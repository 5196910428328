import { Route, Routes } from "react-router-dom";
import React from "react";
import JobsPage from "./pages/JobsPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import HomePage from "./pages/HomePage";
import EmployerPrivateRoute from "./commons/EmployerPrivateRoute";
import EmployeePrivateRoute from "./commons/EmployeePrivateRoute";
import EditProfilePage from "./pages/employee/EditProfilePage";
import StaffsPage from "./pages/StaffsPage";
import AdminPrivateRoute from "./commons/AdminPrivateRoute";
import WhyChooseUs from "./pages/WhyChooseUs";
import Privacy from "./pages/Privacy";
import Faq from "./pages/Faq";
import NotificationPage from "./pages/NotificationPage";
import TnCPage from "./pages/TnCPage";
import AdminLoginPage from "./pages/admin/auth/AdminLoginPage";
import AdminDashboardPage from "./pages/admin/dashboard/AdminDashboardPage";
import SubAdminList from "./pages/admin/sub-admin/SubAdminList";
import SubAdminAdd from "./pages/admin/sub-admin/SubAdminAdd";
import ClientsList from "./pages/admin/clients/ClientsList";
import StaffsList from "./pages/admin/staffs/StaffsList";
import StaffingRequestList from "./pages/admin/staffing-request/StaffingRequestList";
import NotificationList from "./pages/admin/notification/NotificationList";
import ManageNotificationList from "./pages/admin/manageNotification/ManageNotificationList";
import NotificationAdd from "./pages/admin/notification/NotificationAdd";
import Settings from "./pages/admin/settings/Settings";
import HiringRequestPage from "./pages/employer/HiringRequestPage";
import StaffingRequestPage from "./pages/employer/StaffingRequestPage";
import StaffingRequestListPage from "./pages/employer/StaffingRequestListPage";
import AppliedJobsPage from "./pages/employee/AppliedJobsPage";
import MyJobProfilePage from "./pages/employee/MyJobProfilePage";
import ViewShiftsPage from "./pages/employee/ViewShiftsPage";
import AvailableShifts from "./pages/employee/AvailableShiftsPage";
import HiringRequestList from "./pages/admin/hiring-request/HiringRequestList";
import MyProfilePage from "./pages/admin/profile/MyProfilePage";
import NewOpportunity from "./pages/employee/NewOpportunity";
import FavoriteList from "./pages/employer/FavoriteList";
import FavoriteJobList from "./pages/employee/FavoriteJobList";
import ClientEdit from "./pages/admin/clients/ClientEdit";
import StaffsEdit from "./pages/admin/staffs/StaffsEdit";
import JobProfileRequestList from "./pages/admin/jobProfileRequest/JobProfileRequestList";
import GoogleAuth from "@components/socialLogin/GoogleAuth";
import FbAuth from "@components/socialLogin/FbAuth";
import LinkedInAuth from "./components/socialLogin/LinkedInAuth";
import AppliedShifts from "./pages/employee/AppliedShifts";
import MyTeamsPage from "./pages/employee/MyTeamsPage";
import AlertsSettingsPage from "./pages/employee/AlertsSettingsPage";
import OnboardingDocuments from "./pages/employee/OnboardingDocuments";
import StaffingRequestAdminPage from "./pages/admin/staffing-request/StaffingRequestAdminPage";
import ClientAdd from "./pages/admin/clients/ClientAdd";
import PageNotFound from "./pages/PageNotFound";
import PendingStaffingRequestList from "./pages/admin/staffing-request/PendingStaffingRequestList";
import SubAccountList from "./pages/employer/SubAccountList";
import PendingStaffingRequestListEmp from "./pages/admin/staffing-request/PendingStaffingRequestListEmp";
import DNRReportList from "./pages/admin/dnr-report/DNRReportList";
import ReferencesList from "./pages/admin/references/ReferencesList";
import ReferenceReview from "./pages/ReferenceReview";
import LandingPage from "./website/Pages/LandingPage";
import WhyWorkStaffers from "./website/Pages/WhyWorkStaffers";
import ScheduleLocalJobs from "./website/Pages/ScheduleLocalJobs";
import PayBenefitsLocal from "./website/Pages/PayBenefitsLocal";
import PayBenefitsTravel from "./website/Pages/PayBenefitsTravel";
import AboutUs from "./website/Pages/AboutUs";
import PrivacyPolicy from "./website/Pages/PrivacyPolicy";
import Terms from "./website/Pages/Terms";
import JobsListing from "./website/Pages/JobsListing";
import Housing from "./website/Pages/Housing";
import ContactUs from "./website/Pages/ContactUs";
import AppliedShiftsCalendar from "./pages/employee/AppliedShiftsCalendar";
import ShiftByEmployer from "./pages/admin/clients/ShiftByEmployer";
import ShiftByCaregiver from "./pages/admin/jobProfileRequest/ShiftByCaregiver";
import ExportHistoryList from "./pages/admin/staffing-request/ExportHistoryList";
import JobDetails from "./website/Pages/JobDetails";
import UpdatePages from "./pages/admin/manage-website/UpdatePages";
import FaqsLocal from "./website/Pages/FaqsLocal";
import LicensureCompliance from "./website/Pages/LicensureCompliance";
import FaqsTravel from "./website/Pages/FaqsTravel";
import ImportantLinks from "./website/Pages/ImportantLinks";
import HowBecomeTraveler from "./website/Pages/HowBecomeTraveler";
import LocalPage from "./website/Pages/LocalPage";
import ShiftRequestsCalendar from "./pages/employer/ShiftRequestsCalendar";
import Testimonial from "./website/Pages/Testimonial";
import PageSearch from "./website/Pages/PageSearch";
import ShiftCalendarByEmployer from "./pages/admin/clients/ShiftCalendarByEmployer";
import ShiftCalendarByCaregiver from "./pages/admin/jobProfileRequest/ShiftCalendarByCaregiver";
import TravelPage from "./website/Pages/TravelPage";

import NewCaregivers from "./website/Pages/NewCaregivers";
import Jobs from "./pages/admin/manage-website/Jobs";
import Enquiry from "./pages/admin/manage-website/Enquiry";
import Review from "./pages/admin/manage-website/Review";
import FeaturedLocation from "./pages/admin/manage-website/FeaturedLocation";
import AddJob from "./pages/admin/manage-website/component/JobAdd";
import WeeklyReport from "./pages/admin/weekly-report/WeeklyReport";
import WebsiteRegisterPage from "./website/Pages/WebsiteRegisterPage";
import WebsiteLoginPage from "./website/Pages/WebsiteLoginPage";
import FacilityList from "./pages/admin/facilities/FacilityList";
import ShiftByFacility from "./pages/admin/facilities/ShiftByFacility";
import ShiftCalendarByFacility from "./pages/admin/facilities/ShiftCalendarByFacility";
import FacilitiesDetail from "./website/Pages/FacilitiesDetail";
import WebsiteAdminLoginPage from "./website/Pages/WebsiteAdminLoginPage";
import WeeklyReportView from "./pages/admin/weekly-report/WeeklyReportDetails/WeeklyReportView";

const BaseRouter = () => {
  return (
    <div>
      <Routes>
        {/* New website */}
        <Route exact path="/portal" element={<HomePage />} />
        <Route exact path="/" element={<LandingPage />} />
        <Route
          exact
          path="/why-work-with-staffers"
          element={<WhyWorkStaffers />}
        />
        <Route exact path="/local" element={<LocalPage />} />
        <Route exact path="/facilities-detail" element={<FacilitiesDetail />} />
        <Route exact path="/travel" element={<TravelPage />} />

        <Route
          exact
          path="/scheduling-local-job"
          element={<ScheduleLocalJobs />}
        />
        <Route
          exact
          path="/pay-and-benefit-local"
          element={<PayBenefitsLocal />}
        />
        <Route
          exact
          path="/pay-and-benefits-travel"
          element={<PayBenefitsTravel />}
        />
        <Route exact path="/faqs-local" element={<FaqsLocal />} />

        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/login/:role" element={<WebsiteLoginPage />} />
        <Route exact path="/admin-login" element={<WebsiteAdminLoginPage />} />
        <Route exact path="/register/:role" element={<WebsiteRegisterPage />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/terms-and-conditions" element={<Terms />} />
        <Route exact path="/search-jobs" element={<JobsListing />} />
        <Route exact path="/faqs-travel" element={<FaqsTravel />} />
        <Route exact path="/important-links" element={<ImportantLinks />} />
        <Route exact path="/testimonials" element={<Testimonial />} />
        <Route
          exact
          path="/licensure-compliance"
          element={<LicensureCompliance />}
        />
        <Route exact path="/housing" element={<Housing />} />
        <Route
          exact
          path="/how-to-become-a-traveler"
          element={<HowBecomeTraveler />}
        />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/search-jobs/:jobId" element={<JobDetails />} />
        <Route exact path="/search" element={<PageSearch />} />
        <Route exact path="/search-caregivers" element={<NewCaregivers />} />
        {/* Old portal */}

        <Route exact path="/jobs" element={<JobsPage />} />
        <Route exact path="/staffs" element={<StaffsPage />} />
        {/* <Route exact path="/login/:role" element={<LoginPage />} /> */}
        {/* <Route exact path="/admin-login" element={<WebsiteAdminLoginPage />} /> */}
        {/* <Route exact path="/register/:role" element={<RegisterPage />} /> */}
        <Route exact path="/howdoesitwork" element={<WhyChooseUs />} />
        {/* <Route exact path="/privacy" element={<Privacy />} /> */}
        {/* <Route exact path="/tnc" element={<TnCPage />} /> */}
        {/* <Route exact path="/faq" element={<Faq />} /> */}
        <Route exact path="/auth/linkedin" element={<LinkedInAuth />} />
        <Route exact path="/auth/google" element={<GoogleAuth />} />
        <Route exact path="/auth/facebook" element={<FbAuth />} />
        <Route exact path="/reference-review" element={<ReferenceReview />} />

        {/* Employee routes */}
        <Route element={<EmployeePrivateRoute />}>
          <Route exact path="/edit-profile/" element={<EditProfilePage />} />
          <Route exact path="/my-job-profile/" element={<MyJobProfilePage />} />
          <Route exact path="/notification" element={<NotificationPage />} />
          <Route exact path="/applied-jobs/" element={<AppliedJobsPage />} />
          <Route exact path="/new-opportunity/" element={<NewOpportunity />} />
          <Route exact path="/view-shifts/" element={<ViewShiftsPage />} />
          <Route exact path="/applied-shifts/" element={<AppliedShifts />} />
          <Route
            exact
            path="/applied-shifts-calendar/"
            element={<AppliedShiftsCalendar />}
          />
          <Route
            exact
            path="/available-shifts/"
            element={<AvailableShifts />}
          />
          <Route exact path="/my-teams/" element={<MyTeamsPage />} />
          <Route
            exact
            path="/alerts-settings/"
            element={<AlertsSettingsPage />}
          />
          <Route exact path="/favorite-job/" element={<FavoriteJobList />} />
          <Route
            exact
            path="/onboarding-documents/"
            element={<OnboardingDocuments />}
          />
          <Route exact path="/sub-accounts/" element={<SubAccountList />} />
        </Route>
        {/*  Client/Employer routes */}
        <Route element={<EmployerPrivateRoute />}>
          <Route exact path="/edit-profile/" element={<EditProfilePage />} />
          <Route exact path="/notification" element={<NotificationPage />} />
          <Route
            exact
            path="/alerts-settings/"
            element={<AlertsSettingsPage />}
          />
          <Route
            exact
            path="/hiring-request/"
            element={<HiringRequestPage />}
          />
          <Route
            exact
            path="/shift-request/"
            element={<StaffingRequestPage />}
          />
          <Route
            exact
            path="/shift-request-list"
            element={<StaffingRequestListPage />}
          />
          <Route exact path="/favorite/" element={<FavoriteList />} />
          <Route
            exact
            path="/shift-requests-calendar/"
            element={<ShiftRequestsCalendar />}
          />
        </Route>
        {/* Admin/ routes */}
        <Route element={<AdminPrivateRoute />}>
          <Route exact path="/admin/profile" element={<MyProfilePage />} />
          <Route
            exact
            path="/admin/dashboard"
            element={<AdminDashboardPage />}
          />
          <Route exact path="/admin/admin-list" element={<SubAdminList />} />
          <Route exact path="/admin/admin-add" element={<SubAdminAdd />} />
          <Route exact path="/admin/client-edit" element={<ClientEdit />} />
          <Route exact path="/admin/client-add" element={<ClientAdd />} />
          <Route exact path="/admin/staffs-edit" element={<StaffsEdit />} />
          <Route exact path="/admin/facility" element={<FacilityList />} />
          <Route exact path="/admin/clients" element={<ClientsList />} />
          <Route exact path="/admin/staffs" element={<StaffsList />} />
          <Route
            exact
            path="/admin/shift-request"
            element={<StaffingRequestList />}
          />
          <Route
            exact
            path="/admin/pending-shift-request"
            element={<PendingStaffingRequestList />}
          />
          <Route
            exact
            path="/admin/pending-shift-request-employee"
            element={<PendingStaffingRequestListEmp />}
          />
          <Route
            exact
            path="/admin/add-shift-request"
            element={<StaffingRequestAdminPage />}
          />
          <Route
            exact
            path="/admin/hiring-request"
            element={<HiringRequestList />}
          />
          <Route
            exact
            path="/admin/job-profile-request"
            element={<JobProfileRequestList />}
          />
          <Route exact path="/admin/dnr-report" element={<DNRReportList />} />
          <Route
            exact
            path="/admin/notification-list"
            element={<NotificationList />}
          />
          <Route
            exact
            path="/admin/manage-notification-list"
            element={<ManageNotificationList />}
          />
          <Route
            exact
            path="/admin/notification-add"
            element={<NotificationAdd />}
          />
          <Route exact path="/admin/settings" element={<Settings />} />
          <Route exact path="/admin/references" element={<ReferencesList />} />
          <Route
            exact
            path="/admin/caregiver-shift"
            element={<ShiftByCaregiver />}
          />
          <Route
            exact
            path="/admin/caregiver-calendar"
            element={<ShiftCalendarByCaregiver />}
          />
          <Route
            exact
            path="/admin/facility-shift"
            element={<ShiftByFacility />}
          />
          <Route
            exact
            path="/admin/employer-shift"
            element={<ShiftByEmployer />}
          />
          <Route
            exact
            path="/admin/employer-calendar"
            element={<ShiftCalendarByEmployer />}
          />
          <Route
            exact
            path="/admin/facility-calendar"
            element={<ShiftCalendarByFacility />}
          />
          <Route
            exact
            path="/admin/export-history"
            element={<ExportHistoryList />}
          />
          <Route exact path="/admin/update-pages" element={<UpdatePages />} />
          <Route exact path="/admin/jobs" element={<Jobs />} />
          <Route exact path="/admin/add-job" element={<AddJob />} />
          <Route exact path="/admin/enquiry" element={<Enquiry />} />
          <Route exact path="/admin/review" element={<Review />} />
          <Route
            exact
            path="/admin/featured-location"
            element={<FeaturedLocation />}
          />
          <Route exact path="/admin/weekly-report" element={<WeeklyReport />} />
          <Route
            exact
            path="/admin/report-view"
            element={<WeeklyReportView />}
          />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
};

export default BaseRouter;
