/* eslint-disable */
import React, { useEffect, useState } from "react";
import Footer from "@components/Footer";
import Header from "@components/Header";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { Images, InputField } from "@elements/index";
import AdminStore from "@store/AdminStore";
import Spinner from "@components/Spinner";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";
import OtpInput from "react-otp-input";
import TokenStore from "@store/TokenStore";
import {
  osVersion,
  osName,
  mobileVendor,
  browserName,
  fullBrowserVersion,
  mobileModel,
  deviceType,
} from "react-device-detect";
import { Helmet } from "react-helmet";
import {
  SignInSchema,
  StepOneSchema,
  StepThreeSchema,
  regex,
} from "@utils/schemas/allSchema";
import WebsiteLayout from "../components/WebsiteLayout";

const WebsiteAdminLoginPage = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const [stepOne, setStepOne] = useState(false);
  const [stepTwo, setStepTwo] = useState(false);
  const [stepThree, setStepThree] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [OTP, setOTP] = useState("");
  const [email, setEmail] = useState("");
  const [maskEmail, setMaskEmail] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [icon, setIcon] = useState(
    "fa fa-fw fa-eye-slash field-icon toggle-password"
  );
  const [passwordType, setPasswordType] = useState("password");

  useEffect(() => {
    if (OTP.length === 4) {
      verifyOTP();
    }
  }, [OTP]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const login = (values) => {
    let device = `OS:${osName} + OS_VERSION:${osVersion} + MODEL:${mobileModel} +  VENDOR:${mobileVendor}`;
    let browser = `BROWSER:${browserName} + BW_VERSION:${fullBrowserVersion} + DEVICE_TYPE:${deviceType}`;
    TokenStore.clearAllAsyncStore();
    let formData = new FormData();
    formData.append("email", values.email); //append the values with key, value pair
    formData.append("password", values.password);
    formData.append("device_details", device);
    formData.append("browser_details", browser);
    AdminStore.login(formData, navigationCallBack);
  };

  const navigationCallBack = (userType) => {
    toast.success("Successfully Login");
    let from = location.state;
    if (from?.from?.search?.length > 5) {
      navigate(from?.from?.pathname + from?.from?.search);
    } else {
      navigate("../admin/dashboard/");
    }
  };
  const sendOTP = (values) => {
    let formData = new FormData();
    formData.append("email", values.email);
    AdminStore.fogotPassword(formData, forgotPasswordCallback);
  };

  const forgotPasswordCallback = (emailid) => {
    let maskEmail = emailid.replace(
      regex,
      (_, first, middle, last) => `${first}${"x".repeat(middle.length)}${last}`
    );
    setMaskEmail(maskEmail);
    setEmail(emailid);
    setStepOne(false);
    setStepTwo(true);
  };

  const navigationCallBackVerify = () => {
    setOTP("");
    setStepTwo(false);
    setStepThree(true);
    const formdata = new FormData();
    formdata.append("email", email);
    TokenStore.getAccessToken(formdata).then((data) => {
      setAccessToken(data);
    });
  };

  const resendOTP = () => {
    setOTP("");
    setSeconds(30);
    const formdata = new FormData();
    formdata.append("email", email);
    AdminStore.fogotPassword(formdata);
  };

  const verifyOTP = () => {
    if (OTP && OTP.length === 4) {
      const formdata = new FormData();
      formdata.append("email", email);
      formdata.append("otp", OTP);
      AdminStore.verifyResetOtp(formdata, navigationCallBackVerify);
    } else {
      toast.warn("Please Enter OTP!!");
    }
  };
  const setNewPassword = (values) => {
    if (accessToken) {
      // encrypt data
      let valueToEncrypt = accessToken;
      let password = "StaffersLLC";
      let encrypted = CryptoJSAesJson.encrypt(valueToEncrypt, password);
      const formdata = new FormData();
      formdata.append("email", email);
      formdata.append("password", values.password);
      formdata.append("phrase", encrypted);
      AdminStore.setNewPassword(formdata, navigationCallBackNewPassword);
    } else {
      toast.error("Something went wrong!");
    }
  };
  const navigationCallBackNewPassword = () => {
    window.location.reload();
  };
  const _changeIcon = () => {
    icon === "fa fa-fw fa-eye-slash field-icon toggle-password"
      ? (setIcon("fa fa-fw fa-eye field-icon toggle-password"),
        setPasswordType("text"))
      : (setIcon("fa fa-fw fa-eye-slash field-icon toggle-password"),
        setPasswordType("password"));
  };
  return (
    <WebsiteLayout>
      <Helmet>
        <title>Staffers LLC | Admin Login</title>
      </Helmet>
      <div className="container-fluid callback py-5">
        <div className="container pt-5">
          {!stepOne && !stepTwo && !stepThree && (
            <h5 className="display-5 mb-4 animated slideInDown login-head">
              Admin Login
            </h5>
          )}
          {stepOne && (
            <h5 className="display-5 mb-4 animated slideInDown login-head">
              Password Recovery
            </h5>
          )}
          <div className="row justify-content-center ">
            <div className="col-lg-6">
              <div
                className="bg-white shadow border p-4 p-sm-4 wow fadeInUp"
                style={{ borderRadius: 25 }}
                data-wow-delay="0.03s"
              >
                {!stepOne && !stepTwo && !stepThree && (
                  <Formik
                    initialValues={{ email: "", password: "" }}
                    onSubmit={(values) => login(values)}
                    validationSchema={SignInSchema}
                  >
                    {({
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      values,
                      errors,
                      touched,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="col-sm-12 pb-4">
                          <InputField
                            className={"did-floating-input"}
                            type="email"
                            name="email"
                            label={"Your Email / Username"}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.email && touched.email ? (
                            <div className="error-message">{errors.email}</div>
                          ) : null}
                        </div>
                        <div className="col-sm-12">
                          <InputField
                            className={"did-floating-input"}
                            type={passwordType}
                            name="password"
                            label={"Enter Password"}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span
                            toggle="#password-field"
                            className={icon}
                            onClick={() => _changeIcon()}
                          ></span>
                          {errors.password && touched.password ? (
                            <div className="error-message">
                              {errors.password}
                            </div>
                          ) : null}
                        </div>
                        <div className="d-flex justify-content-between pt-2">
                          <span>
                            <label htmlFor="remember_me"></label>
                          </span>
                          <a
                            href="#"
                            onClick={() => setStepOne(true)}
                            className="btn-link"
                          >
                            Forgot Password?
                          </a>
                        </div>

                        <div className="pt-4 d-flex justify-content-center">
                          <button
                            className="btn btn-primary w-50 p-2 "
                            type="submit"
                          >
                            Login
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                )}
                {/* STEP ONE FORGOT PASSWORD*/}
                {stepOne && !stepTwo && !stepThree && (
                  <>
                    <i
                      className="fa fa-arrow-left"
                      onClick={() => setStepOne(false)}
                      style={{ cursor: "pointer" }}
                      aria-hidden="true"
                    ></i>
                    <div
                      className="text-center pb-4 mx-auto wow fadeInUp"
                      data-wow-delay="0.03s"
                      style={{ maxWidth: 600 }}
                    >
                      <h3>Forgot your password?</h3>
                      <small>
                        Please enter your email address and we will send you a
                        passcode to reset your password.
                      </small>
                    </div>
                    <Formik
                      initialValues={{ email: "" }}
                      onSubmit={(values) => sendOTP(values)}
                      validationSchema={StepOneSchema}
                    >
                      {({
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        values,
                        errors,
                        touched,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="col-sm-12">
                            <InputField
                              className={"did-floating-input"}
                              type="email"
                              name="email"
                              label={"Your Email / Username"}
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.email && touched.email ? (
                              <div className="error-message">
                                {errors.email}
                              </div>
                            ) : null}
                          </div>
                          <div className="pt-4 d-flex justify-content-center">
                            <button
                              className="btn btn-primary w-50 p-2"
                              type="submit"
                            >
                              Send Passcode
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </>
                )}
                {/* STEP TWO OTP FIELD*/}
                {!stepOne && stepTwo && !stepThree && (
                  <>
                    <div
                      onClick={() => {
                        setStepTwo(false), setStepOne(true);
                      }}
                    >
                      <i
                        className="fa fa-arrow-left"
                        style={{ cursor: "pointer" }}
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div
                      className="text-center pb-3 pt-5 mx-auto wow fadeInUp"
                      data-wow-delay="0.03s"
                      style={{ maxWidth: 600 }}
                    >
                      Please enter the one time passcode to reset your account
                      password
                      <br></br>
                      <br></br>A code has been sent to {maskEmail}
                    </div>
                    <div className="otpContainer">
                      <OtpInput
                        className="otpInput"
                        value={OTP}
                        onChange={(value) => setOTP(value)}
                        numInputs={4}
                        inputStyle={{
                          width: "3rem",
                          textAlign: "center",
                          height: "3rem",
                          fontSize: "2rem",
                          borderRadius: 8,
                          border: "0.1px solid #75459b",
                        }}
                        focusStyle={{
                          outline: "none",
                          border: "2px solid #782db6",
                          borderRadius: "8px",
                        }}
                      />
                    </div>
                    <div className="countdown-text">
                      {seconds > 0 ? (
                        <small>
                          Time Remaining:{" "}
                          {seconds < 10 ? `0${seconds}` : seconds}
                        </small>
                      ) : (
                        <small>Didn't recieve code?</small>
                      )}

                      <button
                        disabled={seconds > 0}
                        style={{
                          color: seconds > 0 ? "#DFE3E8" : "#75449b",
                        }}
                        onClick={resendOTP}
                      >
                        Resend Passcode
                      </button>
                    </div>
                    <div className="pt-4 d-flex justify-content-center">
                      <button
                        className="btn btn-primary w-50 p-2 "
                        onClick={() => verifyOTP()}
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </>
                )}

                {/* STEP THREE NEW PASSWORD FIELD*/}
                {!stepOne && !stepTwo && stepThree && (
                  <>
                    <div
                      onClick={() => {
                        setStepTwo(true), setStepThree(false), setOTP("");
                      }}
                    >
                      <i
                        className="fa fa-arrow-left"
                        style={{ cursor: "pointer" }}
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div
                      className="text-center pb-3 mx-auto wow fadeInUp"
                      data-wow-delay="0.03s"
                      style={{ maxWidth: 600 }}
                    >
                      <h5>Generate New Password</h5>{" "}
                    </div>
                    <Formik
                      initialValues={{ password: "", passwordConfirmation: "" }}
                      onSubmit={(values) => setNewPassword(values)}
                      validationSchema={StepThreeSchema}
                    >
                      {({
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        values,
                        errors,
                        touched,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="col-sm-12 pb-4">
                            <InputField
                              className={"did-floating-input"}
                              type="password"
                              name="password"
                              label={"New Password *"}
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.password && touched.password ? (
                              <div className="error-message">
                                {errors.password}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-sm-12">
                            <InputField
                              className={"did-floating-input"}
                              type="password"
                              name="passwordConfirmation"
                              label={"Confirm Password*"}
                              value={values.passwordConfirmation}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.passwordConfirmation &&
                            touched.passwordConfirmation ? (
                              <div className="error-message">
                                {errors.passwordConfirmation}
                              </div>
                            ) : null}
                          </div>
                          <div className="pt-4 d-flex justify-content-center">
                            <button
                              className="btn btn-primary w-50 p-2"
                              type="submit"
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Spinner isLoading={AdminStore.loading} />
    </WebsiteLayout>
  );
});

export default WebsiteAdminLoginPage;
