export const BASE_API_URL = "https://api.blueskymss.com/"; //BLUESKY API
export const HOME_URL = "https://staffers.us/"; //Home Website

//LOCAL
// export const BASE_API_URL_INT = "http://192.168.29.105/santeware_staffers_laravel/public/api/" //TEST
// export const BASE_API_URL_FILE = "http://192.168.29.105/santeware_staffers_laravel/"  //TEST
// export const BASE_API_URL_WEB = "http://192.168.29.105/santeware_staffers_laravel/public/"  //TEST

//SERER
// export const BASE_API_URL_INT = "https://138-128-246-143.cloud-xip.com/api/"; //PRODUCTION
// export const BASE_API_URL_FILE = "https://138-128-246-143.cloud-xip.com/"; //PRODUCTION
// export const WEB_SOCKET = "138-128-246-143.cloud-xip.com"; //PRODUCTION

//TRAINING ENV
export const BASE_API_URL_INT = "https://78-138-24-216.cloud-xip.com/api/"; //PRODUCTION
export const BASE_API_URL_FILE = "https://78-138-24-216.cloud-xip.com/api/"; //PRODUCTION
export const WEB_SOCKET = "78-138-24-216.cloud-xip.com"; //PRODUCTION
