import React, { useState, useEffect, useRef } from "react";
import WebsiteLayout from "../components/WebsiteLayout";
import { InputField } from "../../elements";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { observer } from "mobx-react";
import WebsiteStore from "../../store/WebsiteStore";
import splitTextArray from "../../helpers/splitArray";
import { toJS } from "mobx";
import { Typeahead } from "react-bootstrap-typeahead";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import Skeleton from "react-loading-skeleton";
import PageHelmet from "../components/PageHelmet";
import ReactGA from "react-ga";

var cities = require("@assets/city.json");

const JobsListing = observer(() => {
  const [isWhereSpanHidden, setIsWhereSpanHidden] = useState(
    !!WebsiteStore?.data?.location
  );
  const [isWhatSpanHidden, setIsWhatSpanHidden] = useState(
    !!WebsiteStore?.data?.keyword
  );
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedJobType, setSelectedJobType] = useState(null);
  const [jobList, setJobList] = useState([]);
  const [section1Data, setSection1Data] = useState([]);
  const [pageData, setPageData] = useState(null);
  const [isExclusive, setIsExclusive] = useState(false);

  const jobRef = useRef(null);

  useEffect(() => {
    // Set the page_id for fetching page details
    WebsiteStore.page_id = 17;

    // Fetch page details
    WebsiteStore.getPageDetails();
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Reset search filters and fetch jobs
    WebsiteStore.resetSearch();
    WebsiteStore.getJobs();
  }, []);

  const handleJobTypeFilterChange = (jobType) => {
    setSelectedJobType(jobType);
    WebsiteStore.data.contract_type = jobType;
    WebsiteStore.data.page = 1;
    WebsiteStore.getJobs();
  };

  const handleExclusiveFilterChange = () => {
    // Toggle the isExclusive state
    setIsExclusive((prev) => !prev);

    // Update WebsiteStore.data.exclusive based on the new value of isExclusive
    WebsiteStore.data.exclusive = !isExclusive ? 1 : 0;

    // Call getJobs function to update the jobs based on the new filter
    WebsiteStore.getJobs();
  };

  useEffect(() => {
    setJobList(toJS(WebsiteStore?.data?.jobsList));
  }, [WebsiteStore?.data?.jobsList]);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const handleInputChange = async (inputValue) => {
    setIsLoading(true);

    // Use debouncedLoadOptions here
    await debouncedLoadOptions(inputValue, setOptions);

    setIsLoading(false);
  };

  const debouncedLoadOptions = debounce(async (inputValue, callback) => {
    if (!inputValue.trim()) {
      callback([]);
      return;
    }

    setIsLoading(true);

    const filteredCities = cities
      .filter((city) => {
        const labelRegex = new RegExp(inputValue, "i");
        return (
          labelRegex.test(city.label) ||
          labelRegex.test(city.state) ||
          labelRegex.test(city.state_id)
        );
      })
      .map((city) => ({
        label: `${city.label}${city.state_id ? `, ${city.state_id}` : ""}`,
        value: city.label,
      }));

    setTimeout(() => {
      setIsLoading(false);

      const slicedOptions = filteredCities.slice(0, 1000);

      callback(slicedOptions);
    }, 1000);
  }, 300);

  const handlePageChange = (newPage) => {
    WebsiteStore.data.page = newPage;
    WebsiteStore.getJobs();
  };

  const handleMilesChange = (value) => {
    WebsiteStore.data.miles = value;
    WebsiteStore.getJobs();
  };

  const navigate = useNavigate();

  const handleWhereSpanFocus = (e) => {
    // Hide the span on focus
    setIsWhereSpanHidden(!!e.target.value);
    setIsWhereSpanHidden(true);
  };

  const handlePageSizeChange = (value) => {
    WebsiteStore.data.pageSize = value;
    WebsiteStore.getJobs();
  };

  const handleWhatSpanFocus = (e) => {
    // Hide the span on focus
    setIsWhatSpanHidden(!!e.target.value);
    setIsWhatSpanHidden(true);
  };

  const handleIsWhereBlur = (e) => {
    if (!e.target.value) {
      setIsWhereSpanHidden(false);
    }
  };
  const handleIsWhatBlur = (e) => {
    if (!e.target.value) {
      setIsWhatSpanHidden(false);
    }
  };

  const handleSortChange = (sortType) => {
    WebsiteStore.data.sort_by = sortType;
    WebsiteStore.getJobs(); // Trigger the job fetching with the new sort_by value
  };

  const executeScroll = () => {
    if (jobRef && jobRef.current) {
      jobRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  /* const getLocationCords = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    console.log("User's location:");
    console.log("Latitude:", latitude);
    console.log("Longitude:", longitude);

    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        const city = result?.address?.city;
        const state = states.find(
          (state) => state?.name === result?.address?.state
        );

        WebsiteStore.data.location = `${city}, ${state?.abbreviation}`;
      });
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(getLocationCords, null, {
        timeout: 10000,
      });
    } else {
      console.log("your browser doesn't support geolocation");
    }
  }, []);
 */
  useEffect(() => {
    if (WebsiteStore?.data?.pageData !== null) {
      setPageData(toJS(WebsiteStore?.data?.pageData));
    }
    if (WebsiteStore?.data?.pageData?.sections?.length > 0) {
      setSection1Data(
        splitTextArray(
          toJS(WebsiteStore?.data?.pageData?.sections)?.[0]?.content
        )
      );
    }
  }, [WebsiteStore.data.pageData]);

  const returnJobTypeIcon = (type) => {
    return type === "Days" ? (
      <i className="fas fa-sun"></i>
    ) : type === "Nights" ? (
      <i className="fas fa-moon"></i>
    ) : type === "Weekends" ? (
      <i className="fas fa-calendar-week"></i>
    ) : (
      <i className="fas fa-cloud-sun"></i>
    );
  };

  return (
    <>
      <PageHelmet
        name={pageData?.page_name || "Jobs"}
        metaData={pageData?.meta_data}
        ldjson={pageData?.ldjson}
      />

      <WebsiteLayout>
        <div>
          <div className="banner-web main-sec justify-content-center">
            <div className="search-travel-job position-relative pb-0">
              <div className="container">
                <div className="">
                  <div className="banner-content text-center mb-3">
                    <span>
                      {section1Data?.[0] ||
                        `Travel Nursing, Allied, Per Diem Jobs, and More`}
                    </span>
                    <p>
                      {section1Data?.[1] ||
                        `Unlock Direct Access to Permanent and In-House Agency
                      Roles`}
                    </p>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-10 col-md-12">
                    <div className="search-inner">
                      <Formik
                        initialValues={{
                          where: WebsiteStore?.data?.location || "",
                          what: WebsiteStore?.data?.keyword || "",
                        }}
                        onSubmit={(values) => {
                          WebsiteStore.data.location = values.where || "";
                          WebsiteStore.data.keyword = values.what || "";
                          WebsiteStore.getJobs();
                          executeScroll();
                        }}
                        enableReinitialize

                        // validationSchema={

                        // }
                      >
                        {({
                          handleChange,
                          handleSubmit,
                          values,
                          errors,
                          touched,
                          setFieldValue,
                        }) => (
                          <form onSubmit={handleSubmit} autoComplete="off">
                            <div className="row align-items-center">
                              <div className="col-md-6">
                                <div className="border-right grey-box">
                                  <span
                                    className={
                                      isWhereSpanHidden
                                        ? "hidden search-span"
                                        : "search-span"
                                    }
                                  >
                                    Place
                                  </span>

                                  <Typeahead
                                    id="jobLocationTypeahead"
                                    onChange={(selectedOptions) => {
                                      const selectedOption = selectedOptions[0];

                                      setFieldValue(
                                        "where",
                                        selectedOption
                                          ? selectedOption.label
                                          : ""
                                      );
                                    }}
                                    onBlur={handleIsWhereBlur}
                                    onFocus={handleWhereSpanFocus}
                                    onInputChange={handleInputChange}
                                    options={options}
                                    isLoading={isLoading}
                                    labelKey={(option) =>
                                      `${option.label}${
                                        option.state_id
                                          ? `, ${option.state_id}`
                                          : ""
                                      }`
                                    }
                                    placeholder="Job Location"
                                    selected={cities.filter(
                                      (city) =>
                                        values?.where ===
                                        `${city.label}${
                                          city.state_id
                                            ? `, ${city.state_id}`
                                            : ""
                                        }`
                                    )}
                                    renderInput={(inputProps) => (
                                      <InputField
                                        {...inputProps}
                                        className="did-floating-input"
                                        type="text"
                                        name="where"
                                        label={"Job Location"}
                                      />
                                    )}
                                    filterBy={() => true}
                                    minLength={2}
                                  />
                                  {errors.where ? (
                                    <div className="error-message">
                                      {errors.where}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-md-5 ">
                                <div className="grey-box">
                                  <span
                                    className={
                                      isWhatSpanHidden
                                        ? "hidden search-span"
                                        : "search-span"
                                    }
                                  >
                                    Keywords
                                  </span>
                                  <InputField
                                    className={"did-floating-input"}
                                    type="text"
                                    name="what"
                                    label={"Job Title, Specialty, etc"}
                                    value={values?.what || ""}
                                    onChange={handleChange}
                                    onBlur={handleIsWhatBlur}
                                    onFocus={handleWhatSpanFocus}
                                  />
                                  {errors.what ? (
                                    <div className="error-message">
                                      {errors.what}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-md-1 ">
                                <div className="text-center purple-box">
                                  <button className="common-web-btn purple-back p-2 ">
                                    <i className="fa fa-search"></i>
                                    <p>Search Jobs</p>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div ref={jobRef} className="jobs-section">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3 border-filter-right">
                  <div className="">
                    <h3 className="head-third">Filters</h3>
                    <hr></hr>
                    <div>
                      <div className="industry-filter">
                        <h6 className="head-sixth">Job Type</h6>
                        <button
                          className="clear-btn-job-type"
                          onClick={() => handleJobTypeFilterChange(null)}
                        >
                          Clear
                        </button>
                      </div>
                      <div className="health-type">
                        <div
                          className={`blue-box ${
                            selectedJobType === "Travel Contract"
                              ? "selected"
                              : ""
                          }`}
                          onClick={() =>
                            handleJobTypeFilterChange("Travel Contract")
                          }
                        >
                          Travel
                        </div>
                        <div
                          className={`blue-box ${
                            selectedJobType === "Per Diem" ? "selected" : ""
                          }`}
                          onClick={() => handleJobTypeFilterChange("Per Diem")}
                        >
                          Per Diem
                        </div>
                        <div
                          className={`blue-box ${
                            selectedJobType === "Local Contract"
                              ? "selected"
                              : ""
                          }`}
                          onClick={() =>
                            handleJobTypeFilterChange("Local Contract")
                          }
                        >
                          Local
                        </div>
                        {/* <div className="jobs-filter-slider d-flex align-items-center">
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={isExclusive}
                              onClick={handleExclusiveFilterChange}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 border-filter-left">
                  <div className="row">
                    <div className="col-md-5 col-sm-6">
                      <div className="mile-drop">
                        <h3 className="head-third">Results within</h3>

                        <div className="dropdown">
                          <select
                            id="pageSizeDropdown"
                            className="btn btn-secondary"
                            value={WebsiteStore.data.miles}
                            onChange={(e) => handleMilesChange(e.target.value)}
                          >
                            {global.config.radiusMiles.map((mile) => (
                              <option value={mile?.value}>{mile?.value}</option>
                            ))}
                          </select>
                        </div>
                        <h3 className="head-third">miles</h3>
                      </div>
                    </div>
                    <div className="col-md-7 col-sm-6">
                      <div className="mile-drop justify-content-end">
                        <div className="dropdown">
                          <select
                            id="sortingDropdown"
                            className="btn btn-secondary"
                            value={WebsiteStore.data.sort_by || 1}
                            onChange={(e) => handleSortChange(e.target.value)}
                          >
                            {[
                              { label: "Most Recent", value: 1 },
                              { label: "Highest Pay", value: 2 },
                            ].map((el) => (
                              <option value={el.value}>{el.label}</option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <div className="">
                            <div className="jobs-filter-slider d-flex align-items-center">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={isExclusive}
                                  onClick={handleExclusiveFilterChange}
                                />
                                <span className="slider round">
                                  <span className="on">Exclusive</span>
                                  <span className="off">Exclusive?</span>
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="dropdown">
                          <select
                            id="pageSizeDropdown"
                            className="btn btn-secondary"
                            value={WebsiteStore.data.pageSize}
                            onChange={(e) =>
                              handlePageSizeChange(e.target.value)
                            }
                          >
                            <option value={15}>15</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <hr></hr> */}
                  <div className="row mt-3">
                    {WebsiteStore.data.total !== 0 ? (
                      <div className="">
                        <h3 className="head-third mb-4 ">
                          Showing
                          <span style={{ color: "#919191" }}>
                            {" "}
                            {` ${WebsiteStore.data.from || 0} to ${
                              WebsiteStore.data.to || 0
                            } of ${WebsiteStore.data.total || 0}`}
                          </span>{" "}
                          jobs just for you!
                        </h3>
                      </div>
                    ) : (
                      <div className="">
                        <h3 className="head-third mb-4 ">
                          Hmmm.. we can't seem to find a match!
                        </h3>
                        <p>Try changing filters to see other available jobs</p>
                      </div>
                    )}
                    <div className="padding-job row">
                      {WebsiteStore.loading
                        ? // Render skeleton loaders while data is loading
                          Array.from({ length: 6 }).map((_, idx) => (
                            <div className="col-md-4 col-sm-6" key={idx}>
                              <div className="cards-box mb-3">
                                <div className="skeleton-card-header">
                                  <Skeleton height={20} />
                                </div>
                                <div className="skeleton-card-image">
                                  <Skeleton height={150} />
                                </div>
                                <div className="skeleton-card-content">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <Skeleton width={100} />
                                    <Skeleton
                                      width={20}
                                      height={20}
                                      circle={true}
                                    />
                                  </div>
                                  <Skeleton height={20} />
                                  <Skeleton height={20} />
                                  <Skeleton height={20} />
                                  <Skeleton height={20} />
                                </div>
                                <Skeleton height={40} />
                              </div>
                            </div>
                          ))
                        : // Render actual job list once data is loaded
                          jobList?.length > 0 &&
                          jobList?.map((job) => (
                            <div className="col-md-4 col-sm-6" key={job?.id}>
                              <div className="cards-box mb-3">
                                {job?.is_exclusive === 1 && (
                                  <div className="exclusive">Exclusive</div>
                                )}
                                <img
                                  src={`/assets/img/statepics/${(job?.state).toLowerCase()}.png`}
                                  className="img-fluid"
                                  alt="rescue"
                                />
                                {job?.contract_type === "Travel Contract" ? (
                                  <i
                                    className="fas fa-plane small-img"
                                    style={{ color: "#fff" }}
                                  ></i>
                                ) : job?.contract_type === "Per Diem" ? (
                                  <i
                                    className="fas fa-calendar-day small-img"
                                    style={{ color: "#fff" }}
                                  ></i>
                                ) : job?.contract_type === "Local Contract" ? (
                                  <i
                                    className="fas fa-street-view small-img"
                                    style={{ color: "#fff" }}
                                  ></i>
                                ) : (
                                  <i
                                    className="fas fa-map-marker-alt small-img"
                                    style={{ color: "#fff" }}
                                  ></i>
                                )}
                                <div className="content-in">
                                  <div className="d-flex mb-0 pb-0">
                                    <div className="d-flex mb-2 pb-0 align-items-start gap-2">
                                      <div className="d-flex align-items-center">
                                        <span>
                                          <i className="fa fa-map-marker-alt " />{" "}
                                        </span>
                                        <p className="info-text mb-0 gap-1">
                                          {`${job?.city}, ${job?.state}`}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex mb-0 pb-0">
                                    <div className="d-flex mb-2 pb-0 align-items-start money-highlight-text gap-2">
                                      <div className="d-flex align-items-center">
                                        <span>
                                          <i className="fas fa-dollar-sign"></i>
                                        </span>
                                        <p className="info-text mb-0 pb-0 gap-1 ">
                                          ${job?.pay_details_min} - $
                                          {`${job?.pay_details_max}`} Hourly
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="d-flex mb-0 pb-0">
                                    <div className="d-flex mb-2 pb-0 align-items-start  gap-2">
                                      <div className="d-flex align-items-center">
                                        <span>
                                          <i className="fas fa-briefcase-medical"></i>
                                        </span>
                                        <p
                                          className="info-text mb-0 gap-1"
                                          data-toggle="tooltip"
                                          data-placement="left"
                                          title={(() => {
                                            if (
                                              job?.degree &&
                                              job?.speciality
                                            ) {
                                              const parsedDegree = JSON.parse(
                                                job?.degree
                                              );
                                              return `${parsedDegree?.Name}, ${
                                                JSON.parse(job?.speciality)
                                                  ?.Name
                                              }`;
                                            } else if (job?.degree) {
                                              const parsedDegree = JSON.parse(
                                                job?.degree
                                              );
                                              return parsedDegree?.Name;
                                            } else if (job?.speciality) {
                                              return JSON.parse(job?.speciality)
                                                ?.Name;
                                            }
                                            return null; // Return null if both job degree and speciality are not available
                                          })()}
                                        >
                                          {job?.contract_type !==
                                          "Travel Contract"
                                            ? job?.degree &&
                                              (
                                                JSON.parse(job?.degree)?.Name ||
                                                ""
                                              ).length > 30
                                              ? `${(
                                                  JSON.parse(job?.degree)
                                                    ?.Name || ""
                                                ).substring(0, 30)}...`
                                              : JSON.parse(job?.degree)?.Name
                                            : (
                                                ((job?.speciality &&
                                                  JSON.parse(job?.speciality)
                                                    ?.Name + ", ") ||
                                                  "") +
                                                (JSON.parse(job?.degree)
                                                  ?.Name || "")
                                              ).length > 30
                                            ? `${(
                                                ((job?.speciality &&
                                                  JSON.parse(job?.speciality)
                                                    ?.Name + ", ") ||
                                                  "") +
                                                (JSON.parse(job?.degree)
                                                  ?.Name || "")
                                              ).substring(0, 30)}...`
                                            : ((job?.speciality &&
                                                JSON.parse(job?.speciality)
                                                  ?.Name + ", ") ||
                                                "") +
                                              (JSON.parse(job?.degree)?.Name ||
                                                "")}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="d-flex mb-0 pb-0">
                                    <div className="d-flex mb-0 pb-0 align-items-start gap-2">
                                      <div className="box-flex gap-2 mt-1 mb-0">
                                        {job?.gauranteed_hours && (
                                          <div className="d-flex gap-1 align-items-center">
                                            <i className="fas fa-clock"></i>
                                            <p className="small-para mb-0">
                                              {job?.gauranteed_hours}Hr/Week
                                            </p>
                                          </div>
                                        )}
                                        {job?.length_weeks && (
                                          <div className="d-flex gap-1 align-items-center">
                                            <i class="fas fa-calendar-alt"></i>
                                            <p className="small-para mb-0">
                                              {job?.length_weeks} weeks
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="d-flex mb-0 pb-0">
                                    <div className="d-flex mb-0 pb-0 align-items-start gap-2">
                                      <div className="box-flex mt-1 mb-0">
                                        <div className="d-flex gap-2 align-items-center">
                                          {JSON?.parse(job?.shift_type)?.map(
                                            (x, index, array) => (
                                              <div
                                                className="d-flex gap-1 align-items-center"
                                                key={index}
                                              >
                                                {returnJobTypeIcon(x)}
                                                <p className="small-para mb-0">
                                                  {x}
                                                </p>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <button
                                  onClick={() => {
                                    navigate(`/search-jobs/${job?.id}`);
                                  }}
                                  className="common-web-btn purple-back w-100 radius"
                                >
                                  View Details
                                </button>
                              </div>
                            </div>
                          ))}
                    </div>

                    {/* Pagination component */}
                    <div className="row justify-content-end align-items-center pt-3">
                      <div className="col-md-12">
                        <div className="new-pagination">
                          <div className="rc-paginate">
                            <span>{`Showing ${WebsiteStore.data.from || 0} to ${
                              WebsiteStore.data.to || 0
                            } of ${WebsiteStore.data.total || 0} jobs`}</span>
                            <span>{`Page ${WebsiteStore.data.page || 0} of ${
                              WebsiteStore.data.lastPage || "-"
                            }`}</span>

                            <Pagination
                              current={WebsiteStore.data.page}
                              total={WebsiteStore.data.total}
                              pageSize={WebsiteStore.data.pageSize}
                              onChange={(page) => handlePageChange(page)}
                              disabled={WebsiteStore.loading}
                              showTitle={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="new-extra-space">.</div>
        </div>
      </WebsiteLayout>
    </>
  );
});

export default JobsListing;
