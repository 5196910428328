/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react"
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import { CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CFormCheck, CModalBody } from "@coreui/react";
import AdminStore from "@store/AdminStore";
import { toJS } from "mobx";

import Spinner from "@components/Spinner";
import ReactDOM from "react-dom";

import { Helmet } from "react-helmet";
import { BASE_API_URL_FILE } from '@api/config';
import moment from "moment";

const ExportHistoryList = observer(() => {

    const tableRef = useRef()
    const tableName = "table1"
    const [exportHistoryList, setExportHistoryList] = useState([]);
    const [paginationLength, setPaginationLength] = useState(100);

    useEffect(() => {
        AdminStore.getExportList();
    }, []);

    useEffect(() => {
        setExportHistoryList(toJS(AdminStore?.data?.exportHistoryList))
    }, [AdminStore?.data?.exportHistoryList]);

    console.log(exportHistoryList);
    //FOR PENDING/Save
    useEffect(() => {
        $.fn.dataTable.ext.errMode = 'none';
        $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust();
        const table = $(`#${tableName}`).DataTable(
            {
                data: exportHistoryList,
                order: [], // Disable initial sorting
                dom: 'Blfrtip',
                buttons: [
                    'copy', 'csv', 'excel', 'print',
                ],
                pageLength: paginationLength,
                lengthMenu: [100, 500, 1000, 2000, 3000, 5000],
                columns: [
                    {
                        title: "#", data: "id", "render": function (data, type, row, meta) {
                            return data;
                        }
                    },
                    {
                        title: "Exported By", data: "admin", "render": function (data, type, row, meta) {
                            return (data?.first_name + " " + data?.last_name) || "-";
                        }
                    },

                    {
                        title: "Download Time", data: 'created_at', "render": function (data, type, row, meta) {
                            return moment(data).fromNow() || "-";
                        }
                    },
                    {
                        title: "Action"
                    },
                ],
                columnDefs: [


                    {
                        targets: 3,
                        responsivePriority: 1,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <CButton
                                        onClick={() => {
                                            const link = document.createElement('a');
                                            link.href = BASE_API_URL_FILE + rowData?.link;
                                            link.setAttribute('download', rowData?.link);
                                            link.setAttribute('target', '_blank');
                                            document.body.appendChild(link);
                                            link.click();
                                            document.body.removeChild(link);
                                        }}
                                        size="sm" className="w-100" variant='outline' color="success">
                                        Download <i className="fas fa-download"></i>
                                    </CButton>
                                </div>
                                , td),
                    }
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                "language": {
                    search: '',
                    searchPlaceholder: "Search",
                    emptyTable: "No Record Found",
                },
                initComplete: function () {
                    // Apply the search
                    this.api()
                        .columns([0, 1])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px" type="text" placeholder="Filter per page" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });


                },
            });

        // Extra step to do extra clean-up.
        return function () {
            $(`#${tableName}`).off('length.dt');
            table.destroy()
        }
    }, [exportHistoryList])


    return (
        <React.Fragment>
            <Helmet>
                <title>Staffers LLC | Export List</title>
            </Helmet>
            {/* <body> */}
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="content-wrapper p-3 bg-white ">
                            <h3 className="page-title">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="fas fa-user-friends"></i>
                                </span> Export Slot's
                            </h3>

                        </div>
                        <div className="table-wrapper">
                            <table className="table dt-responsive table-hover nowrap" style={{ width: "100%" }} id={tableName} ref={tableRef}>
                            </table>
                        </div>
                    </div>
                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div>
            <Spinner isLoading={AdminStore.loading} />

        </React.Fragment >
    );
})

export default ExportHistoryList;

