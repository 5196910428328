/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useParams, NavLink, useNavigate, useLocation } from "react-router-dom";
import { Formik } from "formik";
import UserStore from "@store/UserStore";
import { InputField } from "@elements/index";
import Spinner from "@components/Spinner";
import OtpInput from "react-otp-input";
import StaffsStore from "@store/StaffsStore";
import HomeStore from "@store/HomeStore";
import { toast } from "react-toastify";
import { observer } from "mobx-react";
import TokenStore from "@store/TokenStore";
import secureLocalStorage from "react-secure-storage";
import SocialLogin from "@components/socialLogin/socialLogin";
import Skeleton from "react-loading-skeleton";
import {
  SignInSchema,
  SignClientSchema,
  StepOneSchema,
  StepThreeSchema,
  regex,
} from "@utils/schemas/allSchema";
import {
  osVersion,
  osName,
  mobileVendor,
  browserName,
  fullBrowserVersion,
  mobileModel,
  deviceType,
} from "react-device-detect";
import { Helmet } from "react-helmet";
import WebsiteLayout from "../components/WebsiteLayout";

const WebsiteLoginPage = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const { role } = useParams();
  const [stepOne, setStepOne] = useState(false);
  const [stepTwo, setStepTwo] = useState(false);
  const [stepThree, setStepThree] = useState(false);
  const [OTP, setOTP] = useState("");
  const [LoginOTP, setLoginOTP] = useState("");
  const [email, setEmail] = useState("");
  const [maskEmail, setMaskEmail] = useState("");
  const [userType, setUserType] = useState("false"); //false for client
  const [seconds, setSeconds] = useState(0);
  const [accessToken, setAccessToken] = useState("");
  const [icon, setIcon] = useState(
    "fa fa-fw fa-eye-slash field-icon toggle-password"
  );
  const [passwordType, setPasswordType] = useState("password");
  const [refresh, setRefresh] = useState();
  const [verifyStaffEmail, setVerifyStaffEmail] = useState();
  const [verifyStaffModal, setVerifyStaffModal] = useState(false);

  window.onscroll = function () {};

  useEffect(() => {
    secureLocalStorage.removeItem("token");
    secureLocalStorage.removeItem("type");
    if (role == "employer") {
      setUserType("false");
    } else {
      setUserType("true");
    }
    setRefresh(localStorage.getItem("refresh-token"));
  }, []);

  const _changeIcon = () => {
    icon === "fa fa-fw fa-eye-slash field-icon toggle-password"
      ? (setIcon("fa fa-fw fa-eye field-icon toggle-password"),
        setPasswordType("text"))
      : (setIcon("fa fa-fw fa-eye-slash field-icon toggle-password"),
        setPasswordType("password"));
  };

  useEffect(() => {
    if (OTP?.length === 4) {
      verifyOTP();
    }
  }, [OTP]);

  useEffect(() => {
    if (LoginOTP?.length === 4) {
      verifyLoginOTP();
    }
  }, [LoginOTP]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const login = (values) => {
    let device = `OS:${osName} + OS_VERSION:${osVersion} + MODEL:${mobileModel} +  VENDOR:${mobileVendor}`;
    let browser = `BROWSER:${browserName} + BW_VERSION:${fullBrowserVersion} + DEVICE_TYPE:${deviceType}`;
    TokenStore.clearAllAsyncStore();
    let formData = new FormData();
    userType == "true" &&
      values.email &&
      formData.append("email", values.email); //append the values with key, value pair
    values.username && formData.append("username", values.username); //append the values with key, value pair
    formData.append("password", values.password);
    formData.append("device_details", device);
    formData.append("browser_details", browser);
    if (userType == "true") {
      StaffsStore.login(formData, navigationCallBack, navigationCallBackFailed);
    } else {
      UserStore.login(formData, navigationCallBack);
    }
  };

  const navigationCallBack = () => {
    secureLocalStorage.setItem("type", userType);
    toast.success("Successfully Login");
    let from = location.state;
    if (from?.from?.pathname?.length > 5) {
      navigate(from?.from?.pathname);
    } else {
      navigate("/portal", { replace: true });
    }
  };

  const navigationCallBackFailed = (creds) => {
    for (const [key, value] of creds.entries()) {
      if (key === "email") {
        const formdata = new FormData();
        formdata.append("email", value);
        StaffsStore.reSendOTP(formdata);
        setStepOne(true);
        setStepTwo(true);
        setStepThree(true);
        setVerifyStaffEmail(value);
        setVerifyStaffModal(true);
      }
    }
  };
  const resendLoginOTP = () => {
    const formdata = new FormData();
    formdata.append("email", verifyStaffEmail);
    StaffsStore.reSendOTP(formdata);
  };

  const sendOTP = (values) => {
    let formData = new FormData();
    formData.append("email", values.email);
    if (userType == "true") {
      StaffsStore.fogotPassword(formData, forgotPasswordCallback);
    } else {
      UserStore.fogotPassword(formData, forgotPasswordCallback);
    }
  };
  const verifyLoginOTP = () => {
    if (LoginOTP && LoginOTP?.length === 4) {
      const formdata = new FormData();
      formdata.append("email", verifyStaffEmail);
      formdata.append("otp", LoginOTP);
      StaffsStore.verifyOTP(formdata, navigationCallBackLoginVerify);
    } else {
      toast.warn("Please Enter OTP!");
    }
  };

  const navigationCallBackLoginVerify = () => {
    setLoginOTP();
    setVerifyStaffModal(false);
    setStepOne(false);
    setStepTwo(false);
    setStepThree(false);
    setVerifyStaffEmail();
    swal(
      "Verified",
      "Your account has been verified. Please login.",
      "success"
    );
  };

  const forgotPasswordCallback = (emailid) => {
    let maskEmail = emailid.replace(
      regex,
      (_, first, middle, last) => `${first}${"x".repeat(middle.length)}${last}`
    );
    setMaskEmail(maskEmail);
    setEmail(emailid);
    setStepOne(false);
    setStepTwo(true);
  };

  const resendOTP = () => {
    setOTP("");
    setSeconds(30);
    const formdata = new FormData();
    formdata.append("email", email);
    if (userType == "true") {
      StaffsStore.fogotPassword(formdata);
    } else {
      UserStore.fogotPassword(formdata);
    }
  };

  const verifyOTP = () => {
    if (OTP && OTP?.length === 4) {
      const formdata = new FormData();
      formdata.append("email", email);
      formdata.append("otp", OTP);
      if (userType == "true") {
        StaffsStore.verifyResetOtp(formdata, navigationCallBackVerify);
      } else {
        UserStore.verifyResetOtp(formdata, navigationCallBackVerify);
      }
    } else {
      toast.warn("Please Enter OTP!!");
    }
  };

  const navigationCallBackVerify = () => {
    setOTP("");
    setStepTwo(false);
    setStepThree(true);
    const formdata = new FormData();
    formdata.append("email", email);
    TokenStore.getAccessToken(formdata).then((data) => {
      setAccessToken(data);
    });
  };

  const setNewPassword = (values) => {
    if (accessToken) {
      // encrypt data
      let valueToEncrypt = accessToken;
      let password = global.config.app_password;
      let encrypted = CryptoJSAesJson.encrypt(valueToEncrypt, password);
      const formdata = new FormData();
      formdata.append("email", email);
      formdata.append("password", values.password);
      formdata.append("phrase", encrypted);
      if (userType == "true") {
        StaffsStore.setNewPassword(formdata, navigationCallBackNewPassword);
      } else {
        UserStore.setNewPassword(formdata, navigationCallBackNewPassword);
      }
    } else {
      toast.error("Something went wrong!");
    }
  };

  const navigationCallBackNewPassword = () => {
    window.location.reload();
  };

  return (
    <WebsiteLayout>
      <Helmet>
        <title>Staffers LLC | Login</title>
      </Helmet>
      <div className="container-fluid callback py-5">
        <div className="container pt-5">
          <h5 className="display-5 mb-4 animated slideInDown login-head">
            {!stepOne && !stepTwo && !stepThree
              ? "Welcome to the Staffers Community"
              : stepTwo === true
              ? "Verify Your Passcode"
              : "Password Recovery"}{" "}
          </h5>
          <div className="row justify-content-center ">
            <div className="col-lg-6">
              <div
                className="bg-white shadow border p-4 p-sm-4 wow fadeInUp running-border"
                style={{ borderRadius: 25 }}
                data-wow-delay="0.03s"
              >
                {/* STEP INITIAL Login Screen */}

                {!stepOne && !stepTwo && !stepThree && (
                  <>
                    <div
                      className="text-center mx-auto wow fadeInUp"
                      data-wow-delay="0.03s"
                      style={{ maxWidth: 600 }}
                    >
                      <div className="d-flex justify-content-center align-items-center text-primary fw-semi-bold py-1 px-3 pb-4 ">
                        <div className="row">
                          <div className="col">
                            <div
                              className={
                                userType === "false"
                                  ? "login-switch-label-selected"
                                  : ""
                              }
                            >
                              <input
                                className="login-switch"
                                type="radio"
                                name="Employer"
                                id="Employer"
                                checked={userType === "false"}
                                value={false}
                                onChange={(event) => {
                                  setUserType(event.target.value);
                                }}
                                onClick={(event) => {
                                  setUserType(event.target.value);
                                }}
                              />
                              <label
                                className="login-switch-label"
                                style={
                                  userType === "false"
                                    ? { color: "#75459B" }
                                    : {}
                                }
                                htmlFor="Employer"
                                aria-label="Employer"
                              >
                                <span></span>
                                Facility User
                              </label>
                            </div>
                          </div>
                          <div className="col">
                            <div
                              className={
                                userType === "true"
                                  ? "login-switch-label-selected"
                                  : ""
                              }
                            >
                              <input
                                className="login-switch"
                                type="radio"
                                name="Employee"
                                id="Employee"
                                value={true}
                                checked={userType === "true"}
                                onChange={(event) => {
                                  setUserType(event.target.value);
                                }}
                                onClick={(event) => {
                                  setUserType(event.target.value);
                                }}
                              />
                              <label
                                className="login-switch-label"
                                htmlFor="Employee"
                                aria-label="Employee"
                                style={
                                  userType === "true"
                                    ? { color: "#75459B" }
                                    : {}
                                }
                              >
                                <span></span>
                                Caregiver
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pb-2">
                      {userType === "false" ? (
                        <p className="text-center">
                          {HomeStore?.data?.dashboardData?.find(
                            (x) => x.key_name === "Employer Login Paraphraser"
                          )?.value || (
                            <Skeleton
                              className="info-text"
                              height={17}
                              width={200}
                            />
                          )}
                        </p>
                      ) : (
                        <p className="text-center">
                          {HomeStore?.data?.dashboardData?.find(
                            (x) => x.key_name === "Caregiver Login Paraphraser"
                          )?.value || (
                            <Skeleton
                              className="info-text"
                              height={17}
                              width={200}
                            />
                          )}
                        </p>
                      )}
                    </div>
                    <Formik
                      initialValues={{ email: "", password: "", username: "" }}
                      onSubmit={(values) => login(values)}
                      validationSchema={
                        userType === "false" ? SignClientSchema : SignInSchema
                      }
                    >
                      {({
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        values,
                        errors,
                        touched,
                      }) => (
                        <form onSubmit={handleSubmit} className="wow fadeInUp">
                          {userType === "true" && (
                            <div className="col-sm-12 pb-4">
                              <InputField
                                className={"did-floating-input"}
                                type="email"
                                name="email"
                                label={"Your Email"}
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.email && touched.email ? (
                                <div className="error-message">
                                  {errors.email}
                                </div>
                              ) : null}
                            </div>
                          )}
                          {userType === "false" && (
                            <div className="col-sm-12 pb-4">
                              <InputField
                                className={"did-floating-input"}
                                type="text"
                                name="username"
                                label={"Username"}
                                value={values.username}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.username && touched.username ? (
                                <div className="error-message">
                                  {errors.username}
                                </div>
                              ) : null}
                            </div>
                          )}
                          <div className="col-sm-12 pb-4">
                            <InputField
                              className={"did-floating-input"}
                              type={passwordType}
                              name="password"
                              label={"Enter Password"}
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <span
                              toggle="#password-field"
                              className={icon}
                              onClick={() => _changeIcon()}
                            ></span>
                            {errors.password && touched.password ? (
                              <div className="error-message">
                                {errors.password}
                              </div>
                            ) : null}
                          </div>
                          <div
                            className="d-flex justify-content-between"
                            style={{ alignItems: "center" }}
                          >
                            <span>
                              {/* <input type="checkbox" className="form-check-input" id="remember_me" name="remember_me" value="remember_me" />
                                                                <label for="remember_me" className="pt-1">&nbsp;Remember Me</label> */}
                            </span>
                            <a
                              href="#"
                              onClick={() => setStepOne(true)}
                              className="btn-link"
                            >
                              Forgot Password?
                            </a>
                          </div>

                          <div className="pt-4 d-flex justify-content-center">
                            <button
                              className="btn btn-primary w-50 p-2"
                              type="submit"
                            >
                              Login
                            </button>
                          </div>
                          {userType == "true" && (
                            <SocialLogin userRole={userType} />
                          )}
                        </form>
                      )}
                    </Formik>
                  </>
                )}

                {/* STEP ONE FORGOT PASSWORD*/}
                {stepOne && !stepTwo && !stepThree && (
                  <>
                    <i
                      className="fa fa-arrow-left"
                      onClick={() => setStepOne(false)}
                      style={{ cursor: "pointer" }}
                      aria-hidden="true"
                    ></i>

                    <div
                      className="text-center mx-auto wow fadeInUp"
                      data-wow-delay="0.03s"
                      style={{ maxWidth: 600 }}
                    >
                      <div className="d-flex justify-content-center align-items-center text-primary fw-semi-bold py-1 px-3 pb-4">
                        <div className="row">
                          <div className="col">
                            <div
                              className={
                                userType === "false"
                                  ? "login-switch-label-selected"
                                  : ""
                              }
                            >
                              <input
                                className="login-switch"
                                type="radio"
                                name="Employer"
                                id="Employer"
                                checked={userType === "false"}
                                value={false}
                                onChange={(event) => {
                                  setUserType(event.target.value);
                                }}
                                onClick={(event) => {
                                  setUserType(event.target.value);
                                }}
                              />
                              <label
                                className="login-switch-label"
                                style={
                                  userType === "false"
                                    ? { color: "#75459B" }
                                    : {}
                                }
                                htmlFor="Employer"
                                aria-label="Employer"
                              >
                                <span></span>
                                Employer
                              </label>
                            </div>
                          </div>
                          <div className="col">
                            <div
                              className={
                                userType === "true"
                                  ? "login-switch-label-selected"
                                  : ""
                              }
                            >
                              <input
                                className="login-switch"
                                type="radio"
                                name="Employee"
                                id="Employee"
                                value={true}
                                checked={userType === "true"}
                                onChange={(event) => {
                                  setUserType(event.target.value);
                                }}
                                onClick={(event) => {
                                  setUserType(event.target.value);
                                }}
                              />
                              <label
                                className="login-switch-label"
                                htmlFor="Employee"
                                aria-label="Employee"
                                style={
                                  userType === "true"
                                    ? { color: "#75459B" }
                                    : {}
                                }
                              >
                                <span></span>
                                Caregiver
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="text-center pb-4 mx-auto wow fadeInUp"
                      data-wow-delay="0.03s"
                      style={{ maxWidth: 600 }}
                    >
                      <h3>Forgot your password?</h3>
                      <small>
                        Please enter your email address and we will send you a
                        passcode to reset your password.
                      </small>
                    </div>
                    <Formik
                      initialValues={{ email: "" }}
                      onSubmit={(values) => sendOTP(values)}
                      validationSchema={StepOneSchema}
                    >
                      {({
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        values,
                        errors,
                        touched,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          {
                            <div className="col-sm-12">
                              <InputField
                                className={"did-floating-input"}
                                type="email"
                                name="email"
                                label={"Enter Your Email"}
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.email && touched.email ? (
                                <div className="error-message">
                                  {errors.email}
                                </div>
                              ) : null}
                            </div>
                          }

                          <div className="pt-4 d-flex justify-content-center">
                            <button
                              className="btn btn-primary w-50 p-2"
                              type="submit"
                            >
                              Send Passcode
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </>
                )}
                {/* STEP TWO OTP FIELD*/}
                {!stepOne && stepTwo && !stepThree && (
                  <>
                    <div
                      onClick={() => {
                        setStepTwo(false), setStepOne(true);
                      }}
                    >
                      <i
                        className="fa fa-arrow-left"
                        style={{ cursor: "pointer" }}
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div
                      className="text-center pb-2 pt-4 mx-auto wow fadeInUp"
                      data-wow-delay="0.03s"
                      style={{ maxWidth: 600 }}
                    >
                      Please enter the one time passcode to reset your account
                      password
                      <br></br>
                      <br></br>A code has been sent to {maskEmail}
                    </div>
                    <div className="otpContainer">
                      <OtpInput
                        className="otpInput"
                        value={OTP}
                        onChange={(value) => setOTP(value)}
                        numInputs={4}
                        inputStyle={{
                          width: "3rem",
                          textAlign: "center",
                          height: "3rem",
                          fontSize: "2rem",
                          borderRadius: 8,
                          border: "0.1px solid #75459b",
                        }}
                        focusStyle={{
                          outline: "none",
                          border: "2px solid #782db6",
                          borderRadius: "8px",
                        }}
                      />
                    </div>
                    <div className="countdown-text">
                      {seconds > 0 ? (
                        <small>
                          Time Remaining:{" "}
                          {seconds < 10 ? `0${seconds}` : seconds}
                        </small>
                      ) : (
                        <small>Didn't get a code?</small>
                      )}

                      <button
                        disabled={seconds > 0}
                        style={{
                          color: seconds > 0 ? "#DFE3E8" : "#75449b",
                        }}
                        onClick={resendOTP}
                      >
                        Resend Passcode
                      </button>
                    </div>
                    <div className="pt-3 d-flex justify-content-center">
                      <button
                        className="btn btn-primary w-50 p-2 "
                        onClick={() => verifyOTP()}
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </>
                )}

                {/* STEP THREE NEW PASSWORD FIELD*/}
                {!stepOne && !stepTwo && stepThree && (
                  <>
                    <div
                      onClick={() => {
                        setStepTwo(true), setStepThree(false), setOTP("");
                      }}
                    >
                      <i
                        className="fa fa-arrow-left"
                        style={{ cursor: "pointer" }}
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div
                      className="text-center pb-3 mx-auto wow fadeInUp"
                      data-wow-delay="0.03s"
                      style={{ maxWidth: 600 }}
                    >
                      <h5>Generate New Password</h5>{" "}
                    </div>
                    <Formik
                      initialValues={{ password: "", passwordConfirmation: "" }}
                      onSubmit={(values) => setNewPassword(values)}
                      validationSchema={StepThreeSchema}
                    >
                      {({
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        values,
                        errors,
                        touched,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="col-sm-12 pb-4">
                            <InputField
                              className={"did-floating-input"}
                              type="password"
                              name="password"
                              label={"New Password *"}
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />

                            {errors.password && touched.password ? (
                              <div className="error-message">
                                {errors.password}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-sm-12">
                            <InputField
                              className={"did-floating-input"}
                              type="password"
                              name="passwordConfirmation"
                              label={"Confirm Password*"}
                              value={values.passwordConfirmation}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.passwordConfirmation &&
                            touched.passwordConfirmation ? (
                              <div className="error-message">
                                {errors.passwordConfirmation}
                              </div>
                            ) : null}
                          </div>
                          <div className="pt-4 d-flex justify-content-center">
                            <button
                              className="btn btn-primary w-50 p-2"
                              type="submit"
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </>
                )}
                {/* STEP  OTP VIRIFICATION MODAL*/}
                {verifyStaffModal && (
                  <>
                    <div
                      onClick={() => {
                        setVerifyStaffModal(false),
                          setStepTwo(false),
                          setStepOne(true),
                          setStepThree(false);
                      }}
                    >
                      <i
                        className="fa fa-arrow-left"
                        style={{ cursor: "pointer" }}
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div
                      className="text-center pb-2 pt-4 mx-auto wow fadeInUp"
                      data-wow-delay="0.03s"
                      style={{ maxWidth: 600 }}
                    >
                      Please enter the one time passcode to verify your account
                      <br></br>
                      <br></br>A code has been sent to{" "}
                      {verifyStaffEmail.replace(
                        regex,
                        (_, first, middle, last) =>
                          `${first}${"x".repeat(middle.length)}${last}`
                      )}
                    </div>
                    <div className="otpContainer">
                      <OtpInput
                        className="otpInput"
                        value={LoginOTP}
                        onChange={(value) => setLoginOTP(value)}
                        numInputs={4}
                        inputStyle={{
                          width: "3rem",
                          textAlign: "center",
                          height: "3rem",
                          fontSize: "2rem",
                          borderRadius: 8,
                          border: "0.1px solid #75459b",
                        }}
                        focusStyle={{
                          outline: "none",
                          border: "2px solid #782db6",
                          borderRadius: "8px",
                        }}
                      />
                    </div>
                    <div className="countdown-text">
                      {seconds > 0 ? (
                        <small>
                          Time Remaining:{" "}
                          {seconds < 10 ? `0${seconds}` : seconds}
                        </small>
                      ) : (
                        <small>Didn't get a code?</small>
                      )}

                      <button
                        disabled={seconds > 0}
                        style={{
                          color: seconds > 0 ? "#DFE3E8" : "#75449b",
                        }}
                        onClick={resendLoginOTP}
                      >
                        Resend Passcode
                      </button>
                    </div>
                    <div className="pt-3 d-flex justify-content-center">
                      <button
                        className="btn btn-primary w-50 p-2 "
                        onClick={() => verifyLoginOTP()}
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5 d-flex justify-content-center">
          <small className="text-center" style={{ fontWeight: "bold" }}>
            Don’t have an Staffers account?{" "}
            <NavLink
              style={{ textDecoration: "underline" }}
              to={`/register/${role}`}
            >
              Sign Up
            </NavLink>
          </small>
        </div>
      </div>
      <Spinner isLoading={UserStore.loading || StaffsStore.loading} />
    </WebsiteLayout>
  );
});
export default WebsiteLoginPage;
