/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import Header from '@components/Header';
import { Helmet } from 'react-helmet';
import 'react-spring-bottom-sheet/dist/style.css';
import StaffsStore from '@store/StaffsStore';
import HomeStore from '@store/HomeStore';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { toJS } from 'mobx';
import Spinner from "@components/Spinner";
import swal from '@sweetalert/with-react';
import { momentLocalizer } from 'react-big-calendar'
import CustomModal from '@components/modals/CustomModal';
import { onCopySlot, slotStatusListCaregiver } from '@utils/constant/constant';
import ToolbarDropdown from "@elements/select/ToolbarDropdown";
import html2pdf from 'html2pdf.js';
import MyCalendar from '../../elements/calendar/MyCalendar';
import secureLocalStorage from 'react-secure-storage';

const localizer = momentLocalizer(moment)
const AppliedShiftsCalendar = observer(() => {
    const [appliedShiftList, setAppliedShiftList] = useState([]);
    const [upComingShiftList, setComingShiftList] = useState([]);
    const [degree, setDegree] = useState([]);
    const [rating, setRating] = useState();
    // const [feedback, setFeedback] = useState("");
    const [submitted, setSubmitted] = useState();
    const [reviewSlot, setReviewSlot] = useState();
    const [selectedOption, setSelectedOption] = useState();
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [userData, setUserData] = useState();

    useEffect(() => {
        StaffsStore.getAppliedShiftData();
        let userData = secureLocalStorage.getItem("userData");
        setUserData(JSON.parse(userData));
    }, []);

    useEffect(() => {
        let pendingShifts = toJS(StaffsStore.data.appliedShiftList);
        const mergedShifts = pendingShifts?.contracts?.concat(pendingShifts?.data);
        // const upcoming = mergedShifts?.filter(x => x?.status === 2);
        setComingShiftList(mergedShifts)
        const formattedEvents = mergedShifts?.map(event => {
            if (event?.category === 'TC') {
                let startDate = new Date(`${event.start_date} ${event.start_time}`);
                let endDate = new Date(`${event.end_date} ${event.end_time}`);
                return {
                    id: event.id,
                    data: event,
                    title: event?.user?.organization_name,
                    start: startDate,
                    end: endDate,
                    allDay: true,
                    color: event?.status === 2 ? "black" : event?.status === 5 ? 'black' : '#FFBA00',
                    // colorEvento:'transparent'
                    colorEvento: event?.status === 1 ? '#E1F2FA' : event?.status === 5 ? '#08bdff' : (event?.status === 2) ? '#55A5C3' : '#6c757d', // Adjust text color for better visibility
                    // You can include other properties as needed
                };
            } else {
                let startDate = new Date(`${event.slot_date} ${event.slot_start_time}`);
                let endDate = new Date(`${event.slot_date} ${event.slot_end_time}`);
                return {
                    id: event.id,
                    data: event,
                    title: event?.request?.facility?.organization_name,
                    start: startDate,
                    end: endDate,
                    allDay: false,
                    color: (event?.status === 5 || event?.status === 6 || event?.status === 3) ? "black" : (event?.status === 2 || (event?.status === 1 && event?.nominator_id)) ? '#0873ff' : (event?.status === 7) ? 'black' : '#FFBA00',
                    colorEvento: '#E1F2FA',
                    // colorEvento: event?.status === 1 ? 'gray' : event?.status === 2 ? '#87CEEB' : (event?.status === 3 || event?.status === 5 || event?.status === 6) ? '#dc3545' : '#6c757d', // Adjust text color for better visibility
                    // You can include other properties as needed
                };
            }
        });

        setAppliedShiftList(formattedEvents);

    }, [StaffsStore.data.appliedShiftList]);

    useEffect(() => {
        setDegree(HomeStore.data.degreeList)
    }, [HomeStore.data.degreeList]);

    const onGetNotify = (item) => {
        let param = {
            "slot_id": item?.id
        }
        StaffsStore.getNotify(param, navigationCallBack)
    }

    const navigationCallBack = () => {
        StaffsStore.getAppliedShiftData();
        swal("Success!", "The client has been notified that you are in route", "success", {
            button: "Close",
        });
    }

    useEffect(() => {
        if (rating && reviewSlot) {
            let param = {
                request_id: reviewSlot?.request?.id,
                rating: rating
            }
            StaffsStore.saveFeedback(param, navigationCallBackReview);
        }
    }, [submitted]);

    const navigationCallBackReview = () => {
        StaffsStore.getAppliedShiftData();
        swal("Thank you for your review!", `We received ${rating}/5 rating from you.`, "success").then(() => {
            // setFeedback()
            setRating()
            setReviewSlot()
            setSubmitted(false)
        })
    }


    const handleEventClick = (event) => {
        // Set the selected event and show the modal
        setSelectedEvent(event);
        // Implement your modal display logic here
        // You might use a state variable to manage the modal's visibility
        // For example, setModalVisible(true);
    };
    const handleCloseModal = () => {
        // Close the modal by resetting the selected event
        setSelectedEvent(false);
        // Implement your modal close logic here
        // For example, setModalVisible(false);
    };

    const ViewEvent = () => {
        return (
            <div className="">
                <h3 className="ml-2 text-primary">{selectedEvent?.title}</h3>
                {selectedEvent?.data?.category !== 'TC' && <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                        <i className="fas fa-user text-primary"></i> <strong>Contact Person:</strong> {selectedEvent?.data?.request?.user?.first_name} {selectedEvent?.data?.request?.user?.last_name}
                    </li>
                    <li className="list-group-item">
                        <i className="fas fa-id-badge  text-primary"></i> <strong>Order Ref:</strong> {selectedEvent?.data?.shift_id}
                    </li>
                    <li className="list-group-item">
                        <i className="fas fa-clipboard text-primary"></i> <strong>Shift Category:</strong> {selectedEvent?.data?.request?.category === 'PD' ? "Per Diem" : "Local Contract"}
                    </li>
                    <li className="list-group-item">
                        <i className="fas fa-id-badge  text-primary"></i> <strong>Profession-Specilaity:</strong> {selectedEvent?.data?.request?.specialty_name ? selectedEvent?.data?.request?.specialty_name : degree?.find(deg => deg.Id == selectedEvent?.data?.shift_data?.profession)?.Name}
                    </li>
                    <li className="list-group-item">
                        <i className="far fa-clock text-primary"></i> <strong>Shift Type:</strong> {selectedEvent?.data?.slot_type}
                    </li>
                    <li className="list-group-item">
                        <i className="far fa-calendar-check text-primary"></i> <strong>Shift Status:</strong> {(selectedEvent?.data?.status === 1 && !selectedEvent?.data?.nominator_id) ? "Pending" : (selectedEvent?.data?.status === 2 || (selectedEvent?.data?.status === 1 && selectedEvent?.data?.nominator_id)) ? "Confirmed" : (selectedEvent?.data?.status === 3 || selectedEvent?.data?.status === 5 || selectedEvent?.data?.status === 6) ? "Cancelled" : selectedEvent?.data?.status === 7 ? "Closed" : "No Update"}
                    </li>
                    {selectedEvent?.data?.nominator_id && <li className="list-group-item">
                        <i className="far fa-calendar-check text-primary"></i> <strong>Assigned To Others?:</strong> {selectedEvent?.data?.status === 1 && selectedEvent?.data?.nominator_id ? "Open" : "Assigned "}
                    </li>}
                    <li className="list-group-item">
                        <i className="far fa-calendar-alt text-primary"></i> <strong>Date:</strong> {moment(selectedEvent?.data?.slot_date).format('MMMM Do, YYYY')}
                    </li>
                    <li className="list-group-item">
                        <i className="far fa-clock text-primary"></i> <strong>Time:</strong> {moment(selectedEvent?.start).format('hh:mm A')} - {moment(selectedEvent?.end).format('hh:mm A')}
                    </li>
                    <li className="list-group-item">
                        <i className="fas fa-map-marked-alt text-primary"></i> <strong>Address:</strong> {selectedEvent?.data?.request?.facility?.address + ", " + selectedEvent?.data?.request?.facility?.city + ", " + selectedEvent?.data?.request?.facility?.state + ", Zip Code :" + selectedEvent?.data?.request?.facility?.zipcode}
                    </li>

                    {selectedEvent?.data?.remark && <li className="list-group-item">
                        <i className="fas fa-comment text-primary"></i> <strong>Client Comment:</strong> {selectedEvent?.data?.remark}
                    </li>}
                </ul>}
                {selectedEvent?.data?.category === 'TC' &&
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                            <i className="fas fa-user text-primary"></i> <strong>Contact Person:</strong> {selectedEvent?.data?.user?.first_name} {selectedEvent?.data?.user?.last_name}
                        </li>
                        <li className="list-group-item">
                            <i className="fas fa-user text-primary"></i> <strong>Facility:</strong> {selectedEvent?.data?.shift_data?.first_name}
                        </li>
                        <li className="list-group-item">
                            <i className="fas fa-id-badge  text-primary"></i> <strong>Shift Ref:</strong> {selectedEvent?.data?.id}
                        </li>
                        <li className="list-group-item">
                            <i className="fas fa-clipboard text-primary"></i> <strong>Shift Category:</strong> Traveler Contract
                        </li>
                        <li className="list-group-item">
                            <i className="far fa-clock text-primary"></i> <strong>Shift Type:</strong> {selectedEvent?.data?.shift_type}
                        </li>
                        <li className="list-group-item">
                            <i className="far fa-calendar-check text-primary"></i> <strong>Shift Status:</strong> {selectedEvent?.data?.status === 1 ? "Pending" : selectedEvent?.data?.status === 5 ? "Confirmed" : selectedEvent?.data?.status === 2 ? "Cancelled" : selectedEvent?.data?.status === 3 ? "Closed" : "No Update"}
                        </li>
                        <li className="list-group-item">
                            <i className="far fa-calendar-alt text-primary"></i> <strong>Start Date:</strong> {moment(selectedEvent?.data?.start_date).format('MMMM Do, YYYY')}
                        </li>
                        <li className="list-group-item">
                            <i className="far fa-calendar-alt text-primary"></i> <strong>End Date:</strong> {moment(selectedEvent?.data?.end_date).format('MMMM Do, YYYY')}
                        </li>
                        <li className="list-group-item">
                            <i className="far fa-clock text-primary"></i> <strong>Time:</strong> {moment(selectedEvent?.start).format('hh:mm A')} - {moment(selectedEvent?.end).format('hh:mm A')}
                        </li>
                        <li className="list-group-item">
                            <i className="far fa-clock text-primary"></i> <strong>Hours Per Week:</strong> {selectedEvent?.data?.hours_per_week}
                        </li>

                        <li className="list-group-item">
                            <i className="fas fa-hashtag text-primary"></i> <strong>No. Of Week:</strong> {selectedEvent?.data?.no_of_weeks}
                        </li>
                        <li className="list-group-item">
                            <i className="fas fa-dollar-sign text-primary"></i> <strong>Pay Details:</strong> {selectedEvent?.data?.pay_details}
                        </li>
                        <li className="list-group-item">
                            <i className="fas fa-graduation-cap text-primary"></i> <strong>Qualifications:</strong> {selectedEvent?.data?.qualifications}
                        </li>
                        {selectedEvent?.data?.description && <li className="list-group-item">
                            <i className="fas fa-comment text-primary"></i> <strong>Client Comment:</strong> {selectedEvent?.data?.description}
                        </li>}

                    </ul>}

                <div className="card-footer d-flex justify-content-center">
                    {(selectedEvent?.data?.status === 5 && selectedEvent?.data?.category === 'TC') && <><button className="btn btn-success me-2" onClick={() => onDownloadICS(selectedEvent?.data)}>
                        <i className="far fa-calendar-plus"></i> Download (.ics)
                    </button>
                        <button className="btn btn-danger me-2" onClick={() => onGetNotify(selectedEvent?.data)}>
                            <i className="fas fa-bullhorn"></i> Departure Alert
                        </button></>}
                    {(selectedEvent?.data?.status === 2 && selectedEvent?.data?.category !== 'TC') && <><button className="btn btn-success me-2" onClick={() => onDownloadICS(selectedEvent?.data)}>
                        <i className="far fa-calendar-plus"></i> Download (.ics)
                    </button>
                        <button className="btn btn-danger me-2" onClick={() => onGetNotify(selectedEvent?.data)}>
                            <i className="fas fa-bullhorn"></i> Departure Alert
                        </button></>}
                    <button className="btn btn-primary" onClick={handleCloseModal}>
                        Close
                    </button>
                </div>
            </div>
        )
    }


    const handleSelectChange = (event) => {
        setSelectedOption(event);
    }
    const printCalendar = () => {
        var divContents = document.getElementById("calendar").innerHTML;
        var printWindow = window.open('', '', 'height=400,width=800');
        printWindow.document.write(`<html><head><title>${userData?.first_name}_${userData?.last_name}</title></head><body>`);
        printWindow.document.write(divContents);
        printWindow.document.write('<link rel="stylesheet" type="text/css" href="https://unpkg.com/react-big-calendar@1.8.5/lib/css/react-big-calendar.css">');
        printWindow.document.write('<style>');
        printWindow.document.write(`
            .rbc-row-content .rbc-row:not(:first-child):not(:nth-child(2)):not(:last-child) {
                display: none;
            }
            .rbc-overlay {
                max-height: 200px;
                overflow-y: auto;
            }
            .rbc-show-more {
                position: absolute;
                top: 0;
            }
        `);
        printWindow.document.write('</style></head><body>');
        printWindow.document.close();
        printWindow.print();

    }
    const downloadCalendar = () => {
        StaffsStore.toggleLoading(true);
        // Clone and append the calendar content
        const calendarContent = document.getElementById('calendar').cloneNode(true);
        const opt = {
            filename: `${userData?.first_name}_${userData?.last_name}.pdf`,
            margin: [10, 10, 10, 10],
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 1, letterRendering: false },
            jsPDF: { orientation: 'landscape' }
        };

        // Start PDF creation
        html2pdf().from(calendarContent).set(opt).save().then(() => {
            StaffsStore.toggleLoading(false);
        });
    }
    return (
        <React.Fragment>
            <Header />
            <Helmet>
                <title>Staffers LLC | My Calendar</title>
            </Helmet>
            <>
                <div className="container-fluid callback-small">
                    <div className="container-fluid mt-5 pt-5">
                        <div className="container mt-lg-5 mt-0 mb-3">
                            <h5 className="display-5 animated slideInDown login-head">My Calendar</h5>
                            <div className="row justify-content-center">
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="row bg-white pb-2">
                            <div className='d-flex flex-wrap align-items-center justify-content-center mt-2'>
                                {<ToolbarDropdown labelProperty={"label"} valueProperty={"value"} isMulti={true} placeholder={"Select Status"} selectedOption={selectedOption} slotStatusList={slotStatusListCaregiver} handleSelectChange={handleSelectChange} />}
                                <button type="button" onClick={printCalendar} className="btn btn-primary mb-2 ms-3 me-3">
                                    <i className="fas fa-print"></i> Print
                                </button>
                                <button type="button" onClick={downloadCalendar} className="btn btn-primary mb-2">
                                    <i className="fas fa-download"></i> Download
                                </button>
                            </div>
                            <div id="calendar">
                                <MyCalendar
                                    localizer={localizer}
                                    events={selectedOption?.length > 0 ? appliedShiftList?.filter(item => selectedOption?.some(filterItem => filterItem.value === item.data.status || (filterItem.value === 2 && item.data.status === 1 && item.data?.nominator_id))) : appliedShiftList}
                                    handleEventClick={handleEventClick}
                                    CustomEvent={CustomEvent}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </>
            <CustomModal
                isOpen={selectedEvent ? true : false}
                closeModal={handleCloseModal}
                title={"Shift Details"}
                content={<ViewEvent />}
            />
            <Spinner isLoading={StaffsStore.loading} />
        </React.Fragment >
    )
})

export default AppliedShiftsCalendar;
const CustomEvent = ({ event }) => (
    <div className='' >
        <div>
            <small> {event.title}</small>
            {event?.data?.category !== 'TC' && <>
                <div className={(event?.data?.status === 3 || event?.data?.status === 5 || event?.data?.status === 6) ? 'text-decoration-line-through' : ''}>
                    <small>{`Time: ${moment(event.start).format('hh:mm A')} - ${moment(event.end).format('hh:mm A')}`}</small>
                </div>
                <small>Status: {(event?.data?.status === 1 && !event?.data?.nominator_id) ? "Pending" : event?.data?.status === 2 || (event?.data?.status === 1 && event?.data?.nominator_id) ? "Confirmed" : (event?.data?.status === 3 || event?.data?.status === 5 || event?.data?.status === 6) ? "Cancelled" : event?.data?.status === 7 ? "Completed" : "No Update"}</small>
            </>}
            {event?.data?.category === 'TC' && <>
                <div className={(event?.data?.status === 2) ? 'text-decoration-line-through' : ''}>
                    <small>{`Start: ${moment(event.start).format('hh:mm A')} - ${moment(event.end).format('hh:mm A')}`}</small>
                </div>
                {/* <small>Status: {event?.data?.status === 0 ? "Pending" : event?.data?.status === 5 ? "Upcoming" : (event?.data?.status === 2) ? "Cancelled" : event?.data?.status === 3 ? "Closed" : "No Update"}</small> */}
            </>}
        </div>

    </div >
);