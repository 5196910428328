import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const HeaderWeb = () => {
  // search header code start //
  const [isScrolled, setIsScrolled] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 50);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // search header code end //

  //============= open sidebar on hover ===================//
  const [open, setOpen] = useState(false);

  const toggleSideMenu = () => {
    setOpen((prev) => !prev);
  };

  // Function to close the sidebar when clicking outside of it
  const handleMouseLeave = (event) => {
    setTimeout(() => {
      setOpen(false);
      document
        .getElementById("side_menuID")
        .classList.remove("show_side_bar_with_animation");
    }, 1500);
  };

  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    // Update isMobile state on window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the threshold as needed
    };

    // Initial check on component mount
    handleResize();

    // Attach event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <header
        className={isScrolled ? "web-header scroll-show" : "web-header"}
        id="headerId"
      >
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img
                src="/assets/images/web-images/staffers_logo.svg"
                alt="logo"
                className=""
              />
            </Link>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                <li className="nav-item dropdown">
                  <Link
                    to="/local"
                    onClick={() => {
                      navigate("/local");
                    }}
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Local Jobs
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        <li className="me-2">
                          <Link className="dropdown-item" to="/search-jobs">
                            <div className="d-flex align-items-center">
                              <img
                                src="/assets/images/website/searchjobs.png"
                                className="img-fluid"
                                alt="icon"
                              />
                              <p>
                                Search Jobs
                                <span>Career Search Hub</span>
                              </p>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/scheduling-local-job"
                          >
                            <div className="d-flex align-items-center">
                              <img
                                src="/assets/images/website/schedulinglocajobs.png"
                                className="img-fluid"
                                alt="icon"
                              />
                              <p>
                                Scheduling Local Jobs
                                <span>Job Shift Scheduler</span>
                              </p>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/pay-and-benefit-local"
                          >
                            <div className="d-flex align-items-center">
                              <img
                                src="/assets/images/website/payandbenefitelocal.png"
                                className="img-fluid"
                                alt="icon"
                              />
                              <p>
                                Pay & Benefits Local
                                <span>Payroll and Perks Overview</span>
                              </p>
                            </div>
                          </Link>
                        </li>
                      </div>
                      <div className="col-lg-6">
                        <li>
                          <Link className="dropdown-item" to="/faqs-local">
                            <div className="d-flex align-items-center">
                              <img
                                src="/assets/images/website/faqlocal.png"
                                className="img-fluid"
                                alt="icon"
                              />
                              <p>
                                FAQ's Local
                                <span>Local Enquiry Clarifications</span>
                              </p>
                            </div>
                          </Link>
                        </li>
                      </div>
                    </div>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/travel"
                    className="nav-link dropdown-toggle"
                    onClick={() => {
                      navigate("/travel");
                    }}
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Travel Jobs
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <div className="row">
                      <div className="col-lg-6 m">
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/how-to-become-a-traveler"
                          >
                            <div className="d-flex align-items-center">
                              <img
                                src="/assets/images/website/payandbenefitelocal.png"
                                className="img-fluid"
                                alt="icon"
                              />
                              <p>
                                How to become a Traveller
                                <span>Starting Your Travel Journey</span>
                              </p>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/pay-and-benefits-travel"
                          >
                            <div className="d-flex align-items-center">
                              <img
                                src="/assets/images/website/payandbenefitstravel.png"
                                className="img-fluid"
                                alt="icon"
                              />
                              <p>
                                Pay & Benefits - Travel
                                <span>Payroll and Perks Abroad</span>
                              </p>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/faqs-travel">
                            <div className="d-flex align-items-center">
                              <img
                                src="/assets/images/website/faqlocal.png"
                                className="img-fluid"
                                alt="icon"
                              />
                              <p>
                                FAQ's Travel
                                <span>Exploring Travel Information</span>
                              </p>
                            </div>
                          </Link>
                        </li>
                      </div>

                      <div className="col-lg-6">
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/licensure-compliance"
                          >
                            <div className="d-flex align-items-center">
                              <img
                                src="/assets/images/website/licensureandcompliance.png"
                                className="img-fluid"
                                alt="icon"
                              />
                              <p>
                                Licensure & Compliance
                                <span>Compliance and License Adherence</span>
                              </p>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/housing">
                            <div className="d-flex align-items-center">
                              <img
                                src="/assets/images/website/housinglogo.png"
                                className="img-fluid"
                                alt="icon"
                              />
                              <p>
                                Housing
                                <span>Comprehensive Housing Assistance</span>
                              </p>
                            </div>
                          </Link>
                        </li>
                      </div>
                    </div>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    onClick={() => {
                      navigate("/about-us");
                    }}
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Company
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <div className="row">
                      <div className="col-lg-6 m">
                        <li>
                          <a
                            className="dropdown-item"
                            href="http://staffersllc.blueskymss.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="d-flex align-items-center">
                              <img
                                src="/assets/images/website/accesstobueskyportal.png"
                                className="img-fluid"
                                alt="icon"
                              />
                              <p>
                                Access to BlueSky Portal
                                <span>BlueSky Portal Entry</span>
                              </p>
                            </div>
                          </a>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/important-links">
                            <div className="d-flex align-items-center">
                              <img
                                src="/assets/images/website/importantlinks.png"
                                className="img-fluid"
                                alt="icon"
                              />
                              <p>
                                Important Links
                                <span>Essential Web Links</span>
                              </p>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/testimonials">
                            <div className="d-flex align-items-center">
                              <img
                                src="/assets/images/website/whyworkwithstaffers.png"
                                className="img-fluid"
                                alt="icon"
                              />
                              <p>
                                Testimonials
                                <span>Users highly recommend us.</span>
                              </p>
                            </div>
                          </Link>
                        </li>
                      </div>
                      <div className="col-lg-6">
                        <li>
                          <Link className="dropdown-item" to={"/about-us"}>
                            <div className="d-flex align-items-center">
                              <img
                                src="/assets/images/website/whyworkwithstaffers1.png"
                                className="img-fluid"
                                alt="icon"
                              />
                              <p>
                                About Us
                                <span>Know all about Staffers</span>
                              </p>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to={"/contact-us"}>
                            <div className="d-flex align-items-center">
                              <img
                                src="/assets/images/website/whotocontact.png"
                                className="img-fluid"
                                alt="icon"
                              />
                              <p>
                                Contact Us
                                <span>Reach Out to Us</span>
                              </p>
                            </div>
                          </Link>
                        </li>

                        <li>
                          <Link
                            className="dropdown-item"
                            to="/why-work-with-staffers"
                          >
                            <div className="d-flex align-items-center">
                              <img
                                src="/assets/images/website/whyworkwithstaffers.png"
                                className="img-fluid"
                                alt="icon"
                              />
                              <p>
                                Why work with Staffers
                                <span>
                                  Collaboration with Experienced Professionals
                                </span>
                              </p>
                            </div>
                          </Link>
                        </li>
                      </div>
                    </div>
                  </ul>
                </li>
                <li>
                  <div className="mobile-show">
                    <NavLink
                      className="common-web-btn purple-border mx-2 p-2 fw-600 mt-2"
                      to="/search-jobs"
                    >
                      <small>
                        <i className="fa fa-search"></i> Jobs
                      </small>
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div className="mobile-show">
                    <NavLink
                      className="common-web-btn purple-back p-2 mx-2 mt-2"
                      to="/facilities-detail"
                    >
                      <small>
                        {/* <i className="fa fa-search"></i>  */}
                        Facilities/Employers?
                      </small>
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div className="mobile-show">
                    <NavLink className="p-2 " to="/login/employee">
                      <NavLink
                        className="common-web-btn purple-back p-2 mt-2"
                        to="/login/employee"
                      >
                        <small>
                          <i className="fas fa-sign-in-alt"></i> Login
                        </small>
                      </NavLink>
                    </NavLink>
                  </div>
                </li>
              </ul>
            </div>

            <div className="web-show">
              <NavLink
                className="common-web-btn purple-back mx-2 p-2 fw-600"
                to="/search-jobs"
              >
                <small>
                  <i className="fa fa-search"></i> Jobs
                </small>
              </NavLink>

              <NavLink
                className="common-web-btn purple-back p-2 mx-2"
                to="/search-caregivers"
              >
                <small>
                  <i className="fa fa-search"></i> Caregivers
                </small>
              </NavLink>
              <NavLink
                className="common-web-btn purple-border p-2 mx-2"
                to="/login/employee"
              >
                <small>
                  <i className="fas fa-sign-in-alt"></i> Login
                </small>
              </NavLink>
              <NavLink
                className="common-web-btn purple-border p-2 mx-2"
                to="/register/employee"
              >
                <small>
                  <i className="fa fa-user" aria-hidden="true"></i> Sign Up
                </small>
              </NavLink>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default HeaderWeb;
