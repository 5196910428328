/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react"
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import AdminStore from "@store/AdminStore";
import HomeStore from "@store/HomeStore";
import StaffsStore from "@store/StaffsStore";
import { toJS } from "mobx";
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import './JobProfileRequestList.css'
import Spinner from "@components/Spinner";
import { BASE_API_URL_FILE } from "@api/config"
import JobProfileStore from "@store/JobProfileStore";
import ReactDOM from "react-dom";
import moment from "moment";
import Select, { components } from 'react-select';
import { selectedKeysOne, selectedKeysTwo, selectedKeysThree, selectedKeysFour, selectedKeysFive, selectedKeysSix } from '@utils/constant/constant';
import { Helmet } from "react-helmet";
import { CButton, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CInputGroup, CFormInput, CFormCheck } from "@coreui/react";
import { useNavigate } from "react-router-dom";
import { FieldArray, Formik } from "formik";
import * as Yup from 'yup';
import { dropdownStatusStyles, colourStylesTitle, colourStylesState, colourStyles } from '@utils/customStyle/style';
import { CModal, CModalHeader, CModalTitle } from "@coreui/react";
import { generateTooltipHTML, checkAccountValidation, checkAccountValidationOnHover, convertToUserFriendly,onDownloadICS } from "../commonFunctions";
import { InputField } from "@elements/index"
import SelectField from "@elements/select/SelectField";
import { UpdateJobProfileSchema } from '@utils/schemas/allSchema';
import { Images } from "@elements";
import { toast } from "react-toastify";


var cities = require('@assets/city.json');
const $ = window.$;
var states = require('@assets/states.json');

const JobProfileRequestList = observer(() => {
    const navigate = useNavigate();
    const tableRef = useRef()
    const tableName = "table1"
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isOpenDistanceView, setIsOpenDistanceView] = useState(false);
    const [profile, setProfile] = useState();
    const [jobProfileList, setJobProfileList] = useState([]);
    const [regions, setRegions] = useState([]);
    const [infoSource, setInfoSource] = useState([]);
    const [devices, setDevices] = useState([]);
    const [requiredDoc, setRequiredDoc] = useState([]);
    const [addedCaregiver, setAddCaregiver] = useState();
    const [addedSpecilty, setAddSpecilty] = useState();
    const [addedDevices, setAddDevices] = useState();
    const [addedDoc, setAddDoc] = useState();
    const [addedPreferredState, setPreferredState] = useState();
    const [paginationLength, setPaginationLength] = useState(25);
    const [inputSearch, setInputSearch] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [progressOne, setProgressOne] = useState(0);
    const [progressTwo, setProgressTwo] = useState(0);
    const [progressThree, setProgressThree] = useState(0);
    const [progressFour, setProgressFour] = useState(0);
    const [progressFive, setProgressFive] = useState(0);
    const [progressSix, setProgressSix] = useState(0);
    const [staffData, setStaffData] = useState([]);
    const [missingData, setMissingData] = useState(null);
    const [openJobProfile, setOpenJobProfileEdit] = useState(false);
    const [specialties, setSpecialties] = useState([]);
    const [degree, setDegree] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const loadingPage = (load) => {
        setLoading(load)
    }
    useEffect(() => {
        AdminStore.updatePage();
        AdminStore.getJobProfileRequests(loadingPage);
        HomeStore.getInformationSource();
        HomeStore.getContactDevices();
        StaffsStore.getRegionsList();
        StaffsStore.getRequiredDoc();
    }, []);

    useEffect(() => {
        setDegree(HomeStore?.data?.degreeList)
    }, [HomeStore?.data?.degreeList]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        try {
            if (id) {
                const payload = JSON?.parse(id);
                let password = global.config.app_password
                let decrypt = CryptoJSAesJson.decrypt(payload, password);
                let popUpData = jobProfileList?.find(x => x.staff_id == decrypt)
                if (popUpData) {
                    showDetails(popUpData)
                }
            }
        } catch (error) {
            // Code to handle the error
            console.error('Error parsing JSON:', error);
        }


    }, [jobProfileList]);


    useEffect(() => {
        setJobProfileList(toJS(AdminStore?.data?.jobProfileList))
    }, [AdminStore?.data?.jobProfileList]);

    useEffect(() => {
        setRegions(StaffsStore?.data?.regionsList)
    }, [StaffsStore?.data?.regionsList]);

    useEffect(() => {
        setInfoSource(HomeStore?.data?.informationSource)
    }, [HomeStore?.data?.informationSource]);

    useEffect(() => {
        setDevices(HomeStore?.data?.contactDevices)
    }, [HomeStore?.data?.contactDevices]);

    useEffect(() => {
        setRequiredDoc(StaffsStore?.data?.requiredDocList)
    }, [StaffsStore?.data?.requiredDocList]);

    useEffect(() => {
        const renderPagination = () => {
            const table = $('#table1').DataTable();
            const totalPages = AdminStore.data.lastPage;
            const currentPage = AdminStore.data.page;
            const paginationContainer = $('.dataTables_paginate');
            const paginationList = $('<ul>', { class: 'pagination' });
            paginationContainer.empty().append(paginationList);

            let startPage = Math.max(1, currentPage - 2);
            let endPage = Math.min(totalPages, currentPage + 2);

            if (totalPages > 5 && currentPage <= 3) {
                endPage = 5;
            } else if (totalPages > 5 && currentPage >= totalPages - 2) {
                startPage = totalPages - 4;
            }
            const firstButton = $('<a>', {
                text: 'First',
                class: `page-link pointer ${currentPage === 1 ? 'disabled' : ''}`,
                click: function () {
                    if (currentPage > 1) {
                        AdminStore.data.page = 1;
                        AdminStore.getJobProfileRequests(loadingPage);
                        table.page('first').draw(false);
                    }
                },
            });

            const previousButton = $('<a>', {
                text: 'Previous',
                class: `page-link pointer ${currentPage === 1 ? 'disabled' : ''}`,
                click: function () {
                    if (currentPage > 1) {
                        AdminStore.data.page = AdminStore.data.page - 1;
                        AdminStore.getJobProfileRequests(loadingPage);
                        table.page('previous').draw(false);
                    }
                },
            });

            const nextButton = $('<a>', {
                text: 'Next',
                class: `page-link pointer ${currentPage === totalPages ? 'disabled' : ''}`,
                click: function () {
                    if (currentPage < totalPages) {
                        AdminStore.data.page = AdminStore.data.page + 1;
                        AdminStore.getJobProfileRequests(loadingPage);
                        table.page('next').draw(false);
                    }
                },
            });
            const lastButton = $('<a>', {
                text: 'Last',
                class: `page-link pointer ${currentPage === totalPages ? 'disabled' : ''}`,
                click: function () {
                    if (currentPage < totalPages) {
                        AdminStore.data.page = AdminStore.data.lastPage;
                        AdminStore.getJobProfileRequests(loadingPage);
                        table.page('last').draw(false);
                    }
                },
            });

            const firstListItem = $('<li>', { class: `page-item ${currentPage === 1 ? 'disabled' : ''}` }).append(firstButton);
            const previousListItem = $('<li>', { class: `page-item ${currentPage === 1 ? 'disabled' : ''}` }).append(previousButton);
            const nextListItem = $('<li>', { class: `page-item ${currentPage === totalPages ? 'disabled' : ''}` }).append(nextButton);
            const lastListItem = $('<li>', { class: `page-item ${currentPage === totalPages ? 'disabled' : ''}` }).append(lastButton);
            paginationList.append(firstListItem);
            paginationList.append(previousListItem);

            for (let i = startPage; i <= endPage; i++) {
                const pageLink = $('<a>', {
                    text: i,
                    class: `page-link pointer ${i === currentPage ? 'active' : ''}`,
                    click: function () {
                        AdminStore.data.page = i;
                        AdminStore.getJobProfileRequests(loadingPage);
                        table.page(i - 1).draw(false);
                    },
                });

                const listItem = $('<li>', { class: `page-item ${i === currentPage ? 'active' : ''}` }).append(pageLink);
                paginationList.append(listItem);
            }

            paginationList.append(nextListItem);
            paginationList.append(lastListItem);
        };
        $.fn.dataTable.ext.errMode = 'none';
        $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust();
        const table = $(`#${tableName}`).DataTable(
            {
                data: jobProfileList,
                order: [], // Disable initial sorting
                dom: 'Blfrtip',
                buttons: [
                    'copy', 'csv', 'excel', 'print',
                ],
                pageLength: paginationLength,
                lengthMenu: [25, 50, 100, 500, 1000,2000,3000,5000,10000],
                columns: [

                    {
                        title: "Bluesky Id", data: 'staffer_id', width: 50, "render": function (data, type, full, meta) {
                            return data || "-"
                        }
                    },
                    {
                        title: "Name", width: 100, "render": function (data, type, row, meta) {
                            return '<a class="link-info pointer">' + (row?.first_name || row?.staff_profile?.first_name) + " " + (row?.middle_name || "") + " " + (row?.last_name || row?.staff_profile?.last_name) + '</a>'
                                + (checkAccountValidation(row, "caregiver") ? `<span id="check-validation" class="text-warning ps-2"><i class="fas fa-exclamation-triangle"></i></span>` : "")
                        }
                    },
                    {
                        title: "Email", data: "email", "render": function (data, type, row, meta) {
                            return row?.staff_profile?.email || "-";
                        }
                    },
                    
                    {
                        title: "Profession/Specialty", data: "degree_specialities", "render": function (data, type, row, meta) {
                            const professionNames = data && (JSON?.parse(data)?.map(x => x?.specialties_name)[0] === undefined ? JSON?.parse(data)?.map(x => x?.profession_name) : JSON?.parse(data)?.map(x => x?.specialties_name));
                            return professionNames?.join("<br/>") || "-";
                        }
                    },
                    {
                        title: "Contact", data: "staff_profile", "render": function (data, type, row, meta) {
                            return data?.contact ? (data?.country_code +" " + data?.contact) : "-"
                        }
                    },
                    {
                        title: "Apply & Hire Date", width: 100, "render": function (data, type, row, meta) {
                            return (row?.apply_date ? row?.apply_date : "-") + (row?.hire_date ? " & " : "") + (row?.hire_date ? row?.hire_date : "");
                        }
                    },
                    {
                        title: "Bluesky Status", width: 100, data: "status", "render": function (data, type, row, meta) {
                            return global?.config?.blueSkyStatusOptions?.find(x=>x.value === data)?.text;
                        }
                    },
                    {
                        title: "Account Status", data: "staff_profile", width: 100, "render": function (data, type, row, meta) {
                            return row?.staff_profile?.status == '0' ? '<span class="text-warning">' + "Pending" + '</span>' : row?.staff_profile?.status == '1' ? '<span class="text-success">' + "Active" + '</span>' : row?.staff_profile?.status == '2' ? '<span class="text-danger">' + "Rejected" + '</span>' : row?.staff_profile?.status == '3' ? 'Blocked' : 'No Update'
                        }
                    },
                    {
                        title: "Action", data: ""
                    },
                    {
                        title: "Area Preference", width: 50, "render": function (data, type, full, meta) {
                            return full?.preferred_cities ? full?.work_distance + " miles/" + JSON?.parse(full?.preferred_cities)?.map(({ label }) => label) : "-"
                        }
                    },
                    {
                        title: "Status Reason", width: 100, data: "status_reason", "render": function (data, type, row, meta) {
                            const label = global?.config?.reasonoptions?.find(x => x.value == data)?.label || "-";
                            return `<strong>${label}</strong>`;
                        }
                    },
                    {
                        title: "Last Updated By", width: 100, data: 'last_updated_by', "render": function (data, type, row, meta) {
                            return data ? (data?.first_name + " " + data?.last_name) : "-"
                        }
                    },
                    {
                        title: "Last Update", width: 100, data: 'updated_at', "render": function (data, type, row, meta) {
                            return moment(data).fromNow() || "-";
                        }
                    },
                    {
                        title: "Region", width: 100, data: 'region_id', "render": function (data, type, row, meta) {
                            return regions?.find(reg => reg.ID == data)?.Name|| "-";
                        }
                    },                   
                ],
                columnDefs: [
                    {
                        targets: 1,
                        responsivePriority: 2,
                        createdCell: function (td, cellData, rowData, row, col) {
                            if (col !== 1) return; // Exit if not column index 1

                            var tdId = $(td).find('span').attr('id');
                            if (tdId === 'check-validation') {
                                // Update tooltip content with HTML returned by checkAccountValidationOnHover
                                const tooltipContent = checkAccountValidationOnHover(rowData, "caregiver");
                                $(td).attr('data-toggle', 'tooltip');
                                $(td).attr('data-html', 'true');
                                $(td).attr('data-placement', 'top');
                                $(td).attr('title', generateTooltipHTML(tooltipContent));
                            }
                        }
                    },
                    {
                        targets: 3,
                        responsivePriority: 4,
                    },
                    {
                        targets: 5,
                        responsivePriority: 5,
                    },

                    {
                        targets: 8,
                        responsivePriority: 1,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <CDropdown variant="btn-group" direction='dropstart' alignment="end">
                                        <CDropdownToggle size="sm" color="transparent" caret={false} href="#" className="p-0"><i className="fa fa-ellipsis-v text-primary"></i></CDropdownToggle>
                                        <CDropdownMenu >
                                            {<CDropdownItem className="more-menu">
                                                <CButton onClick={() => {
                                                    showDetails(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    Job Profile <i className="fas fa-id-card-alt"></i>
                                                </CButton>
                                            </CDropdownItem>}
                                            {rowData?.staff_profile?.bluesky_staff_id && <CDropdownItem className="more-menu">
                                                <CButton onClick={() => {
                                                    onSetMaxDist(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    Update Work Area <i className="fas fa-map-marked-alt"></i>
                                                </CButton>
                                            </CDropdownItem>}
                                            {rowData?.staff_profile?.bluesky_staff_id && <CDropdownItem className="more-menu">
                                                <CButton onClick={() => {
                                                    showShiftDetails(rowData?.staff_profile)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    View Shifts <i className="far fa-calendar-alt"></i>
                                                </CButton>
                                            </CDropdownItem>}
                                            {rowData?.staff_profile?.bluesky_staff_id && <CDropdownItem className="more-menu">
                                                <CButton onClick={() => {
                                                    showCalendarDetails(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    View Calendar <i className="far fa-calendar-alt"></i>
                                                </CButton>
                                            </CDropdownItem>}
                                            {rowData?.staff_profile?.bluesky_staff_id && <CDropdownItem className="more-menu">
                                                <CButton onClick={() => {
                                                    onDownloadICS(rowData?.staff_profile)
                                                }} size="sm" className="w-100" variant='outline' color="success" >
                                                    Download (.ics) <i className="far fa-calendar-plus"></i>
                                                </CButton>
                                            </CDropdownItem>}
                                            <CDropdownItem className="more-menu">
                                                <CButton onClick={() => {
                                                    onEdit(rowData?.staff_profile)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    Edit Account <i className="fas fa-edit"></i>
                                                </CButton>
                                            </CDropdownItem>
                                            {
                                                <CDropdownItem className="more-menu">
                                                    <CButton onClick={() => {
                                                        onEditJobProfile(rowData)
                                                    }} size="sm" className="w-100" variant='outline' color="primary" >
                                                        Edit Job Profile <i className="fas fa-edit"></i>
                                                    </CButton>
                                                </CDropdownItem>
                                            }

                                            {rowData?.staff_profile?.status != 0 && (
                                                <><CDropdownItem>
                                                    <CButton
                                                        onClick={() => {
                                                            onBlock(rowData?.staff_profile)
                                                        }}
                                                        size="sm" className="w-100" variant='outline' color={rowData?.staff_profile?.status === 3 ? "success" : "danger"}>
                                                        {rowData?.staff_profile?.status === 3 ? 'Activate' : 'Block'}    {row?.staff_profile?.status === 3 ? <i className="fas fa-user-check"></i> : <i className="fas fa-ban"></i>}
                                                    </CButton>
                                                </CDropdownItem></>)}
                                            <CDropdownItem>
                                                <CButton
                                                    onClick={() => {
                                                        onDelete(rowData?.staff_profile?.id)
                                                    }}
                                                    size="sm" className="w-100" variant='outline' color="danger">
                                                    Delete <i className="fas fa-trash"></i>
                                                </CButton>
                                            </CDropdownItem>
                                        </CDropdownMenu>
                                    </CDropdown>
                                </div>
                                , td),
                    },

                    {
                        targets: 6,
                        responsivePriority: 3,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div className="d-flex">
                                    {rowData?.status === 0 ? <span className="text-warning">Pending</span>
                                        : rowData?.status === 1 ? <span className="text-warning">Incomplete Applicant</span>
                                            : rowData?.status === 2 ? <span className="text-danger">Rejected</span>
                                                : rowData?.status === 3 ? <span className="text-success">Active</span>
                                                    : rowData?.status === 4 ? <span className="text-danger">In-Active</span> ://same
                                                        rowData?.status === 5 ? <span className="text-info">Applicant</span> ://same
                                                            rowData?.status === 6 ? <span className="text-success">Pre-active</span>//same
                                                                : rowData?.status === null ? <span className="text-warning">Profile is incomplete</span> : 'No Update'
                                    }
                                    {rowData?.staffer_id && <div onClick={() => { checkLiveStatus(rowData) }} data-toggle="tooltip" data-placement="right" title="Update Status">
                                        &nbsp;<a href="#rotate" className="rotate-link"><i className="fas fa-sync-alt pointer"></i></a>
                                    </div>}
                                </div>
                                , td),
                    }
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                "language": {
                    search: '',
                    searchPlaceholder: "Search",
                    emptyTable: "No Record Found",
                    info: "Showing " + AdminStore.data.from + " to " + AdminStore.data.to + " of " + AdminStore.data.total + " entries",
                },
                initComplete: function () {
                    // Apply the search
                    this.api()
                        .columns([0, 1, 2, 3, 4, 5])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px" type="text" placeholder="Filter per page" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });

                    this.api()
                        .columns([6])//Bluesky search
                        .every(function () {
                            var column = this;
                            var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="">All</option></select>')
                                .appendTo($(column.header()))
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                    if (val === "") {
                                        column.search(val, true, false).draw();
                                    } else if (val === '-1') {
                                        column.search('^$', true, false, true).draw();

                                    } else {
                                        column.search('^' + val + '$', true, false).draw();
                                    }
                                });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            column
                                .data()
                                .unique()
                                .sort()
                                .each(function (d, j) {
                                    let opt = global.config.blueSkyStatusOptions?.find(stat => stat.value == d);
                                    select.append('<option value="' + opt?.value + '">' + opt?.text + '</option>');
                                });
                        });

                    this.api()//Acount search
                        .columns([7]).every(function () {
                            var column = this;
                            var select = $('<br/><select class="form-control" placeholder="filter" style="width:100%;margin-top:5px;background-color:white"><option value="" selected>All</option></select>')
                                .appendTo($(column.header()))
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                                });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            var options = []; // To store unique options
                            column.data().unique().sort().each(function (d, j) {
                                if (d) {
                                    let name = [{ "name": "Pending", "value": 0 }, { "name": "Active", "value": 1 }, { "name": "Rejected", "value": 2 }, { "name": "Blocked", "value": 3 }]?.find(item => item.value == d?.status)?.name;
                                    if (options.indexOf(name) === -1) {
                                        options.push(name); // Add to options array if not already present
                                        select.append('<option value="' + name + '">' + name + '</option>');
                                    }
                                }
                            });
                        });
                    this.api()//Response search
                        .columns([10]).every(function () {
                            var column = this;
                            var select = $('<br/><select class="form-control" placeholder="filter" style="width:100%;margin-top:5px;background-color:white"><option value="" selected>All</option></select>')
                                .appendTo($(column.header()))
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                                });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            var options = []; // To store unique options
                            column.data().unique().sort().each(function (d, j) {
                                if (d) {
                                    let name = global?.config?.reasonoptions?.find(item => item.value == d)?.label;
                                    if (options.indexOf(name) === -1) {
                                        options.push(name); // Add to options array if not already present
                                        select.append('<option value="' + name + '">' + name + '</option>');
                                    }
                                }
                            });
                        });

                },
                drawCallback: function () {
                    renderPagination();
                }
            });
        table.page.len(paginationLength).draw();
        $(`#${tableName}`).on('length.dt', function (e, settings, len) {
            handlePaginationLengthChange(len)
        });
        table.on('click', 'td', function () {
            // Get the column index of the clicked cell
            const columnIndex = table.cell(this).index()?.column;
            // Check if the clicked cell belongs to the desired column
            if (columnIndex === 1) {
                // Get the data for the clicked cell
                const rowData = table.row($(this).closest('tr')).data();
                // Handle the event for the clicked cell
                if (rowData) {
                    showDetails(rowData)
                }
            }
        });
        // Assuming 'icon' is the class name of the icon element you want to hover over
        // table.on('mouseover', 'td', function () {
        //     const columnIndex = table.cell(this).index()?.column;
        //     if (columnIndex === 1) {
        //         var tdId = $(this).find('span').attr('id');
        //         if (tdId === 'check-validation') {
        //             const rowData = table.row($(this).closest('tr')).data();
        //             if (rowData) {
        //                 $(this).tooltip('dispose');
        //                 // Update tooltip content with HTML returned by checkAccountValidationOnHover
        //                 const tooltipContent = checkAccountValidationOnHover(rowData);
        //                 console.log(tooltipContent);
        //                 $(this).tooltip({
        //                     title: generateTooltipHTML(tooltipContent),
        //                     trigger: 'hover',
        //                     html: true
        //                 });
        //                 $(this).tooltip('show');
        //             }
        //         }
        //     } else {
        //         return
        //     }
        // });

        // Extra step to do extra clean-up.
        return function () {
            $(`#${tableName}`).off('length.dt');
            table.destroy()
        }
    }, [jobProfileList]);

    const onDismiss = () => {
        setIsOpen(false);
        setIsChecked(false)
        setMissingData();
    }

    const showDetails = (item) => {
        if (item?.uc_response) {
            setIsChecked(true);
        }
        setAddCaregiver(item?.staff_profile?.bluesky_staff_id)
        setAddSpecilty(item?.specialty_updated)
        setAddDevices(item?.devices_updated)
        setAddDoc(item?.docs_updated)
        setPreferredState(item?.pref_locations_updated)
        setProfile(item)
        setIsOpen(true);
        setProgressOne((((selectedKeysOne?.filter(key => item[key] !== null && (typeof item[key] !== 'string' || item[key]?.trim() !== ''))?.length) / selectedKeysOne?.length) * 100)?.toFixed(0))
        setProgressTwo((((selectedKeysTwo?.filter(key => item[key] !== null && (typeof item[key] !== 'string' || item[key]?.trim() !== ''))?.length) / selectedKeysTwo?.length) * 100)?.toFixed(0))
        setProgressThree((((selectedKeysThree?.filter(key => item[key] !== null && (typeof item[key] !== 'string' || item[key]?.trim() !== ''))?.length) / selectedKeysThree?.length) * 100)?.toFixed(0))
        setProgressFour((
            ((selectedKeysFour?.filter(key => item[key] !== null && (!Array.isArray(item[key]) || item[key].length !== 0))?.length || 0) /
                (selectedKeysFour?.length || 1)) *
            100
        )?.toFixed(0)
        )
        setProgressFive((((selectedKeysFive?.filter(key => item[key] !== null && (typeof item[key] !== 'string' || item[key]?.trim() !== ''))?.length) / selectedKeysFive?.length) * 100)?.toFixed(0))
        setProgressSix((((selectedKeysSix?.filter(key => item[key] !== null && (typeof item[key] !== 'string' || item[key]?.trim() !== ''))?.length) / selectedKeysSix?.length) * 100)?.toFixed(0))
        let tooltipContent = checkAccountValidationOnHover(item, "caregiver");
        setMissingData(tooltipContent?.length === 0 ? null : (tooltipContent?.map(key => convertToUserFriendly(key) + " is missing!")))
    }

    const saveCaregiver = (item) => {
        JobProfileStore.loading = true
        let param = {
            request_id: item.id
        }
        JobProfileStore.addCaregiver(param, navigationCallBackAddCaregiver)
    }

    const navigationCallBackAddCaregiver = (data) => {
        setAddCaregiver(data);
        saveSpecialty(profile);
        AdminStore.ubCheckRequest({ "staff_id": profile.staff_id }, null)
    }

    const saveSpecialty = (item) => {
        JobProfileStore.loading = true
        let param = {
            request_id: item.id
        }
        JobProfileStore.addSpecialty(param, navigationCallBackAddSpecialty)
    }

    const navigationCallBackAddSpecialty = (data) => {
        setAddSpecilty(data)
        saveDevices(profile)
    }

    const saveDevices = (item) => {
        JobProfileStore.loading = true
        let param = {
            request_id: item.id
        }
        JobProfileStore.addDevices(param, navigationCallBackAddDevices)
    }

    const navigationCallBackAddDevices = (data) => {
        setAddDevices(data)
        saveDoc(profile)
    }
    const saveDoc = (item) => {
        JobProfileStore.loading = true
        let param = {
            request_id: item.id
        }
        JobProfileStore.uploadDocuments(param, navigationCallBackAddDoc)
    }

    const navigationCallBackAddDoc = (data) => {
        setAddDoc(data)
        savePreferredState(profile)
    }
    const savePreferredState = (item) => {
        JobProfileStore.loading = true
        let param = {
            request_id: item.id
        }
        JobProfileStore.addPreferredState(param, navigationCallBackAddedPreferredState)
    }

    const navigationCallBackAddedPreferredState = (data) => {
        setPreferredState(data)
        onStatus(profile)
    }

    const onStatus = (item) => {
        let param = {
            status: 1,
            staff_id: item.staff_id,
            request_id: item.id,
            remark: null
        }
        JobProfileStore.approveJobProfile(param, navigationCallBack)
    }

    const navigationCallBack = () => {
        JobProfileStore.toggleLoading(false);
        onDismiss();
        swal({
            title: "Successfully Onboarded",
            text: "Please check Caregiver list for job profile details!",
            icon: "success",
        }).then(() => {
            JobProfileStore.toggleLoading(false);
            AdminStore.getJobProfileRequests(loadingPage);
        })
    }

    const onRejected = (item) => {
        onDismiss();
        swal("Reasons for rejection:", {
            title: "Do you really want to reject?",
            icon: "warning",
            content: { element: "textarea" },
            buttons: ["Cancel", "Reject"],
        })
            .then((value) => {
                if (value) {
                    value = document.querySelector(".swal-content__textarea").value;
                    let param = {
                        status: 2,
                        staff_id: item.staff_id,
                        request_id: item.id,
                        remark: value
                    }
                    JobProfileStore.loading = true;
                    JobProfileStore.approveJobProfile(param, navigationCallBackRejected)
                } else {
                    setIsOpen(true)
                }
            });
    }

    const navigationCallBackRejected = () => {
        onDismiss();
        swal({
            title: "Successfully Rejected",
            icon: "success",
        }).then(() => {
            JobProfileStore.loading = false;
            AdminStore.getJobProfileRequests(loadingPage);
        })
    }
    const handleChange = (event) => {
        setInputSearch(event.target.value);
    };
    const handlePaginationLengthChange = (newPageSize) => {
        setPaginationLength(newPageSize)
        AdminStore.data.pageSize = newPageSize;
        AdminStore.getJobProfileRequests(loadingPage);
    };
    const onSearch = () => {
        AdminStore.data.search = inputSearch;
        AdminStore.getJobProfileRequests(loadingPage);
        setStaffData([]);
        setOpenJobProfileEdit(false);
    }

    const onClearSearch = () => {
        setInputSearch('');
        AdminStore.data.search = '';
        AdminStore.getJobProfileRequests(loadingPage);
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onSearch();
        }
    };

    const checkLiveStatus = (data) => {
        let param = {
            "staff_id": data?.staff_id
        }
        AdminStore.liveUpdateStatus(param, navigationCallBackLiveUpdate)
    }

    const navigationCallBackLiveUpdate = () => {
        setIsOpenDistanceView(false)
        AdminStore.getJobProfileRequests(loadingPage);
    }
    const checkLiveStatusForAll = () => {
        swal({
            title: "Do you really want to update all status?",
            text: "The update might take up to 15 minutes, Please don't refresh the page or logout!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    AdminStore.checkLiveStatusForAll()
                }
            });
    }
    const handleSelectChange = (event) => {
        if (event?.value) {
            setSelectedOption(event);
            AdminStore.data.status_id = event?.value;
            AdminStore.data.page = 1;
            AdminStore.data.pageSize = 25;
            AdminStore.data.lastPage = 0;
            AdminStore.data.total = 0;
            AdminStore.data.from = 0;
            AdminStore.data.to = 0;
            AdminStore.getJobProfileRequests(loadingPage);
        } else {
            setSelectedOption('');
            AdminStore.data.status_id = '';
            AdminStore.getJobProfileRequests(loadingPage);
        }
    };
    //ACCOUNT FUNCTION
    const onEdit = (item) => {
        const url = `/admin/staffs-edit?data=${encodeURIComponent(JSON.stringify(item))}`;
        window.open(url, '_blank');
        // navigate('/admin/staffs-edit', { state: { item: item } });
    }
    const onEditJobProfile = (item) => {
        setOpenJobProfileEdit(true)
        setIsOpen(false)
        setStaffData(item)
        if ((item && item?.degree_specialities) && JSON.parse(item?.degree_specialities).length > 0) {
            let degree = JSON.parse(item?.degree_specialities);
            var arr = [];
            degree?.map(async (element, index) => {
                await StaffsStore.getSpecialtiesList(element?.profession).then((data) => {
                    let sItems = [...arr];
                    let sItem = { ...sItems[index] };
                    sItem = data;
                    sItems[index] = sItem;
                    arr = sItems;
                    setSpecialties(arr)
                })
            });
        }
    }
    const onBlock = (item) => {
        swal({
            title: item?.status === 3 ? "Do you really want to unblock user?" : "Do you really want to block?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    AdminStore.blockStaff({ id: item?.id }, navigationCallBackLiveUpdate)
                }
            });
    }
    const onDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this admin details!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    AdminStore.deleteStaff({ id: id }, navigationCallBackLiveUpdate)
                }
            });
    }
    //Distance Modal
    const onSetMaxDist = (data) => {
        setStaffData(data)
        setIsOpenDistanceView(true)
    }
    const onDismissDistanceView = () => {
        setIsOpenDistanceView(false)
    }
    const onDismissJobProfile = () => {
        setOpenJobProfileEdit(false)
        setStaffData([])
    }

    const showShiftDetails = (item) => {
        const caregiver = {
            id: item?.id,
            first_name: item?.first_name,
            last_name: item?.last_name,
            bluesky_staff_id: item?.bluesky_staff_id,
        }
        const url = `/admin/caregiver-shift?data=${encodeURIComponent(JSON.stringify(caregiver))}`;
        window.open(url, '_blank');
    }
    const showCalendarDetails = (item) => {
        const caregiver = {
            id: item?.staff_id,
            first_name: item?.first_name,
            last_name: item?.last_name,
            bluesky_staff_id: item?.staffer_id,
            profession: item?.degree_specialities,
        }
        const url = `/admin/caregiver-calendar?data=${encodeURIComponent(JSON.stringify(caregiver))}`;
        window.open(url, '_blank');
    }

    const updateWorkPreference = (values) => {
        const param = {
            "staff_id": values?.staff_id,
            "miles": values.miles,
            "cities": JSON.stringify(values?.cities),
            "states": JSON.stringify(values?.states),
        }
        AdminStore.updateWorkPreference(param, navigationCallBackLiveUpdate)
    }

    const setShowSyncModal = () => {
        swal({
            title: "Import Caregiver",
            icon: "info",
            buttons: true,
            dangerMode: true,
            content: {
                element: "input",
                attributes: {
                    placeholder: "Enter bluesky id",
                    type: "number",
                    required: true,
                },
            },
        })
            .then((id) => {
                if (id) {
                    let param = {
                        bluesky_id: id
                    }
                    AdminStore.syncCaregiverWithBluesky(param, navigationCallBackSync)
                }
            });
    }

    const navigationCallBackSync = () => {
        swal("Success", "Successfully caregiver imported", "success").then((value) => {
            // This block will be executed when the user interacts with the swal dialog
            // You can put your code here to handle the user's action
            onSearch()
        });
    }
    const onGetSpecialties = (e, index) => {
        setLoading(true)
        StaffsStore.getSpecialtiesList(e).then((data) => {
            let sItems = [...specialties];
            let sItem = { ...sItems[index] };
            sItem = data;
            sItems[index] = sItem;
            setSpecialties(sItems)
            setLoading(false)

        })
    }

    const updateJobProfileSchema = (values) => {
        const param = {
            'profile_id': values.profile_id,
            'external_id': values.external_id,
            'staffer_id': values.staffer_id,
            'region_id': values.region_id,
            'work_distance': values.work_distance,
            'degree_specialities': values.degree_specialities && JSON.stringify(values.degree_specialities),
            'preferred_state': values.preferred_state && JSON.stringify(values.preferred_state),
            'preferred_cities': values.preferred_cities && JSON.stringify(values.preferred_cities),
        }
        AdminStore.updateJobProfile(param, onSearch)
    }
    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    }

    const navigationCallBackUBC = (response) => {
        if (response) {
            onDismiss();
            toast.success("Successfully requested for a background check.");
            setLoading(false);
            onSearch();
        } else {
            setLoading(false);
            toast.error("The request for a background check has failed!");
        }

    }
    return (
        <React.Fragment>
            <Helmet>
                <title>Staffers LLC | Caregivers List</title>
            </Helmet>
            {/* <body> */}
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="p-3 bg-white row">
                            <h3 className="page-title col-6 col-lg-3">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="far fa-id-card"></i>
                                </span> Caregivers
                            </h3>
                            <div className='col-6 col-lg-3 text-end'>
                                <a onClick={() => setShowSyncModal()}>
                                    <h1 className="page-title signup-link fs-4" >
                                        <span className="page-title-icon linear text-white me-2">
                                            <i className="fas fa-plus"></i>
                                        </span>Import Caregiver
                                    </h1>
                                </a>
                            </div>
                            <div className="col-12 col-lg-3  mt-lg-0 mt-3">
                                <Select id="selectInput"
                                    placeholder={selectedOption ? "" : "Bluesky Status"}
                                    options={global.config.blueSkyStatusOptionsFilter}
                                    getOptionLabel={(option) => option.text}
                                    onChange={handleSelectChange}
                                    value={selectedOption}
                                    styles={dropdownStatusStyles}
                                    isClearable
                                />
                            </div>
                            <div className="col-12 col-lg-3 p-0 mt-lg-0 mt-3">
                                <CInputGroup>
                                    <CFormInput placeholder="Search" value={inputSearch} disabled={AdminStore.data.search} onChange={handleChange} onKeyPress={handleKeyPress} />
                                    {inputSearch && (
                                        <CButton onClick={onClearSearch} type="button" color="primary" variant="outline">
                                            <i className="fa fa-times" aria-hidden="true"></i>
                                        </CButton>
                                    )}
                                    <CButton onClick={onSearch} type="button" color="primary" variant="outline">
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </CButton>
                                </CInputGroup>
                            </div>
                        </div>


                        {/* <!-- partial --> */}
                        <div className="col-lg-12">
                            <div className="row bg-white table-responsive" style={{ height: 'auto', minHeight: 500, paddingTop: 20 }}>
                                <div className="table-wrapper">
                                    <table className="table dt-responsive table-hover nowrap" style={{ width: "100%" }} id={tableName} ref={tableRef}>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div>
            <BottomSheet open={isOpen} onDismiss={onDismiss}
                header={
                    <div>
                        <div className="text-end" style={{ cursor: "pointer" }} onClick={onDismiss}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </div>
                        <div className="row">
                            <div className="col-md-4 text-start">
                                <h4 className="text-xl font-bold text-primary-800">
                                    {profile?.first_name?.toUpperCase()} PROFILE DETAILS
                                    {missingData ? <span onClick={() => onEditJobProfile(profile)} className="text-warning ps-2"
                                        data-bs-toggle="tooltip" data-bs-placement="top"
                                        title={missingData}>
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </span> : <img className="ps-2 pb-2" src={Images.verified} width={40} alt="Verified" />}
                                </h4>
                            </div>
                            <div className="col-md-8 d-flex justify-content-start text-start overflow-auto" >
                                {profile?.staff_profile?.is_imported !== 1 && <>
                                    <div className="progressBox  me-3">
                                        <div className="progress">
                                            <div className="progress-bar progress-bar-striped stripBar"
                                                role="progressbar"
                                                style={{ width: `${progressOne}%` }}
                                                aria-valuenow={progressOne} aria-valuemin="0" aria-valuemax="100">
                                                {progressOne}%
                                            </div>
                                        </div>
                                        <small>Personal Info</small>
                                    </div>
                                    <div className="progressBox  me-3">
                                        <div className="progress">
                                            <div className="progress-bar progress-bar-striped stripBar"
                                                role="progressbar"
                                                style={{ width: `${progressTwo}%` }}
                                                aria-valuenow={progressTwo} aria-valuemin="0" aria-valuemax="100">
                                                {progressTwo}%
                                            </div>
                                        </div>
                                        <small>Professional Info</small>
                                    </div>
                                    <div className="progressBox  me-3">
                                        <div className="progress">
                                            <div className="progress-bar progress-bar-striped stripBar"
                                                role="progressbar"
                                                style={{ width: `${progressThree}%` }}
                                                aria-valuenow={progressThree} aria-valuemin="0" aria-valuemax="100">
                                                {progressThree}%
                                            </div>
                                        </div>
                                        <small>Availability</small>
                                    </div>
                                    <div className="progressBox  me-3">
                                        <div className="progress ">
                                            <div className="progress-bar progress-bar-striped stripBar "
                                                role="progressbar"
                                                style={{ width: `${progressFour}%` }}
                                                aria-valuenow={progressFour} aria-valuemin="0" aria-valuemax="100">
                                                {progressFour}%
                                            </div>
                                        </div>
                                        <small>Documents</small>
                                    </div>
                                    <div className="progressBox  me-3">
                                        <div className="progress ">
                                            <div className="progress-bar progress-bar-striped stripBar "
                                                role="progressbar"
                                                style={{ width: `${progressFive}%` }}
                                                aria-valuenow={progressFive} aria-valuemin="0" aria-valuemax="100">
                                                {progressFive}%
                                            </div>
                                        </div>
                                        <small>Acknowledgments</small>
                                    </div>
                                    <div className="progressBox  me-3">
                                        <div className="progress ">
                                            <div className="progress-bar progress-bar-striped stripBar "
                                                role="progressbar"
                                                style={{ width: `${progressSix}%` }}
                                                aria-valuenow={progressSix} aria-valuemin="0" aria-valuemax="100">
                                                {progressSix}%
                                            </div>
                                        </div>
                                        <small>Finish</small>
                                    </div>
                                </>}
                            </div>
                        </div>


                    </div>
                }
                footer={
                    <div>
                        {profile?.uc_response && <div className="row  mb-2">

                            <div className="col-10 col-lg-10">
                                <div className="d-flex align-items-center">
                                    <b className="me-3">Universal Background:</b>
                                    <small className="me-3"><b>Applicant ID:</b> <span>{JSON.parse(profile?.uc_response)?.BackgroundCheckDirect?.ApplicantID}</span>,</small>
                                    <small className="me-3"><b>Order Number:</b> <span>{JSON.parse(profile?.uc_response)?.BackgroundCheckDirect?.OrderNumber}</span>,</small>
                                    <small className="me-3"><b>Status:</b> <span>{JSON.parse(profile?.uc_response)?.BackgroundCheckDirect?.Status}</span>,</small>
                                    <a href={JSON.parse(profile?.uc_response)?.BackgroundCheckDirect?.ReportUrl} target="_blank" className="pointer text-info link-underline-info me-3"><u>Check UBC</u></a>
                                </div>
                            </div>
                        </div>}
                        <div className="row align-items-center mb-2">
                            <div className="col-12 col-lg-1">
                                <b>Onboarding:</b>
                            </div>
                            {(!profile?.uc_response && profile?.status !== null) && <div className="col-6 col-lg-4">
                                <div className="d-flex align-items-center">
                                    <CFormCheck id="flexCheckDefault" checked={isChecked} onChange={handleCheckboxChange} style={{ transform: 'scale(1.5)' }} label="Background Check Request?" />
                                    {profile?.status > 0 && <CButton className="ms-3" onClick={() => {
                                        setLoading(true)
                                        AdminStore.ubCheckRequest({ "staff_id": profile.staff_id }, navigationCallBackUBC)
                                    }} disabled={!isChecked} size="sm">Submit</CButton>}
                                </div>
                            </div>}
                            <div className="col-6 col-lg-10">
                                {(profile?.status === 0 && !profile?.staff_profile?.bluesky_staff_id) && (
                                    <CButton
                                        onClick={() => onRejected(profile)}
                                        size="sm"
                                        className="ms-1"
                                        style={{ right: 0 }}
                                        variant="outline"
                                        color="danger"
                                    >
                                        Reject Onboarding Request?
                                    </CButton>
                                )}
                                <span className="text-center">
                                    {profile?.status === 1 && (
                                        <b className="text-warning">
                                            Incomplete Applicant (<i className="far fa-clock text-warning"></i>{" "}
                                            Under Review)
                                        </b>
                                    )}
                                    {profile?.status === 2 && <b className="text-danger">Rejected</b>}
                                    {profile?.status === 3 && <b className="text-success">Active</b>}
                                    {profile?.status === 4 && <b className="text-danger">Inactive</b>}
                                    {profile?.status === 5 && <b className="text-info">Applicant</b>}
                                    {profile?.status === 6 && <b className="text-success">Pre-active</b>}
                                    {profile?.status === null && (
                                        <span className="d-flex align-items-center">
                                            <b className="text-warning">Profile is incomplete</b>
                                        </span>
                                    )}
                                    {profile?.status === undefined && <span><b>No Update</b></span>}
                                    {profile?.staff_profile?.is_imported === 1 && (
                                        <span>&nbsp;<b className="text-info"> ( Imported <i className="fas fa-file-import text-info"></i> )</b></span>
                                    )}
                                </span>
                            </div>
                        </div>


                        <div className="d-flex overflow-auto">
                            {profile?.status === 0 && (
                                <div className="progresses">
                                    {!(addedCaregiver && profile?.staff_profile?.bluesky_staff_id) ? (
                                        <>
                                            <CButton
                                                onClick={() => saveCaregiver(profile)}
                                                size="sm"
                                                className="w-10"
                                                variant="outline"
                                                color="primary"
                                            >
                                                Add Caregiver
                                            </CButton>
                                            <span className="line"></span>
                                        </>
                                    )
                                        : <>
                                            <CButton size="sm" disabled className="w-10" variant='outline' color="success">Caregiver Created <i className="fa fa-check"></i></CButton>
                                            <span className="line-success"></span>
                                        </>}
                                    {(addedSpecilty === 0 && profile?.specialty_updated === 0) ? (
                                        <>
                                            <CButton
                                                disabled={addedCaregiver === null}
                                                onClick={() => saveSpecialty(profile)}
                                                size="sm"
                                                className="w-10"
                                                variant="outline"
                                                color="primary"
                                            >
                                                Add Specialities
                                            </CButton>
                                            <span className="line"></span>
                                        </>) : <>
                                        <CButton size="sm" disabled className="w-10" variant='outline' color="success">Specialty Added <i className="fa fa-check"></i></CButton>
                                        <span className="line-success"></span>
                                    </>
                                    }
                                    {(addedDevices === 0 && profile?.devices_updated === 0) ? (
                                        <>
                                            <CButton
                                                disabled={addedSpecilty === 0}
                                                onClick={() => saveDevices(profile)}
                                                size="sm"
                                                className="w-10"
                                                variant="outline"
                                                color="primary"
                                            >
                                                Add Contacts
                                            </CButton>
                                            <span className="line"></span>
                                        </>
                                    ) : <>
                                        <CButton size="sm" disabled className="w-10" variant='outline' color="success">Contacts Added <i className="fa fa-check"></i></CButton>
                                        <span className="line-success"></span>
                                    </>}
                                    {(addedDoc === 0 && profile?.docs_updated === 0) ? (
                                        <>
                                            <CButton
                                                disabled={addedDevices === 0}
                                                onClick={() => saveDoc(profile)}
                                                size="sm"
                                                className="w-10"
                                                variant="outline"
                                                color="primary"
                                            >
                                                Add Documents
                                            </CButton>
                                            <span className="line"></span>
                                        </>
                                    ) : <>
                                        <CButton size="sm" disabled className="w-10" variant='outline' color="success">Documents Added <i className="fa fa-check"></i></CButton>
                                        <span className="line-success"></span>
                                    </>}
                                    {
                                        (addedPreferredState === 0 && profile?.pref_locations_updated === 0) ? (
                                            <>
                                                <CButton
                                                    disabled={addedDoc === 0}
                                                    onClick={() => savePreferredState(profile)}
                                                    size="sm"
                                                    className="w-10"
                                                    variant="outline"
                                                    color="primary"
                                                >
                                                    Add Preferred State
                                                </CButton>
                                            </>
                                        ) : <>
                                            <CButton size="sm" disabled className="w-10" variant='outline' color="success">Preferred State Added <i className="fa fa-check"></i></CButton>
                                        </>
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                }>
                <div style={{ height: global.config.windowHeight / 2.5, overflowY: 'scroll' }}>
                    <div className="col-xl-12 col-md-12">
                        <div className="user-card-full">
                            <div className="row m-l-0 m-r-0">
                                <div className="col-sm-12">
                                    <div className="card-block">
                                        <div className="row mb-3 justify-content-between">
                                            <div className="col-md-6">
                                                <div className="d-flex flex-wrap">
                                                    <h6 className="f-w-600 me-2"> Caregiver ID: {profile?.staff_id} </h6>
                                                    <h6 className="f-w-600 ms-md-2"> Bluesky ID: {profile?.staffer_id || <span className="text-danger">&nbsp;Onboard Pending</span>} </h6>
                                                    <h6 className="f-w-600 ms-md-2"> External ID: {profile?.external_id || "Not Available"} </h6>
                                                </div>
                                            </div>
                                            <div className="col-md-6 d-flex flex-wrap justify-content-lg-end">
                                                {profile?.apply_date && <span>Apply Date : <strong className="text-success">{profile?.apply_date}</strong></span>}
                                                {profile?.hire_date && <span className="ms-md-3">Hire Date : <strong className="text-success">{profile?.hire_date}</strong></span>}
                                            </div>
                                        </div>

                                        <h6 className="m-b-20 p-b-5 b-b-default f-w-600">Basic Details</h6>
                                        <div className="row">
                                            {profile && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">First Name</p>
                                                <h6 className="text-muted f-w-400">{profile?.first_name || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {profile && profile?.middle_name && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Middle Name</p>
                                                <h6 className="text-muted f-w-400">{profile?.middle_name}</h6>
                                            </div>}
                                            {profile && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Last Name</p>
                                                <h6 className="text-muted f-w-400">{profile?.last_name || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {profile && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Birth Date</p>
                                                <h6 className="text-muted f-w-400">{profile?.birth_date ? moment(profile?.birth_date).format('MM/DD/YYYY') : <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {profile && profile?.gender && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Gender</p>
                                                <h6 className="text-muted f-w-400">{profile?.gender === 'M' ? "MALE" : "FEMALE"}</h6>
                                            </div>}
                                            {profile && profile?.region_id && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Region</p>
                                                <h6 className="text-muted f-w-400">{regions?.find(reg => reg.ID == profile?.region_id)?.Name}</h6>
                                            </div>}
                                            {profile && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Address</p>
                                                <h6 className="text-muted f-w-400">{profile?.address || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {(profile) && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Zip Code</p>
                                                <h6 className="text-muted f-w-400">{profile?.zipcode || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {(profile) && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">City</p>
                                                <h6 className="text-muted f-w-400">{profile?.city || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {(profile) && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">State</p>
                                                <h6 className="text-muted f-w-400">{profile?.state_id || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {(profile) && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">SSN</p>
                                                <h6 className="text-muted f-w-400">{profile?.ssn || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {profile && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Availability</p>
                                                <h6 className="text-muted f-w-400">{profile?.availability && JSON.parse(profile?.availability)?.map((prefer, index) => {
                                                    return prefer + (index === JSON?.parse(profile?.availability).length - 1 ? "" : ", ")
                                                }) || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {(profile && profile?.available_date) && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Available Date</p>
                                                <h6 className="text-muted f-w-400">{moment(profile?.available_date).format('MM/DD/YYYY')}</h6>
                                            </div>}
                                            {(profile && profile?.available_day) && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Available Day</p>
                                                <h6 className="text-muted f-w-400">{JSON.parse(profile?.available_day)?.map((prefer, index) => {
                                                    return prefer + (index === JSON?.parse(profile?.available_day).length - 1 ? "" : ", ")
                                                })}</h6>
                                            </div>}
                                            {profile && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Interested In</p>
                                                <h6 className="text-muted f-w-400">{profile?.interested_in && JSON.parse(profile?.interested_in)?.map((prefer, index) => {
                                                    return prefer + (index === JSON?.parse(profile?.interested_in).length - 1 ? "" : ", ")
                                                }) || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {(profile) && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Disability</p>
                                                <h6 className="text-muted f-w-400">{(profile?.disability === true ? "Yes" : "No") || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {(profile && profile?.info_sources) ? <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Info Sources</p>
                                                <h6 className="text-muted f-w-400">{infoSource?.find(info => info.SystemValueID === profile?.info_sources)?.SystemValue}</h6>
                                            </div> : null}
                                            {(profile) && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Preferred State's</p>
                                                <h6 className="text-muted f-w-400">{profile?.preferred_state && JSON.parse(profile?.preferred_state)?.map((prefer, index) => {
                                                    const stateName = states?.find(stat => stat?.abbreviation === prefer)?.name || '-';
                                                    return stateName + (index === JSON?.parse(profile?.preferred_state).length - 1 ? "" : ", ")
                                                }) || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {(profile) && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Preferred Cities</p>
                                                <h6 className="text-muted f-w-400">{profile?.preferred_cities && JSON.parse(profile?.preferred_cities)?.map((prefer, index) => {
                                                    const stateName = prefer?.label || <span className="text-danger">Need to be updated</span>;
                                                    return stateName + (index === JSON.parse(profile?.preferred_cities)?.length - 1 ? "" : ", ")
                                                }) || <span className="text-danger">Need to be updated</span>}</h6>
                                            </div>}
                                            {(profile) && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Work Area Distance</p>
                                                <h6 className="text-muted f-w-400">{profile?.work_distance + " miles" || "-"}</h6>
                                            </div>}
                                            {(profile) && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Contact Devices</p>
                                                <h6 className="text-muted f-w-400">{profile?.contact_devices && JSON.parse(profile?.contact_devices)?.map((cont, ind) => {
                                                    return <span key={ind + "contact"}>{(devices?.find(device => device.SystemValueID == cont?.type)?.SystemValue) + " - " + (profile?.country_id != 0 ? profile?.country_id : "") + " " + cont.number}< br /></span>
                                                }) || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {(profile && profile?.last_updated_by) && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Last Updated By</p>
                                                <h6 className="text-muted f-w-400">{profile?.last_updated_by?.first_name} {profile?.last_updated_by?.last_name}</h6>
                                            </div>}
                                            {(profile && profile?.last_updated_by) && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Last Update</p>
                                                <h6 className="text-muted f-w-400">{moment(profile?.updated_at).fromNow()}</h6>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="card-block">
                                        <h6 className="m-b-20 p-b-5 b-b-default f-w-600">Professional/Documents Details</h6>
                                        <div className="row">
                                            {(profile && profile?.degree_specialities) && <div className="col-sm-6">
                                                <p className="m-b-10 f-w-600">Profession Specialities</p>
                                                <h6 className="text-muted f-w-400">{
                                                    profile?.degree_specialities && JSON.parse(profile?.degree_specialities)?.map((prof, index) => {
                                                        return <div key={index + "list"}> {prof?.profession_name} {prof?.specialties_name ? "➤" : ""}  {prof?.specialties_name}{(prof?.exp && prof?.exp != 0) ? <> | <i className="fas fa-briefcase"></i> {prof?.exp} {prof?.exp == 1 ? "year" : "year(s)"}</> : ""}< br /></div>;
                                                    }) || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {profile && <div className="col-sm-4">
                                                <p className="m-b-10 f-w-600">Documents</p>
                                                <h6 className="text-muted f-w-400">{
                                                    (profile?.staff_profile && profile?.documents?.length !== 0) && profile?.documents?.map((doc, index) => {
                                                        return <a href={BASE_API_URL_FILE + doc?.document_path} target="_blank" key={index + "doc"}> {requiredDoc.find(docum => docum.ID === doc.document_id)?.Name}  <i className="fa fa-eye text-primary" aria-hidden="true"></i>< br /></a>;
                                                    }) || <span className="text-warning">Pending</span>}</h6>
                                            </div>}
                                            {(profile && profile?.remark) && <div className="col-sm-3">
                                                <p className="m-b-10 f-w-600">Rejection Reason</p>
                                                <h6 className="text-danger f-w-400">{profile?.remark}</h6>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BottomSheet >
            <CModal className="custom-modal-size" backdrop="static" visible={isOpenDistanceView} onClose={() => onDismissDistanceView()} >
                <CModalHeader >
                    <CModalTitle>{"Update Work Area For " + staffData?.first_name + " " + staffData?.last_name}</CModalTitle>
                </CModalHeader>
                <Formik
                    initialValues={{
                        staff_id: staffData?.staff_id,
                        miles: staffData?.work_distance || 40,
                        cities: staffData?.preferred_cities ? JSON.parse(staffData?.preferred_cities) : [],
                        states: staffData?.preferred_state ? JSON.parse(staffData?.preferred_state) : [],
                    }}
                    onSubmit={values => updateWorkPreference(values)}
                    enableReinitialize
                    validationSchema={Yup.object({
                        cities: Yup.array().min(1, 'Must have at least 1 city').required('Preferred city required').nullable(),
                        states: Yup.array().min(1, 'Must have at least 1 state').required('Preferred state required').nullable(),
                    })}
                    validateOnChange={false}
                >
                    {
                        ({ handleChange, handleBlur, handleSubmit, setFieldValue, resetForm, values, errors, touched
                        }) =>
                        (
                            <form onSubmit={handleSubmit}>
                                <div className="row g-2 modal-body p-4">
                                    <div className="row g-2">
                                        <div className="col-sm-12 mb-3">
                                            <Select
                                                closeMenuOnSelect={false}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.abbreviation}
                                                isMulti
                                                hideSelectedOptions={false}
                                                options={states}
                                                styles={colourStylesTitle}
                                                placeholder=""
                                                isRequired={true}
                                                value={states.filter(({ abbreviation }) => values?.states?.includes(abbreviation)) || ''}
                                                label="Add Preferred States"
                                                onChange={(event) => {
                                                    setFieldValue("states", event?.map(({ abbreviation }) => abbreviation));
                                                    setFieldValue("cities", values?.cities?.filter(ct => event?.map(({ abbreviation }) => abbreviation)?.find(st => st === ct.state_id)))
                                                }}
                                                components={{ Control, MultiValue, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                            />
                                            {errors.states ? (
                                                <div className="error-message">{errors.states}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-12 mb-3">
                                            <Select
                                                closeMenuOnSelect={false}
                                                getOptionLabel={(option) => `${option.label}, ${option.state_id}`}
                                                getOptionValue={(option) => option.label}
                                                isMulti
                                                hideSelectedOptions={false}
                                                options={values?.states?.length > 0 ? cities.filter(city => values?.states?.find(x => x === city.state_id)) : []}
                                                styles={colourStylesTitle}
                                                placeholder=""
                                                isRequired={true}
                                                value={values.cities}
                                                label="Add Preferred Cities"
                                                onChange={(event) => { setFieldValue("cities", event); }}
                                                components={{ Control, MultiValue, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                            />
                                            {errors.cities ? (
                                                <div className="error-message">{errors.cities}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-12 mb-3">
                                            <Select
                                                onChange={(event) => { setFieldValue("miles", event.value) }}
                                                placeholder=""
                                                label="Select Work Area"
                                                isRequired={true}
                                                value={global.config.radiusMiles?.filter(({ value }) => values?.miles === value) || ''}
                                                styles={colourStylesTitle}
                                                isSearchable={true}
                                                options={global.config.radiusMiles}
                                                components={{ Control }} />
                                        </div>

                                        <div className="d-flex justify-content-center pt-1">
                                            <button className="btn btn-primary w-auto pe-5 ps-5 p-2" type="submit">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                </Formik>
            </CModal>
            {/* EDIT JOB PROFILE */}
            < CModal className="custom-modal-size" backdrop="static" visible={openJobProfile} onClose={() => onDismissJobProfile()} >
                <CModalHeader >
                    <CModalTitle>{"Update Details For " + staffData?.first_name + " " + staffData?.last_name}</CModalTitle>
                </CModalHeader>
                <Formik
                    initialValues={{
                        'profile_id': staffData?.id,
                        'external_id': staffData?.external_id,
                        'staffer_id': staffData?.staffer_id,
                        'region_id': staffData?.region_id,
                        'work_distance': staffData?.work_distance || 40,
                        'degree_specialities': staffData?.degree_specialities && JSON.parse(staffData?.degree_specialities),
                        'preferred_state': staffData?.preferred_state && JSON.parse(staffData?.preferred_state),
                        'preferred_cities': staffData?.preferred_cities && JSON.parse(staffData?.preferred_cities),
                    }}
                    onSubmit={values => updateJobProfileSchema(values)}
                    enableReinitialize
                    validationSchema={UpdateJobProfileSchema}
                    validateOnChange={false}
                >
                    {
                        ({ handleChange, handleBlur, handleSubmit, setFieldValue, resetForm, values, errors, touched
                        }) =>
                        (
                            <form onSubmit={handleSubmit}>
                                <div className="row g-2 modal-body p-4">
                                    <div className="row g-2">
                                        <div className="col-sm-6 mb-3">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="text"
                                                name="external_id"
                                                label={"External Id"}
                                                value={values.external_id}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                                disabled
                                            />
                                            <div>
                                                <CButton size="sm" onClick={() => {
                                                    setFieldValue("external_id", (staffData?.staff_profile?.is_imported == 1 ? "C" + staffData?.staffer_id : "N" + staffData?.id))
                                                }} color="link" shape="rounded-0">Generate an External ID <i className="fas fa-redo-alt"></i></CButton>
                                            </div>
                                            {errors.external_id ? (
                                                <div className="error-message">{errors.external_id}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 mb-3">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label"}
                                                type="text"
                                                name="staffer_id"
                                                label={"Bluesky Id"}
                                                value={values.staffer_id}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                disabled={values.staffer_id}
                                            />
                                            {errors.staffer_id ? (
                                                <div className="error-message">{errors.staffer_id}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 mb-3">
                                            <SelectField
                                                name="region_id"
                                                label={"Add Your Region"}
                                                value={values.region_id}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                data={regions}
                                                optionLabel={"Name"}
                                                optionValue={"ID"}
                                                required={true}
                                                disabled={values.staffer_id}
                                            />
                                            {errors.region_id ? (
                                                <div className="error-message">{errors.region_id}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 mb-3">
                                            <Select
                                                onChange={(event) => { setFieldValue("work_distance", event.value) }}
                                                placeholder=""
                                                label="Select Work Area"
                                                isRequired={true}
                                                value={global.config.radiusMiles?.filter(({ value }) => values?.work_distance === value) || ''}
                                                styles={colourStylesState}
                                                isSearchable={true}
                                                options={global.config.radiusMiles}
                                                components={{ Control }} />
                                        </div>
                                        <FieldArray
                                            name="degree_specialities"
                                            render={({ handleRemove, push }) => (
                                                <>
                                                    {
                                                        values.degree_specialities && values.degree_specialities.length > 0 ?
                                                            values.degree_specialities.map((deg, index) => (
                                                                <div key={index + 'list'} className="card shadow-sm p-3">
                                                                    <div className="row p-lg-4">
                                                                        {!values.staffer_id && <div className="col-sm-12 d-flex justify-content-end pb-0 ms-3" style={{ marginTop: -10 }}>
                                                                            {values?.degree_specialities?.length !== 1 && <button
                                                                                type="button"
                                                                                className="btn btn-sm text-danger pointer w-10 me-1 mt-lg-0 mt-3"
                                                                                onClick={() => {
                                                                                    values.degree_specialities.splice(index, 1)
                                                                                    specialties.splice(index, 1)
                                                                                    setFieldValue('degree_specialities', values.degree_specialities)
                                                                                }}
                                                                            >
                                                                                <i className="fas fa-times"></i>
                                                                            </button>}
                                                                        </div>}
                                                                        <div className="col-sm-6 pb-lg-0 pb-md-0 pb-3">
                                                                            <div className="did-floating-label-content">
                                                                                <select
                                                                                    className="did-floating-dropdown"
                                                                                    value={deg?.profession}
                                                                                    required={true}
                                                                                    name={`degree_specialities[${index}].profession`}
                                                                                    disabled={values.staffer_id}
                                                                                    onBlur={handleBlur}
                                                                                    onChange={(e) => {
                                                                                        handleChange(e);
                                                                                        const selectedIndex = e.target.selectedIndex;
                                                                                        const selectedOptionLabel = e.target.options[selectedIndex].label;
                                                                                        setFieldValue(`degree_specialities[${index}].profession`, e.target.value)
                                                                                        setFieldValue(`degree_specialities[${index}].profession_name`, selectedOptionLabel)
                                                                                        setFieldValue(`degree_specialities[${index}].specialties_name`, null);
                                                                                        setFieldValue(`degree_specialities[${index}].specialties`, null);
                                                                                        if (selectedIndex !== 0) {
                                                                                            onGetSpecialties(e.target.value, index)
                                                                                        } else {
                                                                                            specialties[index] = []
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <option
                                                                                        value=""
                                                                                    >
                                                                                    </option>
                                                                                    {degree && degree?.map((option, index) => (
                                                                                        <option
                                                                                            key={index}
                                                                                            value={option.Id}
                                                                                        >
                                                                                            {option.Name}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                                <label className={deg?.profession == "" ? "did-floating-label did-floating-label-down did-floating-labe-astrisk" : "did-floating-label did-floating-label-top did-floating-labe-astrisk"}>Profession</label>
                                                                            </div>
                                                                            {errors?.degree_specialities && errors?.degree_specialities?.length != 0 && errors?.degree_specialities[index] ? (
                                                                                <div className="error-message">{errors?.degree_specialities[index]?.profession}</div>
                                                                            ) : null}
                                                                        </div>
                                                                        {<div className="col-sm-6 pb-lg-0 pb-md-0 pb-3">
                                                                            <SelectField
                                                                                label={loading ? "Loading..." : "Speciality"}
                                                                                disabled={!(specialties[index] && specialties[index]?.length > 0) || values.staffer_id}
                                                                                required={(specialties[index] && specialties[index]?.length > 0)}
                                                                                name={`degree_specialities[${index}].specialties`}

                                                                                value={deg.specialties}
                                                                                onChange={(event) => {
                                                                                    handleChange(event);
                                                                                    const selectedIndex = event.target.selectedIndex;
                                                                                    const selectedOptionLabel = event.target.options[selectedIndex].label;
                                                                                    setFieldValue(`degree_specialities[${index}].specialties_name`, selectedOptionLabel);
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                                data={(deg.specialties ? specialties[index]?.filter(x => x?.Id == deg?.specialties) :
                                                                                    specialties[index]?.filter(x => {
                                                                                        if (values?.degree_specialities?.find(rm => rm?.specialties == x?.Id)) {
                                                                                            return
                                                                                        } else {
                                                                                            return x;
                                                                                        }
                                                                                    }
                                                                                    )) || []}
                                                                                optionLabel={"Name"}
                                                                                optionValue={"Id"}
                                                                            />
                                                                        </div>}
                                                                        {<div className="col-sm-6 pb-lg-0 pb-md-0 pb-3 mt-3">
                                                                            <InputField
                                                                                className={"did-floating-input"}
                                                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                                                type="number"
                                                                                min="0"
                                                                                name={`degree_specialities[${index}].exp`}
                                                                                label={"Years of Experience"}
                                                                                value={deg.exp}
                                                                                disabled={values.staffer_id}
                                                                                onChange={(event) => { handleChange(event); }}
                                                                                onBlur={handleBlur}
                                                                                required={true}
                                                                            />
                                                                            {errors?.degree_specialities && errors?.degree_specialities?.length != 0 && errors?.degree_specialities[index] ? (
                                                                                <div className="error-message">{errors?.degree_specialities[index]?.exp}</div>
                                                                            ) : null}
                                                                        </div>}
                                                                        {<div className="col-sm-6 pb-lg-0 pb-md-0 pb-3 mt-3">
                                                                            <SelectField
                                                                                label={"Currently working in this role?"}
                                                                                name={`degree_specialities[${index}].working`}
                                                                                value={deg.working}
                                                                                onChange={(event) => {
                                                                                    handleChange(event);
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                                disabled={values.staffer_id}
                                                                                data={[{ "label": "Yes", "value": "Yes" }, { "label": "No", "value": "No" }]}
                                                                                optionLabel={"label"}
                                                                                optionValue={"value"}
                                                                            />
                                                                        </div>}
                                                                    </div>
                                                                </div>
                                                            )) : null
                                                    }
                                                    {
                                                        !values.staffer_id && <div className="col-sm-12 d-flex justify-content-sm-end">
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-light w-10"
                                                                onClick={() => { push({ profession: '', specialties: '', exp: '', working: 'No' }); }} // insert an empty string at a position
                                                            >
                                                                Add Profession
                                                            </button>
                                                        </div>}
                                                </>)}
                                        />
                                        <div className="col-sm-6">
                                            <Select
                                                closeMenuOnSelect={false}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.abbreviation}
                                                isMulti
                                                hideSelectedOptions={false}
                                                options={states}
                                                styles={colourStyles}
                                                placeholder=""
                                                isRequired={true}
                                                value={states.filter(({ abbreviation }) => values?.preferred_state?.includes(abbreviation)) || ''}
                                                label="Add Preferred States"
                                                onChange={(event) => {
                                                    setFieldValue("preferred_state", event?.map(({ abbreviation }) => abbreviation));
                                                    setFieldValue("preferred_cities", values?.preferred_cities?.filter(ct => event?.map(({ abbreviation }) => abbreviation)?.find(st => st === ct.state_id)))
                                                }}
                                                components={{ Control, MultiValue, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                            />
                                            {errors.preferred_state ? (
                                                <div className="error-message">{errors.preferred_state}</div>
                                            ) : null}
                                        </div>

                                        <div className="col-sm-6">
                                            <Select
                                                closeMenuOnSelect={false}
                                                getOptionLabel={(option) => `${option.label}, ${option.state_id}`}
                                                getOptionValue={(option) => option.label}
                                                isMulti
                                                hideSelectedOptions={false}
                                                options={values?.preferred_state?.length > 0 ? cities.filter(city => values?.preferred_state?.find(x => x === city.state_id)) : []}
                                                styles={colourStyles}
                                                placeholder=""
                                                isRequired={true}
                                                value={values.preferred_cities}
                                                label="Add Preferred Cities"
                                                onChange={(event) => { setFieldValue("preferred_cities", event); }} components={{ Control, MultiValue, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                            />
                                            {errors.preferred_cities ? (
                                                <div className="error-message">{errors.preferred_cities}</div>
                                            ) : null}
                                        </div>
                                        <div className="d-flex justify-content-center pt-1">
                                            <button className="btn btn-primary w-auto pe-5 ps-5 p-2" type="submit">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                </Formik>
            </CModal >
            <Spinner isLoading={loading || JobProfileStore.loading} />
        </React.Fragment >
    );
})


export default JobProfileRequestList;
export const Control = (props) => {
    return (
        <>
            <label className={props.hasValue || props.isFocused ? "did-floating-label did-floating-label-top" : "did-floating-label did-floating-label"} style={{ zIndex: 999 }}>{props?.selectProps?.label}</label>
            <components.Control {...props} />
        </>
    );
};
const MultiValue = ({ index, getValue, ...props }) => {
    const maxToShow = 3;
    const overflow = getValue()
        .slice(maxToShow)
        .map((x) => x.label);

    return index < maxToShow ? (
        <components.MultiValue {...props} />
    ) : index === maxToShow ? (
        <MoreSelectedBadge items={overflow} />
    ) : null;
};
const MoreSelectedBadge = ({ items }) => {
    const style = {
        marginLeft: "auto",
        background: "#d4eefa",
        borderRadius: "4px",
        fontFamily: "Open Sans",
        fontSize: "11px",
        padding: "3px",
        order: 99
    };

    const title = items.join(", ");
    const length = items.length;
    const label = `+ ${length} item${length !== 1 ? "s" : ""}`;

    return (
        <div style={style} title={title}>
            {label}
        </div>
    );
};