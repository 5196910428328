/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Header from '@components/Header';
import { Helmet } from 'react-helmet';
import { CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react-pro'
import 'react-spring-bottom-sheet/dist/style.css';
import StaffsStore from '@store/StaffsStore';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { toJS } from 'mobx';
import Spinner from "@components/Spinner";
import DatePicker from "react-multi-date-picker"
import Select, { components } from 'react-select';
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
import secureLocalStorage from 'react-secure-storage';
import { Formik } from "formik";
import * as Yup from 'yup';
const { parse, isBefore, isAfter, addDays, isSameDay, isSameHour, isSameMinute } = require('date-fns');
import { colourStylesTitle, colourStyles } from '@utils/customStyle/style';
import Footer from '@components/Footer';
import { useLocation } from 'react-router-dom';
import ResponsiveTable from '../../elements/table/ResponsiveTable';
var _ = require('lodash');
var cities = require('@assets/city.json');
var states = require('@assets/states.json');
var columns = ["Select", "Date", "Time", "Facility", "Type", "Category", "Address"]
const AvailableShiftsPage = observer(() => {
    const [pageLoader, setPageLoader] = useState(false);
    const params = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [errorOne, setErrorOne] = useState("");
    const [errorTwo, setErrorTwo] = useState({ message: "", id: "" });
    const [isOpenDistanceView, setIsOpenDistanceView] = useState(false);
    const [profileData, setJobProfile] = useState();
    const [eligibleFacility, setEligibleFacility] = useState([]);
    const [eligibleFacilityType, setEligibleFacilityType] = useState(null);
    const [selectedFacilities, setSelectedFacilities] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const pageLoading = (load) => {
        setPageLoader(load);
    }

    useEffect(() => {
        pageLoading(true);
        if (params?.state?.range) {
            if (params?.state?.range?.length === 2) {
                StaffsStore.data.filter_start_date = params?.state?.range[0]
                StaffsStore.data.filter_end_date = params?.state?.range[1]
            } else if (params?.state?.range?.length === 1) {
                StaffsStore.data.filter_start_date = params?.state?.range[0]
            }
        }
        let selectedFacility = secureLocalStorage.getItem("selectedFacilites");
        if (selectedFacility) {
            let facilitiesIds = JSON.parse(selectedFacility);
            setSelectedFacilities(facilitiesIds)
        }
        StaffsStore.getJobProfile(1).then((data) => {
            setJobProfile(data?.profile)
            if (data?.profile?.status !== 3) {
                StaffsStore.data.availableShifts = [];
                pageLoading(false);
            }
        });
    }, []);

    useEffect(() => {
        if (profileData?.status === 3) {
            StaffsStore.getEligibleFacility();
            StaffsStore.getAppliedShiftData();
        }
    }, [profileData]);


    useEffect(() => {
        setEligibleFacility(toJS(StaffsStore?.data?.eligibleFacility))
        setEligibleFacilityType(StaffsStore?.data?.eligibleFacilityType)
    }, [StaffsStore?.data?.eligibleFacility]);

    useEffect(() => {
        if ((eligibleFacility?.length > 0) && profileData) {
            getShift()
        }
    }, [eligibleFacility]);

    const resetSearch = () => {
        StaffsStore.data.filter_start_date = '';
        StaffsStore.data.filter_end_date = '';
        StaffsStore.data.filter_time_start = '';
        StaffsStore.data.filter_time_end = '';
        StaffsStore.data.filter_shift_type = '';
        // Replace the current history state with the new URL
        setSelectedFacilities([]);
        secureLocalStorage.removeItem("selectedFacilites");
        const formData = new FormData();
        eligibleFacility?.forEach(({ id }) => formData.append("facility_ids[]", id));
        if (profileData?.status == 3) {
            StaffsStore.getAvailableShifts(formData, pageLoading);
        } else {
            pageLoading(false)
            swal("Account Inactive", "We regret to inform you that your account is currently inactive. Our system indicates that your account has been temporarily disabled for unknown reasons. To resolve this issue and reactivate your account, we kindly request you to contact the administrator or support team.", "warning");
        }
    }

    const getShift = () => {
        if (profileData?.status == 3) {
            setSelectedRows([])
            const formData = new FormData()
            if (selectedFacilities?.length > 0) {
                selectedFacilities?.forEach(facility => formData.append("facility_ids[]", facility?.id));
                StaffsStore.getAvailableShifts(formData, pageLoading)
            } else {
                eligibleFacility?.forEach(({ id }) => formData.append("facility_ids[]", id));
                StaffsStore.getAvailableShifts(formData, pageLoading)
            }
        } else {
            pageLoading(false)
            swal("Account Inactive", "We regret to inform you that your account is currently inactive. Our system indicates that your account has been temporarily disabled for unknown reasons. To resolve this issue and reactivate your account, we kindly request you to contact the administrator or support team.", "warning");
        }
    }

    const pushSlot = (event, slot) => {
        if (event.target.checked) {
            setSelectedRows([...selectedRows, slot]);
        } else {
            const removeValue = selectedRows?.filter(item => item.id !== slot?.id);
            setSelectedRows(removeValue);
        }

    }
    const removeSlot = (slot) => {
        swal({
            title: "Are you sure to remove this shift?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const removeValue = selectedRows?.filter(item => item.id !== slot?.id);
                    setSelectedRows(removeValue);
                    setErrorOne("")
                    setErrorTwo("")
                }
            });
    }
    const areShiftsOverlapping = (slot1, slot2) => {
        let start1 = parse(`${slot1.slot_date} ${slot1.slot_start_time}`, 'MM/dd/yyyy HH:mm', new Date());
        let end1 = parse(`${slot1.slot_date} ${slot1.slot_end_time}`, 'MM/dd/yyyy HH:mm', new Date());//break
        let start2 = parse(`${slot2.slot_date} ${slot2.slot_start_time}`, 'MM/dd/yyyy HH:mm', new Date());
        let end2 = parse(`${slot2.slot_date} ${slot2.slot_end_time}`, 'MM/dd/yyyy HH:mm', new Date());//break
        if (isBefore(end1, start1) || (isSameDay(end1, start1) && isSameHour(end1, start1) && isSameMinute(end1, start1))) {
            // Add one day to the end1 time
            end1 = addDays(end1, 1);
        }
        return ((isBefore(start1, end2) && isAfter(end1, start2)) || ((isSameDay(end1, start1) && isSameHour(end1, start1) && isSameMinute(end1, start1))));
    }

    //END VALIDATION

    const addMyShift = () => {
        const overlappingPairsData = [];
        selectedRows?.forEach((obj, index) => {
            obj.ind = index + 1;
        });
        // var filterTC = selectedRows?.filter(x => x.category === 'TC')
        // var filterNoTC = selectedRows?.filter(x => x.category !== 'TC')
        // if (filterTC.length > 0) {
        //     filterTC?.forEach((obj) => {
        //         filterNoTC.forEach((obj2) => {
        //             const overlap = hasConflictRange(obj, obj2);
        //             if (overlap) {
        //                 overlappingPairsData.push({
        //                     message: `Slot #${obj.ind} overlaps with Slot #${obj2.ind}`,
        //                     id: [obj.id, obj2.id]
        //                 });
        //                 setErrorOne("Overlapping slot found:")
        //                 setErrorTwo(overlappingPairsData);
        //             }
        //         })
        //     })
        //     for (let i = 0; i < filterTC.length; i++) {
        //         for (let j = i + 1; j < filterTC.length; j++) {
        //             let check = checkForDateAndTimeConflicts(filterTC[i], filterTC[j]);
        //             if (check) {
        //                 overlappingPairsData.push({
        //                     message: `Slot #${check.obj1.ind} overlaps with Slot #${check.obj2.ind}`,
        //                     id: [check.obj1.id, check.obj2.id]
        //                 });
        //                 setErrorOne("Overlapping slot found:")
        //                 setErrorTwo(overlappingPairsData);
        //             }
        //         }
        //     }
        // }
        for (let i = 0; i < selectedRows?.length - 1; i++) {
            for (let j = i + 1; j < selectedRows.length; j++) {
                if (areShiftsOverlapping(selectedRows[i], selectedRows[j])) {
                    console.log("overlap found...");
                    overlappingPairsData.push({
                        message: `Shift #${selectedRows[i]?.ind} overlaps with Shift #${selectedRows[j]?.ind}`,
                        id: [selectedRows[i]?.id, selectedRows[j]?.id]
                    });
                    setErrorOne("Overlapping shift found:")
                    setErrorTwo(overlappingPairsData);
                }
            }
        }
        if (overlappingPairsData.length > 0) {
            return
        } else {
            if (selectedRows?.length === 0) {
                onDismiss()
                return
            }
            let param = {
                slot_ids: selectedRows?.map(element => { if (element.category !== 'TC') { return element.id } else return })?.filter(item => item !== null && item !== undefined),
                shift_ids: selectedRows?.map(element => { if (element.category === 'TC') { return element.id } else return })?.filter(item => item !== null && item !== undefined),
            }
            setErrorOne("")
            setErrorTwo("")
            StaffsStore.applyShifts(param, navigationCallBack, navigationCallBackUnallocated,pageLoading)
        }
    }
    const navigationCallBack = () => {
        StaffsStore.getAppliedShiftData();
        searchShift();
        onDismiss();
        setErrorOne("")
        setErrorTwo("")
        swal("Success", "The shift was successfully assigned to you.", "success");
    }
    const navigationCallBackUnallocated = () => {
        StaffsStore.getAppliedShiftData();
        searchShift();
        onDismiss();
        setErrorOne("")
        setErrorTwo("")
    }
    const openModal = () => {
        setIsOpen(true)
        setErrorOne("")
        setErrorTwo("")
    }

    const onDismiss = () => {
        setIsOpen(false)
        setErrorOne("")
        setErrorTwo("")
    }


    //Distance Modal
    const onSetMaxDist = () => {
        setIsOpenDistanceView(true)
    }
    const onDismissDistanceView = () => {
        setIsOpenDistanceView(false)
    }
    const updateWorkPreference = (values) => {
        const param = {
            "staff_id": values?.staff_id,
            "miles": values.miles,
            "cities": JSON.stringify(values?.cities),
            "states": JSON.stringify(values?.states),
        }
        StaffsStore.updateWorkPreference(param, navigationCallBackWordUpdate)
    }
    const navigationCallBackWordUpdate = () => {
        onDismissDistanceView();
        StaffsStore.getJobProfile(1).then((data) => {
            setJobProfile(data?.profile)
            if (data?.profile?.status !== 3) {
                StaffsStore.data.availableShifts = [];
                // setLoading(false)
            }
        });
        StaffsStore.getEligibleFacility();
        StaffsStore.getAppliedShiftData();
    }

    const onPageSizeChange = (e) => {
        StaffsStore.data.pageSize = e.target.value;
        searchShift();
    }
    const onChangeSort = (e) => {
        StaffsStore.data.sort_by = e.target.value;
        searchShift();
    }

    const searchShift = () => {
        const formData = new FormData();
        if (selectedFacilities?.length > 0) {
            selectedFacilities?.forEach(({ id }) => formData.append("facility_ids[]", id));
        } else {
            eligibleFacility?.forEach(({ id }) => formData.append("facility_ids[]", id));
        }
        formData.append("page", StaffsStore.data.page);
        formData.append("per_page", StaffsStore.data.pageSize)
        StaffsStore.data.sort_by && formData.append("sort_by", StaffsStore.data.sort_by)
        StaffsStore.data.filter_start_date && formData.append("filter_start_date", StaffsStore.data.filter_start_date)
        StaffsStore.data.filter_end_date && formData.append("filter_end_date", StaffsStore.data.filter_end_date)
        StaffsStore.data.filter_time_start && formData.append("filter_time_start", StaffsStore.data.filter_time_start)
        StaffsStore.data.filter_time_end && formData.append("filter_time_end", StaffsStore.data.filter_time_end)
        StaffsStore.data.filter_shift_type && formData.append("filter_shift_type", StaffsStore.data.filter_shift_type)
        if (profileData?.status == 3) {
            StaffsStore.getAvailableShifts(formData, pageLoading);
        } else {
            pageLoading(false);
            swal("Account Inactive", "We regret to inform you that your account is currently inactive. Our system indicates that your account has been temporarily disabled for unknown reasons. To resolve this issue and reactivate your account, we kindly request you to contact the administrator or support team.", "warning");
        }
    }

    const onPageChange = (page) => {
        StaffsStore.data.page = page;
        searchShift()
    }

    return (
        <React.Fragment>
            <Header />
            <Helmet>
                <title>Staffers LLC | Available Shifts</title>
            </Helmet>
            <>
                <div className="container-fluid callback-small  mb-3">
                    <div className="container-fluid mt-5  pt-lg-5">
                        <div className="container mt-5 pt-5 pt-lg-0 mb-3 mt-lg-5">
                            <h5 className="display-5 animated slideInDown login-head mb-2 mb-lg-5">Pickup Shifts</h5>
                            <div className="row justify-content-center">
                                <div className="container float-search float-search-shift  d-flex justify-content-center top-padding">
                                    <div className="shadow border rounded wow fadeInUp w-100" style={{ backgroundColor: "#f6e8ff" }}>
                                        <div className="col-lg-12 col-12 pt-2 ps-2">
                                            {/* <b className="">Pickup Shifts</b> */}
                                            {(profileData && profileData?.status !== 3) ? <center> <strong className='text-danger text-center'>Account is inactive. Please contact the administrator.</strong>
                                            </center> : ""} </div>

                                        <div className='row align-items-center p-2'>
                                            <div className='col-12 col-lg-10 '>
                                                <div className='row '>
                                                    <div className="col-lg-4 col-12 mb-3 mb-lg-0">
                                                        <Select
                                                            getOptionLabel={(option) => `${option.organization_name}, ${option.city}, ${option.state}`}
                                                            getOptionValue={(option) => option.id}
                                                            hideSelectedOptions={false}
                                                            isMulti
                                                            closeMenuOnSelect={false}
                                                            options={eligibleFacility}
                                                            styles={colourStyles}
                                                            placeholder=""
                                                            value={selectedFacilities}
                                                            label="Select Facilities In Your Area"
                                                            onChange={(e) => {
                                                                setSelectedFacilities(e)
                                                                secureLocalStorage.setItem("selectedFacilites", JSON.stringify(e));
                                                            }}
                                                            components={{ Control, MultiValue, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                        />
                                                    </div>
                                                    <div className="col-lg-3 col-12 mb-3 mb-lg-0">
                                                        <DatePicker
                                                            value={[StaffsStore.data.filter_start_date, StaffsStore.data.filter_end_date]}
                                                            minDate={new Date()}
                                                            onChange={dateObjects => {
                                                                if (dateObjects && dateObjects.length === 2) {
                                                                    const startDate = dateObjects[0]?.format("MM/DD/YYYY");
                                                                    const endDate = dateObjects[1]?.format("MM/DD/YYYY");
                                                                    StaffsStore.data.filter_start_date = startDate
                                                                    StaffsStore.data.filter_end_date = endDate
                                                                    searchShift()
                                                                }
                                                            }}
                                                            placeholder="Select Start End Date"
                                                            format="MM/DD/YYYY"
                                                            inputClass="did-floating-dropdown"
                                                            range
                                                            rangeHover={true}
                                                        />
                                                    </div>
                                                    <div className="col-lg-3 col-12 mb-3 mb-lg-0">
                                                        <div className="did-floating-label-content">
                                                            <div className="did-floating-dropdown d-flex justify-content-center align-items-center">
                                                                <input
                                                                    type="time"
                                                                    id="start"
                                                                    className="border-0"
                                                                    name="start"
                                                                    value={StaffsStore.data.filter_time_start}
                                                                    onChange={(e) =>
                                                                        StaffsStore.data.filter_time_start = e.target.value
                                                                    }
                                                                />
                                                                <span className="slot-time-to">To</span>
                                                                <input
                                                                    type="time"
                                                                    id="end"
                                                                    className="border-0"
                                                                    name="end"
                                                                    value={StaffsStore.data.filter_time_end}
                                                                    onChange={(e) => {
                                                                        StaffsStore.data.filter_time_end = e.target.value
                                                                        if (StaffsStore.data.filter_time_end && StaffsStore.data.filter_time_start) {
                                                                            searchShift()
                                                                        }
                                                                    }
                                                                    }
                                                                />
                                                            </div>
                                                            <label className={"did-floating-label did-floating-label-top"}>Select Time</label>

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-12 mb-3 mb-lg-0">
                                                        <Select
                                                            getOptionLabel={(option) => option.name}
                                                            getOptionValue={(option) => option.value}
                                                            hideSelectedOptions={false}
                                                            isMulti={false}
                                                            closeMenuOnSelect={true}
                                                            options={global.config.shiftType}
                                                            styles={colourStyles}
                                                            placeholder=""
                                                            value={global.config.shiftType?.find(x => x.value == StaffsStore?.data?.filter_shift_type) || null}
                                                            label="Shift Type"
                                                            onChange={(e) => {
                                                                StaffsStore.data.filter_shift_type = e.value
                                                                searchShift()
                                                            }}
                                                            components={{ Control, MultiValue, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12 col-lg-2'>
                                                <div className='row '>
                                                    <div className='col-12'>
                                                        <button className="btn btn-primary w-100 py-2" disabled={eligibleFacility?.length === 0} onClick={searchShift}>Search</button>
                                                    </div>
                                                    <div className='col-12 mt-2 mb-2'>
                                                        <button className="btn btn-primary w-100 py-2" onClick={resetSearch}>Reset</button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row bg-white" style={{ paddingTop: "9%", }}>
                        <p><strong>Didn't find shifts in your location? Update preferred cities here.<span className='text-decoration-underline text-primary pointer' onClick={onSetMaxDist}> Change</span></strong>  {!profileData?.preferred_cities && <sup className="blink text-danger font-italic font-weight-bold ">Update </sup>}</p>
                        <div className='d-flex justify-content-between'>
                            <p><strong>Please Note: </strong><span className='text-danger'><i>Kindly click the appropriate checkbox to indicate the shifts you want to apply for, then click 'Apply Selected Shift'.</i></span></p>
                            {eligibleFacilityType === 'Yes' && <strong> <span className='text-info'>Showing results based on region!</span></strong>}
                        </div>
                        <ResponsiveTable
                            columns={columns}
                            data={toJS(StaffsStore.data.availableShifts?.data) || []}
                            onChangeSort={onChangeSort}
                            onPageSizeChange={onPageSizeChange}
                            onPageChange={onPageChange}
                            pushSlot={pushSlot}
                            selectedRows={selectedRows} />
                        {/* <table className="table dt-responsive table-hover nowrap hover" style={{ width: "100%" }} id={tableName} ref={tableRef}></table> */}
                    </div>
                    {(selectedRows?.length > 0) && <div className="float-btn">
                        <button type="submit" onClick={() => openModal()} className="btn btn-success w-100 py-3"><i className="far fa-calendar-check"></i>&nbsp; &nbsp;Apply ({selectedRows?.length}) Selected Shift(s)</button>
                    </div>}
                </div>
                <Footer />
            </>
            {/*Apply Available shifts */}
            <CModal size="xl" scrollable visible={isOpen} onClose={() => onDismiss()} backdrop="static">
                <CModalHeader onClose={() => onDismiss()}>
                    <CModalTitle>Applying for the shift(s) listed below, are you sure?</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <table className="table table-bordered  table-sm p-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date/Time</th>
                                <th>Type</th>
                                <th>Category</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedRows && selectedRows?.length !== 0 &&
                                selectedRows?.sort((a, b) => new Date(a.slot_date) - new Date(b.slot_date))?.map((slot, index) => (
                                    <tr className=' p-0' key={index + 'slotss'}>
                                        <td>#{index + 1}</td>
                                        <td>
                                            {slot.category !== 'TC' ? <> <input className={`slot-staff ${(errorTwo.length > 0 && errorTwo?.some(entry => entry.id.includes(slot.id))) ? "red-border red-border-tc-slot-left" : "green-border"}`} readOnly value={slot?.slot_date} />
                                                <TimeRangePicker
                                                    disabled
                                                    value={[slot?.slot_start_time, slot?.slot_end_time]}
                                                    className={(errorTwo.length > 0 && errorTwo?.some(entry => entry.id.includes(slot.id))) ? "timer-staff-error" : "timer-staff-filled"}
                                                    rangeDivider="To"
                                                    clearIcon={null}
                                                    disableClock={true}
                                                /></> :
                                                <>
                                                    <input className={`slot-staff ${(errorTwo.length > 0 && errorTwo?.some(entry => entry.id.includes(slot.id))) ? "red-border red-border-tc-slot-left" : "green-border-tc-slot-left"}`} readOnly value={moment(slot.start_date, 'YYYY-MM-DD').format('MM/DD/YYYY')} />
                                                    <span className={`${(errorTwo.length > 0 && errorTwo?.some(entry => entry.id.includes(slot.id))) ? "red-border-tc-slot-to" : "green-border-tc-slot-to"}`}> To </span>
                                                    <input className={`slot-staff ${(errorTwo.length > 0 && errorTwo?.some(entry => entry.id.includes(slot.id))) ? "red-border red-border-tc-slot-right" : "green-border-tc-slot-right"}`} readOnly value={moment(slot.end_date, 'YYYY-MM-DD').format('MM/DD/YYYY')} />
                                                    <TimeRangePicker
                                                        disabled
                                                        value={[slot?.start_time, slot?.end_time]}
                                                        className={(errorTwo.length > 0 && errorTwo?.some(entry => entry.id.includes(slot.id))) ? "timer-staff-error" : "timer-staff-filled"}
                                                        rangeDivider="To"
                                                        clearIcon={null}
                                                        disableClock={true}
                                                    />
                                                </>
                                            }
                                        </td>
                                        <td>{global.config.shiftType?.find(x => x.value === slot.slot_type)?.name}</td>
                                        <td>{global.config.shiftCaregory?.find(x => x.value === slot?.request?.category)?.label}</td>
                                        <td>
                                            <i className="fa fa-trash text-danger pointer" onClick={() => removeSlot(slot)} aria-hidden="true" data-toggle="tooltip" data-placement="right" title="Remove This Slot"></i>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    {errorOne && <><h5 className='text-danger mt-2'>{errorOne}</h5>
                        {
                            errorTwo?.map((err, index) => {
                                return <small key={index + "errors"} className='text-danger'><i className="fas fa-exclamation-triangle"></i> {err.message}<br /></small>
                            })
                        }</>}
                </CModalBody>
                <div className="modal-footer justify-content-center">
                    <button type="submit" disabled={selectedRows?.length === 0} onClick={addMyShift} className="btn btn-success" style={{ maxWidth: "200px" }}>Yes, Apply</button>
                </div>
            </CModal>
            {/* Update work preferences */}
            <CModal scrollable visible={isOpenDistanceView} onClose={() => onDismissDistanceView()} backdrop="static">
                <CModalHeader >
                    <CModalTitle>{"Update Work Area"}</CModalTitle>
                </CModalHeader>
                <div className="row g-2 modal-body p-4">
                    <Formik
                        initialValues={{
                            staff_id: profileData?.staff_id,
                            miles: profileData?.work_distance || 40,
                            cities: profileData?.preferred_cities ? JSON.parse(profileData?.preferred_cities) : [],
                            states: profileData?.preferred_state ? JSON.parse(profileData?.preferred_state) : [],
                        }}
                        onSubmit={values => updateWorkPreference(values)}
                        enableReinitialize
                        validationSchema={Yup.object({
                            states: Yup.array().min(1, 'Must have at least 1 state').required('Preferred state required').nullable(),
                            cities: Yup.array().min(1, 'Must have at least 1 city').required('Preferred city required').nullable(),
                        })}
                        validateOnChange={false}
                    >
                        {
                            ({ handleChange, handleBlur, handleSubmit, setFieldValue, resetForm, values, errors, touched
                            }) =>
                            (
                                <form onSubmit={handleSubmit}>
                                    <div className="row g-2">
                                        <div className="col-sm-12 mb-3">
                                            <Select
                                                closeMenuOnSelect={false}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.abbreviation}
                                                isMulti
                                                hideSelectedOptions={false}
                                                options={states}
                                                styles={colourStylesTitle}
                                                placeholder=""
                                                isRequired={true}
                                                value={states.filter(({ abbreviation }) => values?.states?.includes(abbreviation)) || ''}
                                                label="Add Preferred States"
                                                onChange={(event) => {
                                                    setFieldValue("states", event?.map(({ abbreviation }) => abbreviation));
                                                    setFieldValue("cities", values?.cities?.filter(ct => event?.map(({ abbreviation }) => abbreviation)?.find(st => st === ct.state_id)))
                                                }}
                                                components={{ Control, MultiValue, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                            />
                                            {errors.states ? (
                                                <div className="error-message">{errors.states}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-12 mb-3">
                                            <Select
                                                closeMenuOnSelect={false}
                                                getOptionLabel={(option) => `${option.label}, ${option.state_id}`}
                                                getOptionValue={(option) => option.label}
                                                isMulti
                                                hideSelectedOptions={false}
                                                options={values?.states?.length > 0 ? cities.filter(city => values?.states?.find(x => x === city.state_id)) : []}
                                                styles={colourStylesTitle}
                                                placeholder=""
                                                isRequired={true}
                                                value={values.cities}
                                                label="Add Preferred Cities"
                                                onChange={(event) => { setFieldValue("cities", event); }} components={{ Control, MultiValue, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                            />
                                            {errors.cities ? (
                                                <div className="error-message">{errors.cities}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-12 mb-3">
                                            <Select
                                                onChange={(event) => { setFieldValue("miles", event.value) }}
                                                placeholder=""
                                                label="Select Work Area"
                                                isRequired={true}
                                                value={global.config.radiusMiles?.filter(({ value }) => values?.miles === value) || ''}
                                                styles={colourStylesTitle}
                                                isSearchable={true}
                                                options={global.config.radiusMiles}
                                                components={{ Control }} />
                                        </div>
                                        <div className="d-flex justify-content-center pt-1">
                                            <button className="btn btn-primary w-auto pe-5 ps-5 p-2" type="submit">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            )}
                    </Formik>
                </div>
            </CModal>
            <Spinner isLoading={pageLoader} />
        </React.Fragment >
    )
})
const MoreSelectedBadge = ({ items }) => {
    const style = {
        marginLeft: "auto",
        background: "#d4eefa",
        borderRadius: "4px",
        // fontFamily: "Open Sans",
        fontSize: "11px",
        padding: "3px",
        order: 99
    };

    const title = items.join(", ");
    const length = items.length;
    const label = `+ ${length} facilit${length !== 1 ? "ies" : "y"}`;

    return (
        <div style={style} title={title}>
            {label}
        </div>
    );
};
const MultiValue = ({ index, getValue, ...props }) => {
    const maxToShow = 3;
    const overflow = getValue()
        .slice(maxToShow)
        .map((x) => x.label);

    return index < maxToShow ? (
        <components.MultiValue {...props} />
    ) : index === maxToShow ? (
        <MoreSelectedBadge items={overflow} />
    ) : null;
};
export const Control = (props) => {
    return (
        <>
            <label className={props.hasValue || props.isFocused ? "did-floating-label did-floating-label-top" : "did-floating-label did-floating-label"} style={{ zIndex: 99 }}>{props?.selectProps?.label}</label>
            <components.Control {...props} />
        </>
    );
};
export default AvailableShiftsPage;