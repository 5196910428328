/* eslint-disable */
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import BaseRouter from "./routes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import jwt_decode from "jwt-decode";
import "./api/constant";
import HomeStore from "@store/HomeStore";
import ScrollToTop from "@helpers/ScrollToTop";
import { observer } from "mobx-react";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import secureLocalStorage from "react-secure-storage";
import StaffsStore from "@store/StaffsStore";
import UserStore from "@store/UserStore";
import AdminStore from "@store/AdminStore";
import { BASE_API_URL_FILE, HOME_URL, WEB_SOCKET } from "./api/config";
import MaintenancePage from "./pages/MaintenancePage";
import { Images } from "./elements";
import { toJS } from "mobx";
import EmployerModal from "./components/pending-approval-modal/EmployerModal";
import EmployeeModal from "./components/pending-approval-modal/EmployeeModal";

const App = observer(() => {
  // Initialize React Ga with your tracking ID

  var selectedContractExtIds = [];
  var selectedSlotExtIds = [];
  const [maintenance, setMaintenance] = useState(false);
  const [showSlotModal, setShowSlotModal] = useState(false);
  const [pendingShiftList, setPendingShiftList] = useState([]); //Staff
  const [pendingContractList, setPendingContractList] = useState([]); //Staff
  const [pendingShiftExtList, setPendingShiftExtList] = useState([]); //Staff Extension
  const [pendingContractExtList, setPendingContractExtList] = useState([]); //Staff Extension
  const [pendingCount, setPendingCount] = useState(0);
  const [callCount, setCallCount] = useState(0);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  const [selectedContractIds, setSelectedContractIds] = useState([]);
  const [selectedSlotIds, setSelectedSlotIds] = useState([]);
  //Staff side pending approval check
  useEffect(() => {
    let pendingShifts = toJS(StaffsStore.data.pendingShiftList);
    setPendingCount(
      pendingShifts?.contracts?.concat(
        pendingShifts?.data,
        pendingShifts?.ex_contracts,
        pendingShifts?.ex_data
      )?.length
    );
    setPendingContractList(pendingShifts?.contracts);
    setPendingShiftList(pendingShifts?.data);
    setPendingShiftExtList(pendingShifts?.ex_data);
    setPendingContractExtList(pendingShifts?.ex_contracts);
  }, [StaffsStore.data.pendingShiftList]);

  //PendingApprovals for client Side
  useEffect(() => {
    let pendingShifts = toJS(UserStore.data.pendingApprovals);
    setPendingCount(
      pendingShifts?.contracts?.concat(pendingShifts?.data)?.length
    );
    setPendingContractList(pendingShifts?.contracts);
    setPendingShiftList(pendingShifts?.data);
  }, [UserStore.data.pendingApprovals]);

  //BlueSky Token
  useEffect(() => {

    // Check if the current domain is 'domain.findstaff.staffers.us'
    if (window.location.hostname === 'findstaff.staffers.us') {
      // Redirect to 'staffers.us'
      window.location.href = 'https://staffers.us';
    }

    HomeStore.getDashboardData();
    try {
      let token = localStorage.getItem("refresh-token");
      decoded = jwt_decode(token);
      if (moment.unix(decoded.exp).format() < moment().format()) {
        setLoading(false);
        throw 401;
      } else {
        return true;
      }
    } catch {
      // encrypt data
      let valueToEncrypt = global.config.app_key;
      let password = global.config.app_password;
      let encrypted = CryptoJSAesJson.encrypt(valueToEncrypt, password);
      const formData = new FormData();
      formData.append("passphrase", encrypted);
      UserStore.getRefreshToken(
        formData,
        navigationCallBackSuccess,
        navigationCallBack
      );
    }
  }, []);

  const navigationCallBackSuccess = () => {
    return true;
  };

  const navigationCallBack = () => {
    // encrypt data
    let valueToEncrypt = global.config.app_key;
    let password = global.config.app_password;
    let encrypted = CryptoJSAesJson.encrypt(valueToEncrypt, password);
    const formData = new FormData();
    formData.append("passphrase", encrypted);
    //three time set
    UserStore.getRefreshToken(
      formData,
      navigationCallBackSuccess,
      navigationCallBackFailed
    );
  };

  const navigationCallBackFailed = () => {
    console.log("retry....", callCount);
    setCallCount(callCount + 1);
  };

  useEffect(() => {
    console.log("sleep called");
    const interval = setInterval(async () => {
      if (callCount < 3 && callCount !== 0) {
        console.log("calling token refresh..", callCount);
        await navigationCallBack();
      } else if (callCount !== 0) {
        console.log("sleep");
        clearInterval(interval);
        await new Promise((resolve) => setTimeout(resolve, 2 * 60 * 1000));
        setCallCount(0);
      }
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [callCount]);

  //Websocket
  useEffect(() => {
    let token = secureLocalStorage.getItem("token");
    let tokenAdmin = secureLocalStorage.getItem("admin-token");
    if (!token && !tokenAdmin) {
      return;
    }
    console.log("websocket connection....");
    let userData = secureLocalStorage.getItem("userData");
    if (userData === "undefined") {
      secureLocalStorage.clear();
      return;
    }
    if (JSON?.parse(userData)?.bluesky_staff_id) {
      // HomeStore.getCaregiverDetails(JSON?.parse(userData)?.bluesky_staff_id)?.then((data) => {
      //     StaffsStore.updateProfileStatus({ "status_id": data?.DelFl });
      // });
      StaffsStore.getSignedDocuments();
      StaffsStore.getPendingShiftData();
    }

    window.Pusher = Pusher;
    window.Echo = new Echo({
      broadcaster: "pusher",
      key: "production_staffinders",
      cluster: "mt1",
      forceTLS: false,
      encrypted: true,
      wsHost: `${WEB_SOCKET}`,
      wsPort: 6001,
      wssPort: 6001,
      enabledTransports: ["ws", "wss"],
      authEndpoint: `${BASE_API_URL_FILE}broadcasting/auth`,
      auth: {
        headers: {
          Authorization: `Bearer ${token ? token : tokenAdmin}`,
          Accept: "application/json",
        },
      },
      withCredentials: true,
    });
    let userType = secureLocalStorage.getItem("type");
    if (userType === 1) {
      let data = secureLocalStorage.getItem("adminData");
      window.Echo.private(`App.Models.Admin.${JSON.parse(data)?.id}`).listen(
        ".Illuminate\\Notifications\\Events\\BroadcastNotificationCreated",
        (data) => {
          if (data?.data?.popup === 0) {
            AdminStore.getSettings();
            toast.info(data?.data.heading, {
              position: "top-right",
              autoClose: true,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              onClick: () => {
                toast.dismiss();
                if (data?.data?.link) {
                  window.location.href = data?.data?.link;
                }
              },
            });
          } else {
            swal({
              title: data?.data?.heading,
              text: data?.data?.content,
              buttons: [false, "Ok"],
            }).then((willDelete) => {
              if (willDelete) {
                if (data?.data?.link) {
                  window.location.href = data?.data?.link;
                }
              } else {
              }
            });
          }
          const audio = new Audio(Images.notificationAlert);
          audio.play();
          AdminStore.getStaffingRequestList();
          AdminStore.getJobProfileRequests();
          AdminStore.getNotificationsData();
        }
      );
      return;
    }

    if (userType == "false") {
      UserStore.getPendingApprovals();
      window.Echo.private(`App.Models.User.${JSON.parse(userData)?.id}`).listen(
        ".Illuminate\\Notifications\\Events\\BroadcastNotificationCreated",
        (data) => {
          if (data?.data?.popup === 0) {
            const audio = new Audio(Images.notificationAlert);
            audio.play();
            toast.info(data?.data.heading, {
              position: "top-right",
              autoClose: true,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              onClick: () => {
                toast.dismiss();
                if (data?.data?.link) {
                  window.location.href = data?.data?.link;
                }
              },
            });
          } else {
            swal({
              title: data?.data?.heading,
              text: data?.data?.subject,
              buttons: [false, "Ok"],
            }).then((willDelete) => {
              if (willDelete) {
                if (data?.data?.link) {
                  window.location.href = data?.data?.link;
                }
              } else {
              }
            });
          }
          const sentence = "has been fulfilled";
          if (data?.data?.heading.includes(sentence)) {
            UserStore.getUnratedShift();
          }
          UserStore.getNotificationsData();
        }
      );
    } else {
      window.Echo.private(
        `App.Models.Staff.${JSON?.parse(userData)?.id}`
      ).listen(
        ".Illuminate\\Notifications\\Events\\BroadcastNotificationCreated",
        (data) => {
          if (data?.data?.popup === 0) {
            const audio = new Audio(Images.notificationAlert);
            audio.play();
            toast.info(data?.data.heading, {
              position: "top-right",
              autoClose: true,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              onClick: () => {
                toast.dismiss();
                if (data?.data?.link) {
                  window.location.href = data?.data?.link;
                }
              },
            });
          } else {
            swal({
              title: data?.data?.heading,
              text: data?.data?.subject,
              buttons: [false, "Ok"],
            }).then((willDelete) => {
              if (willDelete) {
                if (data?.data?.link) {
                  window.location.href = data?.data?.link;
                }
              } else {
              }
            });
          }
        }
      );
      StaffsStore.getAppliedShiftData();
      StaffsStore.getJobProfile(1);
    }
  }, [
    UserStore.data.token,
    StaffsStore.data.token,
    AdminStore.data.admintoken,
  ]);

  //WS CLOSE 2HR
  useEffect(() => {
    let token = secureLocalStorage.getItem("token");
    let tokenAdmin = secureLocalStorage.getItem("admin-token");
    const checkInactivity = () => {
      if (Date.now() - lastActivityTime >= 7200000) {
        if (window.Echo && (token || tokenAdmin)) {
          window.Echo.disconnect();
          swal({
            title: "Are you still there?",
            text: "You have been inactive for a while. Please click below to refresh the page!",
            icon: "info",
            buttons: {
              refresh: {
                text: "Refresh",
                value: "refresh",
              },
            },
          }).then((value) => {
            if (value === "refresh") {
              window.location.reload();
            }
          });
        } else {
          console.log("no connection");
        }
      }
    };

    const handleActivity = () => setLastActivityTime(Date.now());

    const intervalId = setInterval(checkInactivity, 7200000);
    return () => {
      document.removeEventListener("mousemove", handleActivity);
      document.removeEventListener("keypress", handleActivity);
      clearInterval(intervalId);
    };
  }, [lastActivityTime]);

  useEffect(() => {
    setMaintenance(
      HomeStore?.data?.dashboardData?.find((x) => x.key_name === "Maintenance")
        ?.value == 1
    );
  }, [HomeStore?.data?.dashboardData]);

  const handleSelectAllContract = (e) => {
    //1 For Approve 2 for reject only for contract
    if (e.target.checked) {
      if (e.target.value === "Approve") {
        setSelectedContractIds(
          pendingContractList?.map((x) => {
            return { id: x.id, code: 5 };
          })
        );
        return;
      }
      if (e.target.value === "Reject") {
        setSelectedContractIds(
          pendingContractList?.map((x) => {
            return { id: x.id, code: 3 };
          })
        );
        return;
      }
    }
  };
  const handleSelectAllSlots = (e) => {
    if (e.target.checked) {
      if (e.target.value === "Approve") {
        setSelectedSlotIds(
          pendingShiftList?.map((x) => {
            return { id: x.id, code: 2 };
          })
        );
        return;
      }
      if (e.target.value === "Reject") {
        setSelectedSlotIds(
          pendingShiftList?.map((x) => {
            return { id: x.id, code: 1 };
          })
        );
        return;
      }
      if (e.target.value === "Reject-Close") {
        setSelectedSlotIds(
          pendingShiftList?.map((x) => {
            return { id: x.id, code: 3 };
          })
        );
        return;
      }
    }
  };

  const contractAdd = (type, contract_id) => {
    setSelectedContractIds((prevState) => {
      const index = prevState.findIndex((item) => item.id === contract_id);
      return index !== -1
        ? [
          ...prevState.slice(0, index),
          { id: contract_id, code: type === "Approve" ? 5 : 3 },
          ...prevState.slice(index + 1),
        ]
        : [...prevState, { id: contract_id, code: type === "Approve" ? 5 : 3 }];
    });
  };

  const slotAdd = (type, slot_id) => {
    setSelectedSlotIds((prevState) => {
      const index = prevState.findIndex((item) => item.id === slot_id);
      return index !== -1
        ? [
          ...prevState.slice(0, index),
          {
            id: slot_id,
            code: type === "Approve" ? 2 : type === "Reject-Close" ? 3 : 1,
          },
          ...prevState.slice(index + 1),
        ]
        : [
          ...prevState,
          {
            id: slot_id,
            code: type === "Approve" ? 2 : type === "Reject-Close" ? 3 : 1,
          },
        ];
    });
  };

  const contractAcceptReject = () => {
    if (selectedContractIds?.length === 0) {
      return;
    }
    const param = {
      shifts: JSON.stringify(selectedContractIds),
    };
    if (secureLocalStorage.getItem("type") === "true") {
      StaffsStore.contractAppRejectRequest(
        param,
        navigationCallBackForEmployee
      );
    }
    if (secureLocalStorage.getItem("type") === "false") {
      UserStore.onApproveContract(param, navigationCallBackForEmployer);
    }
  };

  const slotAcceptReject = () => {
    if (selectedSlotIds?.length === 0) {
      toast.warn("Please select at least one shift!");
      return;
    }
    const param = {
      slots: JSON.stringify(selectedSlotIds),
    };
    if (secureLocalStorage.getItem("type") === "true") {
      StaffsStore.slotAppRejectRequest(param, navigationCallBackForEmployee);
    }
    if (secureLocalStorage.getItem("type") === "false") {
      UserStore.onApproveSlots(param, navigationCallBackForEmployer);
    }
  };
  const navigationCallBackForEmployee = () => {
    StaffsStore.getPendingShiftData();
    swal(
      "Success!",
      "Your approval/reject request was successfully submitted. Thank you for utilizing our service.",
      "success"
    );
    setShowSlotModal(false);
  };
  const navigationCallBackForEmployer = () => {
    swal(
      "Success!",
      "Your approval/reject request was successfully submitted. Thank you for utilizing our service.",
      "success"
    );
    setShowSlotModal(false);
    UserStore.getStaffingRequest();
    UserStore.getPendingApprovals();
  };
  //EXTENSION
  const handleSelectAllContractExt = (e) => {
    //1 For Approve 2 for reject only for contract
    selectedContractExtIds = [];
    if (e.target.checked) {
      if (e.target.value === "Approve") {
        const checkboxes = document.querySelectorAll(
          `#approval #approve-contract-ext input[type='radio']`
        );
        for (const checkbox of checkboxes) {
          checkbox.checked = e.target.checked;
        }
        selectedContractExtIds = pendingContractExtList?.map((x) => {
          return { id: x.id, code: 1 };
        });
        return;
      }
      if (e.target.value === "Reject") {
        const checkboxes = document.querySelectorAll(
          `#approval #reject-contract-ext input[type='radio']`
        );
        for (const checkbox of checkboxes) {
          checkbox.checked = e.target.checked;
        }
        selectedContractExtIds = pendingContractExtList?.map((x) => {
          return { id: x.id, code: 2 };
        });
        return;
      }
    }
  };
  const handleSelectAllSlotsExt = (e) => {
    selectedSlotExtIds = [];
    if (e.target.checked) {
      if (e.target.value === "Approve") {
        const checkboxes = document.querySelectorAll(
          `#approval #approve-slots-ext input[type='radio']`
        );
        for (const checkbox of checkboxes) {
          checkbox.checked = e.target.checked;
        }
        selectedSlotExtIds = pendingShiftExtList?.map((x) => {
          return { id: x.id, code: 2 };
        });
        return;
      }
      if (e.target.value === "Reject") {
        const checkboxes = document.querySelectorAll(
          `#approval #reject-slots-ext  input[type='radio']`
        );
        for (const checkbox of checkboxes) {
          checkbox.checked = e.target.checked;
        }
        selectedSlotExtIds = pendingShiftExtList?.map((x) => {
          return { id: x.id, code: 1 };
        });
        return;
      }
    }
  };

  const contractExtAdd = (type, contract_id) => {
    const index = selectedContractExtIds.findIndex(
      (item) => item.id === contract_id
    );
    index !== -1
      ? (selectedContractExtIds[index] = {
        id: contract_id,
        code: type === "Approve" ? 1 : 2,
      })
      : selectedContractExtIds.push({
        id: contract_id,
        code: type === "Approve" ? 1 : 2,
      });
    return;
  };

  const slotExtAdd = (type, slot_id) => {
    const index = selectedSlotExtIds.findIndex((item) => item.id === slot_id);
    index !== -1
      ? (selectedSlotExtIds[index] = {
        id: slot_id,
        code: type === "Approve" ? 2 : 1,
      })
      : selectedSlotExtIds.push({
        id: slot_id,
        code: type === "Approve" ? 2 : 1,
      });
    return;
  };

  const contractExtAcceptReject = () => {
    if (selectedContractExtIds?.length === 0) {
      return;
    }
    const param = {
      shifts: JSON.stringify(selectedContractExtIds),
    };

    StaffsStore.contractExtAppRejectRequest(
      param,
      navigationCallBackForEmployee
    );
  };

  const slotExtAcceptReject = () => {
    if (selectedSlotExtIds?.length === 0) {
      return;
    }
    const param = {
      slots: JSON.stringify(selectedSlotExtIds),
    };
    StaffsStore.slotExtAppRejectRequest(param, navigationCallBackForEmployee);
  };


  return (
    <React.Fragment>
      <Router>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
          theme="light"
        />
        <ScrollToTop />
        {maintenance ? <MaintenancePage /> : <BaseRouter />}
      </Router>

      {/* Floating button only for stafff */}
      {secureLocalStorage.getItem("token") &&
        showSlotModal === false &&
        pendingCount > 0 && (
          <div className="pending-floating-button">
            <span className="badge-tag-extention">{pendingCount}</span>
            <div
              onClick={() => {
                setShowSlotModal(true);
                setSelectedContractIds([]);
                setSelectedSlotIds([]);
              }}
              className="btn btn-primary btn-lg p-1"
            >
              <strong className="text-white">

                <span className="btn rotate-clock pe-3 text-warning"><i className="fas fa-hourglass-half"></i></span> Pending
                Request(s)
              </strong>{" "}

            </div>
          </div>
        )}
      {secureLocalStorage.getItem("type") === "false" && (
        <div className="extention-floating-button mt-5">
          <div
            onClick={() => window.open("/shift-request", "_blank")}
            className="btn btn-success btn-lg ps-4 pe-4 mt-3"
          >
            <strong className="text-white">
              <i className="fas fa-calendar-plus pe-3"></i> Add Order
            </strong>{" "}
          </div>
        </div>
      )}
      {/*  SLOT APPROVAL Employer SIDE*/}
      {secureLocalStorage.getItem("type") === "false" && (
        <>
          <EmployerModal
            showSlotModal={showSlotModal}
            setShowSlotModal={setShowSlotModal}
            pendingContractList={pendingContractList}
            pendingShiftList={pendingShiftList}
            pendingContractExtList={pendingContractExtList}
            handleSelectAllSlots={handleSelectAllSlots}
            handleSelectAllContract={handleSelectAllContract}
            contractAdd={contractAdd}
            slotAdd={slotAdd}
            contractAcceptReject={contractAcceptReject}
            slotAcceptReject={slotAcceptReject}
            selectedContractIds={selectedContractIds}
            selectedSlotIds={selectedSlotIds}
          />
        </>
      )}

      {/*  SLOT APPROVAL Employee SIDE*/}
      {secureLocalStorage.getItem("type") === "true" && (
        <>
          <EmployeeModal
            showSlotModal={showSlotModal}
            setShowSlotModal={setShowSlotModal}
            pendingContractList={pendingContractList}
            pendingShiftList={pendingShiftList}
            pendingContractExtList={pendingContractExtList}
            handleSelectAllContract={handleSelectAllContract}
            contractAdd={contractAdd}
            slotAdd={slotAdd}
            handleSelectAllSlots={handleSelectAllSlots}
            handleSelectAllContractExt={handleSelectAllContractExt}
            contractAcceptReject={contractAcceptReject}
            slotAcceptReject={slotAcceptReject}
            contractExtAcceptReject={contractExtAcceptReject}
            slotExtAcceptReject={slotExtAcceptReject}
            slotExtAdd={slotExtAdd}
            handleSelectAllSlotsExt={handleSelectAllSlotsExt}
            contractExtAdd={contractExtAdd}
            pendingShiftExtList={pendingShiftExtList}
            selectedContractIds={selectedContractIds}
            selectedSlotIds={selectedSlotIds}
          />
        </>
      )}
    </React.Fragment>
  );
});
export default App;
