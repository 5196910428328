/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react"
import HeaderPage from "../layout/HeaderPage";
import { Helmet } from "react-helmet";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import { CButton, CFormLabel, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CInputGroup, CFormInput, CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody, CTableDataCell, CModalBody, CModalFooter } from "@coreui/react";
import './StaffingRequestList.css'
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import AdminStore from "@store/AdminStore";
import HomeStore from "@store/HomeStore";
import StaffsStore from "@store/StaffsStore";
import * as Yup from 'yup';
import { toJS } from "mobx";
import moment from "moment";
import Spinner from "@components/Spinner";
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
import { CModal, CModalHeader, CModalTitle } from "@coreui/react";
import { toast } from "react-toastify";
import { Formik, Field, FieldArray } from "formik";
import { InputField } from "@elements/index";
import DatePicker, { getAllDatesInRange } from "react-multi-date-picker"
import weekends from "react-multi-date-picker/plugins/highlight_weekends"
import ReactDOM from "react-dom";
import Select, { components } from 'react-select';
import SelectField from "@elements/select/SelectField";
import { UpdateTCSchema, shiftImportAdminSchema } from '@utils/schemas/allSchema';
import { colourStylesState, colourStylesTitle } from '@utils/customStyle/style';
import ExcelPreview from "@elements/excelViewer/ExcelPreview";
import { useNavigate } from "react-router-dom";
import swal from '@sweetalert/with-react';
import { onCopySlot } from '@utils/constant/constant';
const { parse, isBefore, isAfter, addDays } = require('date-fns');
var specialtieJson = require('@assets/SpecialtiesCodes.json');
import Papa from 'papaparse';
import { calculateLunch, calculateTotalHr } from "../commonFunctions";
const delimiter = ',';
const $ = window.$;
var today = new Date();


const StaffingRequestList = observer(() => {
    const tableRef = useRef()
    const tableName = "table1"
    const navigate = useNavigate();

    const formikRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [openNewCaregiver, setOpenNewCaregiver] = useState(false);
    const [signleData, setSingleData] = useState([]);
    const [degree, setDegree] = useState([]);
    const [staffingList, setStaffingList] = useState([]);
    const [editSlot, setEditSlot] = useState([]);
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [formData, setFormData] = useState();
    const [multi, setValues] = useState([])
    const [dates, setDates] = useState([])
    const [allDates, setAllDates] = useState([])
    const [disabledSlots, setDisabledSlots] = useState([]);
    const [copy, setCopy] = useState(false);
    const [deletedSlotFacility, setDeletedSlotFacility] = useState([]);
    const [deletedSlotCaregiver, setDeletedSlotCaregiver] = useState([]);
    const [removeSlot, setRemoveSlot] = useState([]);
    const [extentionUser, setExtentionUser] = useState();
    const [extentionModal, setExtentionModal] = useState(false);
    const [paginationLength, setPaginationLength] = useState(25);
    const [inputSearch, setInputSearch] = useState('');
    const [isTouched, setIsTouched] = useState(false);
    const [unExportedCount, setUnExportedCount] = useState(0);
    const [editMode, setEditMode] = useState(false);
    const [cancelMode, setCancelMode] = useState(false);
    const [assignTime, setAssignTime] = useState();
    const [assignDate, setAssignDate] = useState();
    const [staffByProfession, setStaffByProfession] = useState([]);
    const [assignToStaff, setAssignToStaff] = useState(null);
    const [isOpenFormTC, setIsOpenFormTC] = useState(false);
    const [tcFormDataTC, setTCFormData] = useState();
    const [isOpenFormEditTC, setIsOpenFormEditTC] = useState(false);
    const [isOpenCSVUpload, setIsOpenCSVUpload] = useState(false);
    const [clientList, setClientList] = useState([]);
    const [slotData, setSlotData] = useState();
    const [specialties, setSpecialties] = useState([]);
    const [excelData, setExcelData] = useState([]);
    const [isOpenExcelDownload, setIsOpenExcelDownload] = useState(false);
    const [isOpenProfChange, setIsOpenProfChange] = useState(false);
    const [facilityList, setFacilityList] = useState([]);

    //HOOKS
    useEffect(() => {
        AdminStore.updatePage();
        AdminStore.getStaffingRequestList();
        StaffsStore.getRegionsList();
        AdminStore.getAllStaffingRequestList().then(data => {
            var filterUnexported = data?.map(x => x.unexported_assignments)
            if (filterUnexported?.length > 0) {
                filterUnexported = [].concat(...filterUnexported)
                // Check if any element has "is_exported" set to 1
                const hasExportedElement = filterUnexported?.filter(item => item.is_exported === 0)?.length;
                setUnExportedCount(hasExportedElement)
            }
        })
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        let popUpData = staffingList?.find(x => x.id == id?.replace(/\D/g, ""))
        if (popUpData) {
            console.log("called by popop");
            showDetails(popUpData)
        }
    }, [staffingList]);

    useEffect(() => {
        setStaffingList(toJS(AdminStore?.data?.staffingList))
    }, [AdminStore?.data?.staffingList]);

    useEffect(() => {
        setStaffByProfession(toJS(AdminStore?.data?.staffListByProfession)?.filter(x => x.staffer_id && x.external_id && x.status === 3))
    }, [AdminStore?.data?.staffListByProfession]);

    useEffect(() => {
        setDegree(HomeStore.data.degreeList)
    }, [HomeStore.data.degreeList]);
    useEffect(() => {
        setSpecialties(HomeStore?.data?.dropDown)
    }, [HomeStore?.data?.dropDown]);
    useEffect(() => {
        setClientList(toJS(AdminStore?.data?.allClientList))
    }, [AdminStore?.data?.allClientList]);


    useEffect(() => {
        const renderPagination = () => {
            const table = $('#table1').DataTable();
            const totalPages = AdminStore.data.lastPage;
            const currentPage = AdminStore.data.page;

            const paginationContainer = $('.dataTables_paginate');
            const paginationList = $('<ul>', { class: 'pagination' });
            paginationContainer.empty().append(paginationList);

            let startPage = Math.max(1, currentPage - 2);
            let endPage = Math.min(totalPages, currentPage + 2);

            if (totalPages > 5 && currentPage <= 3) {
                endPage = 5;
            } else if (totalPages > 5 && currentPage >= totalPages - 2) {
                startPage = totalPages - 4;
            }
            const firstButton = $('<a>', {
                text: 'First',
                class: `page-link pointer ${currentPage === 1 ? 'disabled' : ''}`,
                click: function () {
                    if (currentPage > 1) {
                        AdminStore.data.page = 1;
                        AdminStore.getStaffingRequestList();
                        table.page('first').draw(false);
                    }
                },
            });

            const previousButton = $('<a>', {
                text: 'Previous',
                class: `page-link pointer ${currentPage === 1 ? 'disabled' : ''}`,
                click: function () {
                    if (currentPage > 1) {
                        AdminStore.data.page = AdminStore.data.page - 1;
                        AdminStore.getStaffingRequestList();
                        table.page('previous').draw(false);
                    }
                },
            });

            const nextButton = $('<a>', {
                text: 'Next',
                class: `page-link pointer ${currentPage === totalPages ? 'disabled' : ''}`,
                click: function () {
                    if (currentPage < totalPages) {
                        AdminStore.data.page = AdminStore.data.page + 1;
                        AdminStore.getStaffingRequestList();
                        table.page('next').draw(false);
                    }
                },
            });
            const lastButton = $('<a>', {
                text: 'Last',
                class: `page-link pointer ${currentPage === totalPages ? 'disabled' : ''}`,
                click: function () {
                    if (currentPage < totalPages) {
                        AdminStore.data.page = AdminStore.data.lastPage;
                        AdminStore.getStaffingRequestList();
                        table.page('last').draw(false);
                    }
                },
            });

            const firstListItem = $('<li>', { class: `page-item ${currentPage === 1 ? 'disabled' : ''}` }).append(firstButton);
            const previousListItem = $('<li>', { class: `page-item ${currentPage === 1 ? 'disabled' : ''}` }).append(previousButton);
            const nextListItem = $('<li>', { class: `page-item ${currentPage === totalPages ? 'disabled' : ''}` }).append(nextButton);
            const lastListItem = $('<li>', { class: `page-item ${currentPage === totalPages ? 'disabled' : ''}` }).append(lastButton);
            paginationList.append(firstListItem);
            paginationList.append(previousListItem);

            for (let i = startPage; i <= endPage; i++) {
                const pageLink = $('<a>', {
                    text: i,
                    class: `page-link pointer ${i === currentPage ? 'active' : ''}`,
                    click: function () {
                        AdminStore.data.page = i;
                        AdminStore.getStaffingRequestList();
                        table.page(i - 1).draw(false);
                    },
                });

                const listItem = $('<li>', { class: `page-item ${i === currentPage ? 'active' : ''}` }).append(pageLink);
                paginationList.append(listItem);
            }

            paginationList.append(nextListItem);
            paginationList.append(lastListItem);
        };

        $.fn.dataTable.ext.errMode = 'none';
        $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust();

        const table = $(`#${tableName}`).DataTable(
            {
                data: staffingList,
                order: [], // Disable initial sorting
                pageLength: paginationLength,
                lengthMenu: [25, 50, 100, 500, 1000],
                columns: [
                    {
                        title: "Ref.", data: 'id',
                        render: function (data, type, full, meta) {
                            return data;
                        }
                    },
                    {
                        title: "Caregiver Name", data: "staff_name", "render": function (data, type, row, meta) {
                            return data || "-";
                        }
                    },
                    {
                        title: "Facility", data: "facility",
                        render: function (data, type, full, meta) {
                            return data?.organization_name;
                        }
                    },
                    {
                        title: "Shift Date/Time", data: "assignments", "render": function (data, type, full, meta) {
                            const dates = data?.map(item => new Date(item?.slot_date));
                            // Find the minimum and maximum dates
                            const minDate = new Date(Math?.min.apply(null, dates));
                            const maxDate = new Date(Math?.max.apply(null, dates));

                            // Function to pad a number with leading zeros
                            const zeroPad = (value, length) => {
                                return value.toString().padStart(length, "0");
                            };

                            // Format the dates as strings with zero-padded month and day values
                            const minDateString = `${zeroPad(minDate.getMonth() + 1, 2)}/${zeroPad(minDate.getDate(), 2)}/${minDate.getFullYear()}`;
                            const maxDateString = `${zeroPad(maxDate.getMonth() + 1, 2)}/${zeroPad(maxDate.getDate(), 2)}/${maxDate.getFullYear()}`;

                            return data?.length > 0 ? '<span class="pointer link-info">' + minDateString + " To " + maxDateString + '</span>' :
                                full?.category === 'TC' ? '<span class="pointer link-info">' + moment(full.start_date, 'YYYY-MM-DD').format('MM/DD/YYYY') + " To " + moment(full.end_date, 'YYYY-MM-DD').format('MM/DD/YYYY') + '</span>' :
                                    "No Slot Available";
                        }
                    },
                    {
                        title: "Shift Type", data: 'category', "render": function (data, type, full, meta) {
                            return full?.category === 'TC' ? "Traveler" : global.config.shiftCaregory?.find(x => x.value === data)?.label
                        }
                    },
                    {
                        title: "Profession-Specialty", "render": function (data, type, full, meta) {
                            return full?.specialty_name ? full?.specialty_name : degree?.find(deg => deg.Id == full?.profession)?.Name
                        }
                    },
                    {
                        title: "Org. Email", data: "user", "render": function (data, type, full, meta) {
                            return data?.email || '-';
                        }
                    },
                    {
                        title: "Org. Contact", data: "user", "render": function (data, type, full, meta) {
                            return data?.contact || '-';
                        }
                    },
                    {
                        title: "Caregiver ID", data: "staff_id", "render": function (data, type, row, meta) {
                            return data || "-";
                        }
                    },
                    {
                        title: "State", width: 60, data: "state", "render": function (data, type, row, meta) {
                            return data || "-";
                        }
                    },
                    {
                        title: "City", data: "city", "render": function (data, type, row, meta) {
                            return data || "-";
                        }
                    },
                    {
                        title: "Extended Times", data: "extentions", "render": function (data, type, row, meta) {
                            return data || "-";
                        }
                    },
                    {
                        title: "Status", data: "status"
                    },
                    {
                        title: "Creation Time", data: "created_at", "render": function (data, type, row, meta) {
                            return moment(data).format('MM/DD/yy hh:mm A') || "-";
                        }
                    },
                    {
                        title: "Last Update", data: "updated_at", "render": function (data, type, row, meta) {
                            return moment(data).fromNow() || "-";
                        }
                    },
                    {
                        title: "Manage", data: ""
                    },
                ],
                columnDefs: [
                    {
                        targets: 0,
                        responsivePriority: 5
                    },
                    {
                        targets: 4,
                        responsivePriority: 4
                    },
                    {
                        targets: 2,
                        responsivePriority: 3
                    },
                    {
                        targets: 15,
                        responsivePriority: 1,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <CDropdown variant="btn-group" direction='dropstart' alignment="end">
                                        <CDropdownToggle size="sm" color="transparent" caret={false} href="#" className="p-0"><i className="fa fa-ellipsis-v text-primary"></i></CDropdownToggle>
                                        <CDropdownMenu>
                                            <CDropdownItem className="more-menu">
                                                <CButton
                                                    color="primary"
                                                    variant="outline"
                                                    shape="square"
                                                    size="sm"
                                                    className="w-100"
                                                    onClick={() => {
                                                        showDetails(rowData)
                                                    }}
                                                >
                                                    View <i className="fas fa-eye"></i>
                                                </CButton>
                                            </CDropdownItem>
                                            {
                                                (rowData?.category === 'TC') && <CDropdownItem>
                                                    <CButton onClick={() => {
                                                        editTCShift(rowData)
                                                    }} size="sm" className="w-100" variant='outline' color="primary" >
                                                        Edit <i className="fas fa-edit"></i>
                                                    </CButton>
                                                </CDropdownItem>
                                            }
                                            {
                                                (rowData?.category === 'TC' && !rowData?.staff_id) && <CDropdownItem>
                                                    <CButton onClick={() => {
                                                        openProfChangeModal(rowData)
                                                    }} size="sm" className="w-100" variant='outline' color="primary" >
                                                        Change Profession <i className="fas fa-edit"></i>
                                                    </CButton>
                                                </CDropdownItem>
                                            }
                                            {
                                                (rowData?.category !== 'TC' && rowData?.assignments?.every(obj => obj.status === 1)) && <CDropdownItem>
                                                    <CButton onClick={() => {
                                                        openProfChangeModal(rowData)
                                                    }} size="sm" className="w-100" variant='outline' color="primary" >
                                                        Change Profession <i className="fas fa-edit"></i>
                                                    </CButton>
                                                </CDropdownItem>
                                            }
                                            {rowData.status == 0 && <> <CDropdownItem>
                                                <CButton
                                                    color="success"
                                                    variant="outline"
                                                    shape="square"
                                                    size="sm"
                                                    className="w-100"
                                                    onClick={() => statusApprove(rowData, 1)}
                                                >
                                                    Approve <i className="fas fa-user-check"></i>
                                                </CButton>
                                            </CDropdownItem>
                                                <CDropdownItem>
                                                    <CButton
                                                        color="danger"
                                                        variant="outline"
                                                        shape="square"
                                                        size="sm"
                                                        className="w-100"
                                                        onClick={() => statusChange(rowData, 2)}
                                                    >
                                                        Reject <i className="fas fa-user-times"></i>
                                                    </CButton>
                                                </CDropdownItem></>}

                                            {/* {
                                                rowData?.category === 'TR' && (rowData?.extention_request ? null : (rowData?.status == 1 || rowData?.status == 3 || rowData?.status == 4)) && <CDropdownItem>
                                                    <CButton onClick={() => {
                                                        onOpenForm(rowData)
                                                    }} size="sm" className="w-100" variant='outline' color="warning" >
                                                        Extend <i className="far fa-calendar-plus"></i>
                                                    </CButton>
                                                </CDropdownItem>
                                            } */}
                                            {/* FOR CONTACT */}
                                            {
                                                (rowData?.category === "TC" && rowData?.status === 5 && !rowData?.extention_request) && <CDropdownItem>
                                                    <CButton onClick={() => {
                                                        onOpenFormForTC(rowData)
                                                    }} size="sm" className="w-100" variant='outline' color="warning" >
                                                        Extend <i className="far fa-calendar-plus"></i>
                                                    </CButton>
                                                </CDropdownItem>
                                            }

                                            {((rowData.status == 1 || rowData.status == 4) && rowData?.category !== 'TC' && rowData?.staff_response === 1) && <CDropdownItem>
                                                <CButton
                                                    color="success"
                                                    variant="outline"
                                                    shape="square"
                                                    size="sm"
                                                    className="w-100"
                                                    onClick={() => exportShift(rowData)}
                                                >
                                                    Export <i className="far fa-file-excel"></i>
                                                </CButton>
                                            </CDropdownItem>}
                                            {(!rowData.staff_id && rowData.status === 1 && rowData.category === 'TC') && <CDropdownItem>
                                                <CButton
                                                    color="danger"
                                                    variant="outline"
                                                    shape="square"
                                                    size="sm"
                                                    className="w-100"
                                                    onClick={() => onContractStatusChange(rowData, "Delete")}
                                                >
                                                    Delete <i className="fas fa-trash"></i>
                                                </CButton>
                                            </CDropdownItem>}
                                            {(rowData.staff_id && (rowData.status === 1 || rowData.status === 4 || rowData.status === 5) && rowData.category === 'TC') && <CDropdownItem>
                                                <CButton
                                                    color="danger"
                                                    variant="outline"
                                                    shape="square"
                                                    size="sm"
                                                    className="w-100"
                                                    onClick={() => onContractStatusChange(rowData, "Cancel")}
                                                >
                                                    Cancel <i className="fas fa-close"></i>
                                                </CButton>
                                            </CDropdownItem>}
                                            {/* {((rowData.category !== 'TC' && (rowData.status === 1 || rowData.status === 4 || rowData.status === 5)) || (rowData.category === 'TC' && rowData.staff_id && (rowData.status === 1 || rowData.status === 4 || rowData.status === 5))) && <CDropdownItem>
                                                <CButton
                                                    color="dark"
                                                    variant="outline"
                                                    shape="square"
                                                    size="sm"
                                                    className="w-100"
                                                    onClick={() => statusChangeAfterClose(rowData, 3)}
                                                >
                                                    Close
                                                </CButton>
                                            </CDropdownItem>} */}
                                        </CDropdownMenu>
                                    </CDropdown>
                                </div>
                                , td),
                    },
                    {
                        targets: 13,
                        responsivePriority: 6
                    },
                    {
                        targets: 14,
                        responsivePriority: 7
                    },
                    {
                        targets: 5,
                        responsivePriority: 8
                    },
                    {
                        targets: 12,
                        responsivePriority: 2,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <CFormLabel className="row d-flex align-items-center" >
                                        {
                                            rowData?.status === 0 ? <span id="stat" className={'text-warning'}>Pending</span> :
                                                (rowData?.status === 1 && rowData?.category === 'TC' && rowData?.staff_response === 0 && rowData?.staff_id) ? <span className={'text-warning '} id="stat" ><strong>Pending<br /> By Caregiver</strong></span> :
                                                    (rowData?.status === 1 && rowData?.staff_response === 1 && rowData?.staff_id) ? <span className={'text-warning '}><strong>Pending<br /> By Client</strong></span> :
                                                        rowData?.status === 2 ? <span className={'text-danger'}>Rejected</span> : rowData?.status === 3 ? <span className={'text-dark'}>Closed</span> :
                                                            rowData?.assignments?.length === 0 ? (rowData?.category === 'TC' && rowData?.status === 1 && !rowData?.staff_id) ? <span className={'text-danger'}>Unfilled</span> :
                                                                (rowData?.category === 'TC' && rowData?.status === 5 && rowData.staff_id) ? <span className={'text-success'}><b>Filled</b></span> : (rowData?.status === 4 && rowData.extention_request) ? <span className={'text-info'}><b>Requested</b></span> : <span className={'text-muted'}><b>No Update  </b></span>
                                                                : rowData?.assignments?.some(obj => obj.status === 4 && obj.staff_id) ?
                                                                    <span className={'text-warning'}><b>Extention Pending</b></span>
                                                                    : rowData?.assignments?.filter(stat => stat.status === 1 || stat.status === 2)?.every(obj => obj?.status === 2 && obj?.staff_id)
                                                                        ? <span className={'text-success'}><b>Filled</b></span>
                                                                        : rowData?.assignments?.filter(obj => obj.status === 2)?.length > 0 || (rowData?.assignments?.some(obj => obj.status === 1 && obj.staff_id))
                                                                            ? <span className={'text-warning'}><b>Partially Filled</b></span> :
                                                                            rowData?.status === 1 ? <span id="stat" className={'text-danger'}>Unfilled</span> :
                                                                                rowData?.status === 4 ? <span className={'text-success'}>Extended</span> : <span>No Update</span>}
                                        <br />
                                        <br />
                                        <br />
                                        {rowData?.status === 0 && <CButton
                                            color="success"
                                            variant="outline"
                                            shape="square"
                                            size="sm"
                                            className="w-100"
                                            onClick={() => statusApprove(rowData, 1)}
                                        >
                                            Approve <i className="fas fa-user-check"></i>
                                        </CButton>}
                                        {rowData?.assignments.some(obj => obj.status === 4) && <CButton
                                            color="danger"
                                            variant="outline"
                                            shape="square"
                                            size="sm"
                                            className="w-10"
                                            onClick={() => {
                                                onOpenFormRespond(rowData)
                                            }}
                                        >
                                            Respond?
                                        </CButton>}

                                    </CFormLabel>
                                </div>
                                , td),
                    },
                    {
                        targets: 3,
                        responsivePriority: 1,
                        createdCell: function (td, cellData, rowData, row, col) {
                            var listHtml = "";
                            cellData.forEach(function (data) {
                                listHtml += "" + data?.slot_date + " "
                                    + moment(data?.slot_start_time, "HH:mm").format("h:mm A") + " To "
                                    + moment(data?.slot_end_time, "HH:mm").format("h:mm A") + " "
                                    + (data.status === 2 ? "FILLED"
                                        : data.status === 3 ? "Rejected"
                                            : data.status === 4 ? "Extention Pending"
                                                : data.status === 5 ? "Cancelled By Caregiver"
                                                    : data.status === 6 ? "Cancelled By Facility"
                                                        : data.status === 7 ? "Completed" : "UNFILLED") + "\n";
                            })
                            listHtml += "";
                            $(td).attr('data-toggle', 'tooltip');
                            $(td).attr('data-html', 'true');
                            $(td).attr('data-placement', 'top');
                            $(td).attr('title', listHtml);
                        },
                    }
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                "language": {
                    search: '',
                    searchPlaceholder: "Search",
                    emptyTable: "No Record Found",
                    info: "Showing " + AdminStore.data.from + " to " + AdminStore.data.to + " of " + AdminStore.data.total + " entries",
                },
                initComplete: function () {
                    // Apply the search
                    this.api()
                        .columns([0, 1, 6, 7, 8, 10, 11])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px" type="text" placeholder="Filter per page" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });
                    this.api().columns([4]).every(function () {
                        var column = this;
                        var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="">All</option></select>')
                            .appendTo($(column.header()))
                            .on('change', function () {
                                var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                column.search(val ? '^' + val + '$' : '', true, false).draw();
                            });

                        $(select).click(function (e) {
                            e.stopPropagation();
                        });

                        column
                            .data()
                            .unique()
                            .sort()
                            .each(function (d, j) {
                                if (select.find('option[value="' + (d?.category === 'TR' ? 'Traveler' : 'Per Diem') + '"]').length === 0) {
                                    select.append('<option value="' + (d?.category === 'TR' ? 'Traveler' : 'Per Diem') + '">' + (d?.category === 'TR' ? 'Traveler' : "Per Diem") + '</option>');
                                }
                            });
                    });
                    //Specilaty + Profession Dropdown
                    this.api().columns([5]).every(function () {
                        $(select).click(function (e) {
                            e.stopPropagation();
                        });
                        var column = this;

                        // Create the dropdown with "All" as the default option
                        var select = $('<br/><select class="form-control" placeholder="filter" style="width:100%;margin-top:5px;background-color:white"><option value="" selected>All</option></select>')
                            .appendTo($(column.header()));

                        // Keep track of unique values
                        var uniqueValues = {};

                        // Populate the dropdown with unique values from column 5
                        column.data().each(function (d, j) {
                            // Get the HTML content of the cell
                            var cellText = column.cell(j, 5).node().innerHTML;
                            // Check if the value is unique before adding it to the dropdown
                            if (!uniqueValues[cellText]) {
                                uniqueValues[cellText] = true;
                                select.append('<option value="' + cellText + '">' + cellText + '</option>');
                            }
                        });

                        // Add change event listener to the dropdown
                        select.on('change', function () {
                            var val = $.fn.dataTable.util.escapeRegex(
                                $(this).val()
                            );

                            // Search and filter the table
                            column.search(val ? '^' + val + '$' : '', true, false).draw();
                        });

                    });
                    this.api().columns([2]).every(function () {

                        var column = this;

                        // Create the dropdown with "All" as the default option
                        var select = $('<br/><select class="form-control" placeholder="filter" style="width:100%;margin-top:5px;background-color:white"><option value="" selected>All</option></select>')
                            .appendTo($(column.header()));
                        $(select).click(function (e) {
                            e.stopPropagation();
                        });
                        // Keep track of unique values
                        var uniqueValues = {};

                        // Populate the dropdown with unique values from column 5
                        column.data().each(function (d, j) {
                            // Get the HTML content of the cell
                            var cellText = column.cell(j, 2).node().innerHTML;
                            // Check if the value is unique before adding it to the dropdown
                            if (!uniqueValues[cellText]) {
                                uniqueValues[cellText] = true;
                                select.append('<option value="' + cellText + '">' + cellText + '</option>');
                            }
                        });

                        // Add change event listener to the dropdown
                        select.on('change', function () {
                            var val = $.fn.dataTable.util.escapeRegex(
                                $(this).val()
                            );

                            // Search and filter the table
                            column.search(val ? '^' + val + '$' : '', true, false).draw();
                        });

                    });

                    this.api()//Date Filter
                        .columns([3])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><div class="input-daterange input-group" style="width:250px;margin-top:5px"></div>')
                                .appendTo($(column.header()));

                            var startDateInput = $('<input type="text" class="form-control" placeholder="Start Date" style="border-top-left-radius:8px;border-bottom-left-radius:8px;"/>');
                            var endDateInput = $('<input type="text" class="form-control" placeholder="End Date"  style="border-top-right-radius:8px;border-bottom-right-radius:8px;"/>');

                            startDateInput.appendTo(select);
                            endDateInput.appendTo(select);

                            $('.input-daterange').datepicker({
                                format: 'mm-dd-yyyy', // Specify your desired date format here
                                // autoclose: true,
                                todayHighlight: true, // Highlight today's date
                                clearBtn: true, // Show the "Clear" button
                                orientation: 'bottom',
                                daysOfWeekHighlighted: '0,6', // Highlight weekends (0: Sunday, 6: Saturday)
                                templates: {
                                    leftArrow: '<i class="fas fa-chevron-left"></i>', // Customize the left arrow icon
                                    rightArrow: '<i class="fas fa-chevron-right"></i>', // Customize the right arrow icon
                                },
                            });

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (!this.value) {
                                    $(`#${tableName}`).DataTable().column([3]).search('').draw();
                                }
                                if (column.search() !== this.value) {
                                    column.search(moment(this.value).format('MM/DD/YYYY')).draw();
                                }
                            });
                        });
                    //Status
                    this.api().columns([12]) // Assuming this is the correct way to get the DataTables API reference
                        .every(function () {
                            var column = this;
                            var select = $('<select class="form-control" style="width:100%;margin-top:5px;background-color:white"><option value="" selected>All</option></select>')
                                .appendTo($(column.header()))
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                                });

                            // Stop the click event propagation to prevent the dropdown from closing when clicked
                            select.click(function (e) {
                                e.stopPropagation();
                            });

                            // Get unique values from the column data and populate the dropdown
                            column.data().unique().sort().each(function (d, j) {
                                let statusMap = {
                                    0: "Pending",
                                    1: "Open",
                                    2: "Rejected",
                                    3: "Closed",
                                    4: "Extended"
                                };

                                // Use the statusMap to get the corresponding name for the value
                                let name = statusMap[d];

                                select.append('<option value="' + d + '">' + name + '</option>');
                            });

                            // Add change event listener to the dropdown
                            select.on('change', function () {
                                var val = $.fn.dataTable.util.escapeRegex($(this).val());

                                // Search and filter the table
                                column.search(val ? '^' + val + '$' : '', true, false).draw();
                            });
                        });


                },
                drawCallback: function () {
                    renderPagination();
                }
            });
        table.column(1).visible(false);
        table.column(8).visible(false);
        renderPagination();
        table.page.len(paginationLength).draw();
        $(`#${tableName}`).on('length.dt', function (e, settings, len) {
            handlePaginationLengthChange(len)
        });
        table.on('click', 'td', function () {
            // Get the column index of the clicked cell
            const columnIndex = table.cell(this).index()?.column;
            // Check if the clicked cell belongs to the desired column
            if (columnIndex === 2 || columnIndex === 3) {
                // Get the data for the clicked cell
                const rowData = table.row($(this).closest('tr')).data();
                // Handle the event for the clicked cell
                if (rowData) {
                    showDetails(rowData)
                }
            }
        });
        // Attach the processing event handler
        // $(tableRef.current).on('processing.dt', (e, settings, processing) => {
        //     if (processing) {
        //         // Show loader when processing starts
        //         // You can implement your loader display logic here
        //         console.log('Show loader');
        //     } else {
        //         // Hide loader when processing finishes
        //         // You can implement your loader hiding logic here
        //         console.log('Hide loader');
        //     }
        // });
        // Extra step to do extra clean-up.
        return function () {
            $(`#${tableName}`).off('length.dt');
            table.destroy()
        }

    }, [staffingList, degree])

    //HOOKS MODALS
    const showDetails = (item) => {
        setIsTouched(false)
        setCancelMode(false)
        setEditMode(false)
        setDeletedSlotCaregiver([])
        setDeletedSlotFacility([])
        setRemoveSlot([])
        setIsOpen(true)
        setSingleData(item);
    }

    const statusChangeAfterClose = (data, status) => {
        swal({
            title: "Please Note",
            text: "This shift will be marked as close and you will not be able to reverse it",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let param = {
                        status_id: status,
                        request_id: data.id,
                        send_sms: 0,
                        send_mail: 0
                    }
                    AdminStore.statusStaffingRequest(param, navigationCallBack)
                }
            })
    }

    const statusChange = (data, status) => {
        swal({
            title: "Do you want to reject this shift?",
            // text: "You have some modifications in slot that you want to really cancle.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let param = {
                        status_id: status,
                        request_id: data.id,
                        send_sms: 0,
                        send_mail: 0
                    }
                    AdminStore.statusStaffingRequest(param, navigationCallBack)
                }
            });
    }

    const statusApprove = (data, status) => {
        swal({
            title: "Do you want to approve this shift?",
            // text: "You have some modifications in slot that you want to really cancle.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let param = {
                        status_id: status,
                        request_id: data.id,
                        send_mail: 0,
                        send_sms: 0,
                    }
                    AdminStore.statusStaffingRequest(param, navigationCallBack)
                }
            });

    }

    const navigationCallBack = () => {
        AdminStore.getStaffingRequestList();
        setTCFormData();
        setIsOpenFormEditTC(false)
        setIsOpen(false)
        setIsTouched(false)
        setSingleData([])
        setEditMode(false)
        setCancelMode(false)
        setDeletedSlotCaregiver([])
        setDeletedSlotFacility([])
        setEditSlot([])
        onDismissFormRespond()
        setExtentionUser()
    }

    //SLOT EDIT API CALL 
    const slotChanges = (id) => {
        setIsOpen(false)
        if (cancelMode) {
            swal({
                content: (
                    <div>
                        <h3>Are you sure to cancel this shift?</h3>
                        <p>After this action, you can't roll back!</p>
                        <input
                            type="text"
                            placeholder="Enter the reason for cancellation..."
                            id="cancelReason"
                            className='did-floating-dropdown'
                        />
                    </div>
                ),
                buttons: {
                    cancel: {
                        text: 'Cancel',
                        value: null,
                        visible: true,
                        className: '',
                        closeModal: true,
                    },
                    confirm: {
                        text: 'OK',
                        value: true,
                        visible: true,
                        className: '',
                        closeModal: true,
                    },
                },
                icon: 'warning', // Add alert icon
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        const reason = document?.getElementById('cancelReason')?.value;
                        setIsTouched(false)
                        let param = {
                            slot_id_facility: deletedSlotFacility,
                            slot_id_caregiver: deletedSlotCaregiver,
                            slot_id_delete: removeSlot,
                            reason: reason
                        }
                        AdminStore.cancelSlot(param, navigationCallBack)
                    } else {
                        setIsOpen(true);
                    }
                });
        }
        if (editMode) {

            swal({
                title: "Do you really want to update this shift(s)?",
                text: "After an update, You can't roll back an update.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        setIsTouched(false)
                        let param = {
                            request_id: id,
                            slots: JSON.stringify(editSlot)
                        }

                        AdminStore.updateSlotData(param, navigationCallBack)
                    } else {
                        setIsOpen(true);
                    }
                });
        }
    }

    const onDismiss = () => {
        setIsOpen(false)
        if (isTouched) {
            swal({
                title: "Are you sure?",
                text: "You have some modifications in shift that you want to really cancel!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {

                        setIsTouched(false)
                    } else {
                        setIsOpen(true)
                    }
                });
        } else {
            setIsOpen(false)
        }
    }

    const deleteSlots = (id, type) => {
        setIsTouched(true)
        // Check if the element already exists in the array
        if (type === "facility") {
            const elementExists = deletedSlotFacility.includes(id);
            // Remove the existing element if it exists, and add the new element to the array
            const updatedArray = elementExists
                ? deletedSlotFacility.filter(item => item !== id)
                : deletedSlotFacility.concat(id);
            // Update the state variable with the updated array
            setDeletedSlotFacility(updatedArray);
        }
        if (type === "caregiver") {
            const elementExists = deletedSlotCaregiver.includes(id);
            // Remove the existing element if it exists, and add the new element to the array
            const updatedArray = elementExists
                ? deletedSlotCaregiver.filter(item => item !== id)
                : deletedSlotCaregiver.concat(id);
            // Update the state variable with the updated array
            setDeletedSlotCaregiver(updatedArray);
        }
        if (type === "undo") {
            if (deletedSlotFacility.includes(id)) {
                let updatedArray = deletedSlotFacility.filter(item => item !== id)
                setDeletedSlotFacility(updatedArray);
            } else {
                let updatedArray = deletedSlotCaregiver.filter(item => item !== id)
                setDeletedSlotCaregiver(updatedArray);

            }
        }
    }

    const removeSlots = (id, type) => {
        setIsTouched(true)

        // Check if the element already exists in the array
        if (type === "remove") {
            const elementExists = removeSlot.includes(id);
            // Remove the existing element if it exists, and add the new element to the array
            const updatedArray = elementExists
                ? removeSlot.filter(item => item !== id)
                : removeSlot.concat(id);
            // Update the state variable with the updated array
            setRemoveSlot(updatedArray);
        }
        if (type === "undo-remove") {
            if (removeSlot.includes(id)) {
                let updatedArray = removeSlot.filter(item => item !== id)
                setRemoveSlot(updatedArray);
            } else {
                let updatedArray = removeSlot.filter(item => item !== id)
                setRemoveSlot(updatedArray);

            }
        }
    }

    const updateSlots = (slot, start, end, type, date) => {

        setIsTouched(true)
        let newSlot = { "id": slot?.id, "staff_id": slot?.staff_id, "slot_status": slot?.status, "date": date === null ? editSlot?.find(s => s.id === slot.id)?.slot_date : moment(date).format("MM/DD/YYYY"), "time": [start === null ? editSlot?.find(s => s.id === slot.id)?.time[0] || slot.slot_start_time : start, end === null ? editSlot?.find(s => s.id === slot.id)?.time[1] || slot.slot_end_time : end], "slot_type": type === null ? editSlot?.find(s => s.id === slot.id)?.slot_type || slot.slot_type : type };
        // Check if a slot with the same id and time already exists in editSlot
        const duplicateSlotIndex = editSlot.findIndex(s => s.id === newSlot.id);
        if (duplicateSlotIndex !== -1) {
            // A duplicate slot already exists, replace it with the new slot
            const updatedEditSlot = [...editSlot];
            updatedEditSlot[duplicateSlotIndex] = newSlot;
            setEditSlot(updatedEditSlot);
            return;
        } else {
            setEditSlot([...editSlot, newSlot]);
            return;
        }
    }

    const onDismissForm = () => {
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
        setIsOpenForm(false)
        setFormData()
        setValues([])
        setDates([])
        setAllDates([])
        setDisabledSlots([])
    }

    const onOpenForm = (item) => {
        setFormData(item)
        setIsOpenForm(true)
    }
    const onOpenFormForTC = (item) => {
        setTCFormData(item)
        setIsOpenFormTC(true)
    }
    const onDismissFormRespond = () => {
        setExtentionModal(false)
        setExtentionUser([])
    }
    const onOpenFormRespond = (item) => {
        setExtentionUser(item)
        setExtentionModal(true)
    }

    const onClipboard = async (signleData, url) => {
        try {
            if (!document.hasFocus()) {
                window.focus();
            }
            navigator.clipboard.writeText(`${signleData?.user?.first_name} | ${degree?.find(x => x.Id == signleData?.profession)?.Name} | ${signleData?.user?.organization_name} ${signleData?.rating ? "\n| Rating :" + signleData?.rating + "/5" : ''} ${signleData?.feedback ? "\n| Feedback :" + signleData?.feedback : ''} \nVisit Us Here - https://staffers.us`).then(() => {
                onDismiss()
                swal({
                    title: "Feedback copied on clipboard!",
                    text: "Please paste this on your post",
                    icon: "success",
                    buttons: true,
                    dangerMode: true,
                    buttons: ["Cancel", "Share"],
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            setIsOpen(true)
                            window.open(url, '_blank');
                        } else {
                            setIsOpen(true)
                        }
                    });

            })
        } catch (error) {
            console.log('Failed to copy to clipboard: ', error);
        }
    }

    const onCopy = async (signleData) => {
        try {
            if (!document.hasFocus()) {
                window.focus();
            }
            navigator.clipboard.writeText(`${signleData?.user?.first_name} | ${degree?.find(x => x.Id == signleData?.profession)?.Name} | ${signleData?.user?.organization_name} ${signleData?.rating ? "\n| Rating :" + signleData?.rating + "/5" : ''} ${signleData?.feedback ? "\n| Feedback :" + signleData?.feedback : ''} \nVisit Us Here - https://staffers.us`).then(() => {
                setCopy(true)
                setTimeout(function () {
                    setCopy(false)
                }.bind(this), 1000);
            })
        } catch (error) {
            console.log('Failed to copy to clipboard: ', error);
        }
    }
    //FOR Shift
    const onExtendStatus = (values) => {

        let finalslot = values?.tenure?.map((x, index) => {
            const copiedObjects = Array.from({ length: values.allqty[index] }, () => Object.assign({}, { "date": x.format(), "time": values.alltime[index] ? values.alltime[index] : values.alltime[0], "remark": values?.allcomment ? values?.allcomment[index] || '' : '', "slot_type": values?.alltype ? values?.alltype[index] || '' : '' }));
            return copiedObjects
        })
        finalslot?.forEach((obj, index) => {
            obj.ind = index + 1;
        });
        if (formData?.assignments?.length !== 0) {
            const conflictingEntries = checkConflicts(finalslot, formData?.assignments);
            if (conflictingEntries?.length !== 0) {
                setDisabledSlots(conflictingEntries)
                toast.warn("Some shifts have already been allocated to you!")
                return
            }
        }

        finalslot = [].concat(...finalslot);
        let check = finalslot.some((obj) => !Object.values(obj).every((val) => val !== undefined));
        if (check) {
            toast.warn("Please select date & time!")
        } else {
            setDisabledSlots([])
            let param = {
                status_id: 4,
                request_id: values.request_id,
                send_mail: 0,
                send_sms: 0,
                slots: JSON.stringify(finalslot)
            }
            AdminStore.statusStaffingRequest(param, navigationCallBackExtend)
        }
    }
    //Order extention approve
    const orderExtentionAppRej = (shiftsData, type) => {
        let shifts;
        if (type === 'approve') {
            shifts = shiftsData?.map((slot) => {
                return { "id": slot?.id, "staff_id": slot?.staff_id, "slot_status": 1, "date": slot?.slot_date, "time": [slot?.slot_start_time, slot?.slot_end_time], "slot_type": slot.slot_type };
            })
        } else {
            shifts = shiftsData?.map((slot) => {
                return { "id": slot?.id, "staff_id": slot?.staff_id, "slot_status": 3, "date": slot?.slot_date, "time": [slot?.slot_start_time, slot?.slot_end_time], "slot_type": slot.slot_type };
            })
        }
        let param = {
            request_id: extentionUser?.id,
            slots: JSON.stringify(shifts)
        }
        AdminStore.updateSlotData(param, navigationCallBack)
    }

    const navigationCallBackExtend = () => {
        AdminStore.getStaffingRequestList();
        onDismissForm();
        setFormData();
        setDisabledSlots();
        onDismissFormRespond()
    }
    //FOR TC
    const onExtendTCStatus = (values) => {
        AdminStore.extendContract(values, navigationCallBackExtendTC)
    }
    const navigationCallBackExtendTC = () => {
        swal("Success!", "Your extension of contract request was successfully submitted.", "success");
        AdminStore.getStaffingRequestList();
        setTCFormData()
        setIsOpenFormTC(false)
    }
    const handlePaginationLengthChange = (newPageSize) => {
        setPaginationLength(newPageSize)
        AdminStore.data.pageSize = newPageSize;
        AdminStore.getStaffingRequestList();
    };

    const handleChange = (event) => {
        setInputSearch(event.target.value);
        AdminStore.data.search = event.target.value;
    };

    const onSearch = () => {
        AdminStore.getStaffingRequestList();
    }
    const onClearSearch = () => {
        setInputSearch('');
        AdminStore.data.search = '';
        AdminStore.getStaffingRequestList();
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onSearch();
        }
    };

    const getAllStaffingRequest = () => {
        AdminStore.toggleLoading(true)
        AdminStore.getAllStaffingRequestList().then(data => {
            onExportAllShift(data)
        })
    }
    const exportShift = (shift) => {
        let checking = shift?.assignments?.filter(data => [2, 5, 6, 7].includes(data.status) && data.staff_id !== null && data.is_exported === 0)
        if (checking?.length === 0) {
            toast.warn("This shift has no outstanding export slots.")
            return
        }
        swal({
            title: "Please Note",
            text: "This shift's will be marked as exported if you export it.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    var finalSpeCode = '';
                    const jsonForExcel = checking?.map(slt => {
                        var staffProf = (slt?.job_profile?.degree_specialities && slt?.job_profile?.degree_specialities?.length !== 0) ? JSON.parse(slt?.job_profile?.degree_specialities) : null;
                        if (Array.isArray(staffProf)) {
                            if (shift?.profession == 13 || shift?.profession == 1) {
                                let caregiverSProfs = staffProf?.filter(dg => dg.profession == 13 || dg.profession == 1);
                                let caregiverSpec = caregiverSProfs?.find(x => x.specialties == shift.specialty);
                                if (caregiverSpec) {
                                    finalSpeCode = specialtieJson?.find(x => x.specialty_id == caregiverSpec?.specialties)?.specialty_code
                                } else {
                                    finalSpeCode = specialtieJson?.find(x => x.specialty_id == caregiverSProfs[0]?.specialties)?.specialty_code
                                }
                            } else if (shift?.profession == 17 || shift?.profession == 22) {
                                let caregiverSProfs = staffProf?.filter(dg => dg.profession == 17 || dg.profession == 22);
                                let caregiverSpec = caregiverSProfs?.find(x => x.specialties == shift.specialty);
                                if (caregiverSpec) {
                                    finalSpeCode = specialtieJson?.find(x => x.specialty_id == caregiverSpec?.specialties)?.specialty_code
                                } else {
                                    finalSpeCode = specialtieJson?.find(x => x.specialty_id == caregiverSProfs[0]?.specialties)?.specialty_code
                                }
                            } else {
                                finalSpeCode = specialtieJson?.find(x => x.specialty_id == (shift?.specialty || shift?.profession))?.specialty_code
                            }
                        } else if (typeof staffProf === 'string') {
                            finalSpeCode = specialtieJson?.find(x => x.specialty_id == (shift?.specialty || shift?.profession))?.specialty_code
                        } else {
                            finalSpeCode = specialtieJson?.find(x => x.specialty_id == (shift?.specialty || shift?.profession))?.specialty_code
                        }
                        return (
                            {
                                FacilityCode: shift?.user?.facility_code,
                                UnitCode: shift?.user?.unit_code,
                                SpecialtyCode: finalSpeCode,
                                EmployeeCode: slt?.job_profile?.external_id || "",
                                EmployeeName: slt?.staff?.first_name + " " + slt?.staff?.last_name || "",
                                Category: shift?.category,
                                ShiftType: slt?.slot_type,
                                StartDate: slt?.slot_date,
                                FinishDate: "",
                                StartTime: slt?.slot_start_time,
                                FinishTime: slt?.slot_end_time,
                                Lunch: "",
                                Comment: slt?.remark,
                                OverTimeFlag: "",
                                OnCallFlag: "",
                                ChargeFlag: "",
                                CallBackFlag: "",
                                PoolFlag: "",
                                PatientCode: "",
                                ShiftNumber: "SLOTID" + slt?.id,
                                FacilityConfirmFlag: "",
                                ConfirmDate: "",
                                CaregiverCancelFlag: slt?.status === 5 ? 1 : 0,
                                FacilityCancelFlag: slt?.status === 6 ? 1 : 0,
                                CancelDate: slt?.cancelled_date ? moment(slt?.cancelled_date).format('MM/DD/YYYY') : "",
                                FacilityReturnFlag: "",
                                FacilityFilledFlag: "",
                                FacilityFilledDate: "",
                                ConfirmedByCaregiver: "",
                                SplitShiftsByDate: "",
                                AlternateSpecialtyCodeList: "",
                            }
                        )
                    })

                    if (jsonForExcel?.length === 0 || jsonForExcel?.every((element) => element === undefined)) {
                        toast.warn("There is no pending shift assignment.");
                        return
                    }
                    const flattenedArray = [].concat(...jsonForExcel?.filter((item) => item !== undefined));
                    downloadExcelFile(flattenedArray, shift.id + '_' + shift?.user?.organization_name?.replace(' ', '_') + '_' +
                        (today.getMonth() + 1) + '/' + today.getDate().toString() + '/' + today.getFullYear() + '_' + today.getHours() + ":"
                        + today.getMinutes() + '.csv', checking);
                } else {
                    toast.warn("There is no pending shift assignment.");
                }
            });
    };//SINGLE EXPORT


    const onExportAllShift = (data) => {

        let slotPerShift = data?.map(sft => {
            var finalSpeCode = '';
            const jsonForExcel = sft?.unexported_assignments.map(slt => {
                var staffProf = (slt?.job_profile?.degree_specialities && slt?.job_profile?.degree_specialities?.length !== 0) ? JSON.parse(slt?.job_profile?.degree_specialities) : null;
                if (Array.isArray(staffProf)) {
                    if (sft?.profession == 13 || sft?.profession == 1) {
                        let caregiverSProfs = staffProf?.filter(dg => dg.profession == 13 || dg.profession == 1);
                        let caregiverSpec = caregiverSProfs?.find(x => x.specialties == sft.specialty);
                        if (caregiverSpec) {
                            finalSpeCode = specialtieJson?.find(x => x.specialty_id == caregiverSpec?.specialties)?.specialty_code
                        } else {
                            finalSpeCode = specialtieJson?.find(x => x.specialty_id == caregiverSProfs[0]?.specialties)?.specialty_code
                            console.log("shift not match caregiver professions");
                        }
                    } else if (sft?.profession == 17 || sft?.profession == 22) {
                        let caregiverSProfs = staffProf?.filter(dg => dg.profession == 17 || dg.profession == 22);
                        let caregiverSpec = caregiverSProfs?.find(x => x.specialties == sft.specialty);
                        if (caregiverSpec) {
                            finalSpeCode = specialtieJson?.find(x => x.specialty_id == caregiverSpec?.specialties)?.specialty_code
                        } else {
                            finalSpeCode = specialtieJson?.find(x => x.specialty_id == caregiverSProfs[0]?.specialties)?.specialty_code
                            console.log("shift not match caregiver professions", caregiverSProfs[0]);
                        }
                    } else {
                        finalSpeCode = specialtieJson?.find(x => x.specialty_id == (sft?.specialty || sft?.profession))?.specialty_code
                        console.log("others");
                    }
                } else if (typeof staffProf === 'string') {
                    finalSpeCode = specialtieJson?.find(x => x.specialty_id == (sft?.specialty || sft?.profession))?.specialty_code
                    console.log(sft?.profession, "not match to any caregiver");
                } else {
                    finalSpeCode = specialtieJson?.find(x => x.specialty_id == (sft?.specialty || sft?.profession))?.specialty_code
                    console.log(sft?.profession, "not match to any caregiver");
                }
                return (
                    {
                        FacilityCode: sft?.facility?.facility_code,
                        UnitCode: sft?.facility?.unit_code,
                        SpecialtyCode: finalSpeCode,
                        EmployeeCode: slt?.job_profile?.external_id || "",
                        EmployeeName: slt?.staff?.first_name + " " + slt?.staff?.last_name || "",
                        Category: sft?.category,
                        ShiftType: slt?.slot_type,
                        StartDate: slt?.slot_date,
                        FinishDate: "",
                        StartTime: slt?.slot_start_time,
                        FinishTime: slt?.slot_end_time,
                        Lunch: "",
                        Comment: slt?.remark,
                        OverTimeFlag: "",
                        OnCallFlag: "",
                        ChargeFlag: "",
                        CallBackFlag: "",
                        PoolFlag: "",
                        PatientCode: "",
                        ShiftNumber: "SLOTID" + slt?.id,
                        FacilityConfirmFlag: "",
                        ConfirmDate: "",
                        CaregiverCancelFlag: slt?.status === 5 ? 1 : 0,
                        FacilityCancelFlag: slt?.status === 6 ? 1 : 0,
                        CancelDate: slt?.cancelled_date ? moment(slt?.cancelled_date).format('MM/DD/YYYY') : "",
                        FacilityReturnFlag: "",
                        FacilityFilledFlag: "",
                        FacilityFilledDate: "",
                        ConfirmedByCaregiver: "",
                        SplitShiftsByDate: "",
                        AlternateSpecialtyCodeList: "",
                    }
                )
            })
            return jsonForExcel
        })
        if (slotPerShift?.length === 0 || slotPerShift?.every((element) => element === undefined)) {
            toast.warn("There is no pending slot assignment.");
            return
        }
        const flattenedArray = [].concat(...slotPerShift?.filter((item) => item !== undefined));
        const unExportAssignList = data?.map(x => x?.unexported_assignments)
        const unExportAssign = [].concat(...unExportAssignList)
        downloadExcelFile(flattenedArray, (today.getMonth() + 1) + '_' + today.getDate().toString() + '_' + today.getFullYear() + '_' + today.getHours() + "_"
            + today.getMinutes() + '.csv', unExportAssign);

    }
    function downloadExcelFile(workbook, fileName, slotIds) {
        const csv = Papa.unparse(workbook, {
            delimiter: delimiter,
        });

        exportFileData(fileName, csv, slotIds?.map(slt => slt.id))
    }
    const exportFileData = (fileName, csv, slotIds) => {
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        setExcelData({ "file_name": fileName, "csv": blob, "slot_ids": slotIds })
        setIsOpenExcelDownload(true);
    }

    const exportStatusChange = () => {
        let param = {
            assignment_ids: excelData?.slot_ids
        }
        AdminStore.changeExportedStatus(param, excelData?.file_name, excelData?.csv, navigationCallBackExport)
    }

    const onDismissExport = () => {
        setIsOpenExcelDownload();
        setExcelData()
    }
    const navigationCallBackExport = (fileName, csv) => {
        const url = URL.createObjectURL(csv);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        AdminStore.getStaffingRequestList();
        setExcelData();
        setIsOpenExcelDownload(false)
        const file = new File([csv], fileName, { type: "csv" });
        const formData = new FormData();
        formData.append('file', file);
        AdminStore.saveExportFile(formData)
        toast.success(`CSV successfully downloaded, please upload to bluesky.`);

    }
    const assignToOtherStaff = (slot) => {
        if (assignToStaff) {
            swal({
                title: "Do you really want to assign this caregiver?",
                text: "After an update, You can't roll back an update.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        let newSlot = [{ "id": slot?.id, "slot_type": slot?.slot_type, "staff_id": assignToStaff, "slot_status": 2, "date": slot?.slot_date, "time": [slot?.slot_start_time, slot?.slot_end_time] }];
                        let param = {
                            request_id: slot?.shift_id,
                            slots: JSON.stringify(newSlot)
                        }
                        AdminStore.updateSlotData(param, navigationCallBackAssign)
                    } else {
                    }
                });
        } else {
            toast.warn("Please select any other caregiver!")
        }
    }
    const navigationCallBackAssign = () => {
        AdminStore.getStaffingRequestList();
        setIsOpen(false)
        setOpenNewCaregiver(false)
        setSlotData()
        setAssignDate()
        setAssignToStaff()
    }

    function checkConflicts(array1, array2) {
        let conflicts = [];
        if (array2) {
            array1?.find(entry1 => {
                if (array2.find(entry2 => {
                    let entry1StartTime = parse(`${entry1[0].date} ${entry1[0].time[0]}`, 'MM/dd/yyyy HH:mm', new Date()).getTime();
                    let entry1EndTime = parse(`${entry1[0].date} ${entry1[0].time[1]}`, 'MM/dd/yyyy HH:mm', new Date()).getTime();
                    let entry2StartTime = parse(`${entry2.slot_date} ${entry2.slot_start_time}`, 'MM/dd/yyyy HH:mm', new Date()).getTime();
                    let entry2EndTime = parse(`${entry2.slot_date} ${entry2.slot_end_time}`, 'MM/dd/yyyy HH:mm', new Date()).getTime();
                    if (isBefore(entry1EndTime, entry1StartTime)) {
                        // Add one day to the end1 time
                        entry1StartTime = addDays(entry1StartTime, 1);
                    }
                    return (isBefore(entry1StartTime, entry2EndTime) && isAfter(entry1EndTime, entry2StartTime));
                }
                )) {
                    conflicts.push(entry1?.ind)
                }
            })
            return conflicts
        } else {
            return true;
        }
    }


    const onContractStatusChange = (item, type) => {
        if (type === 'Delete') {
            swal({
                title: "Do you really want to delete this contract?",
                text: "After an deletion, You can't roll back an delete.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        let param = {
                            "contract_id_delete": [item.id]
                        }
                        AdminStore.cancelContract(param, navigationCallBackContractStatusChange)
                    }
                });
        } else if (type === 'Cancel') {
            swal({
                title: "Do you really want to delete this contract?",
                text: "After an deletion, You can't roll back an delete.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        let param = {
                            "contract_id_facility": [item.id]
                        }
                        AdminStore.cancelContract(param, navigationCallBackContractStatusChange)
                    }
                });
        } else {
            console.log("");
        }

    }

    const navigationCallBackContractStatusChange = () => {
        setIsOpen(false)
        AdminStore.getStaffingRequestList();
    }

    const editTCShift = (data) => {
        setIsOpen(false)
        setIsOpenFormTC(false)
        setTCFormData(data);
        setIsOpenFormEditTC(true)
    }

    const updateContractData = (values) => {
        AdminStore.updateContractData(values, navigationCallBack)
    }

    const openCSVUpload = () => {
        if (clientList?.length === 0) {
            AdminStore.getAllClient();
        }
        setFacilityList([])
        setIsOpenCSVUpload(true)
    }
    const uploadShiftCSV = (values) => {
        values.specialty_name = specialties?.find(x => x.Id == values.specialty)?.Name
        AdminStore.uploadShiftCSV(values, navigationCallBackImport)
    }
    const navigationCallBackImport = () => {
        setIsOpenCSVUpload(false)
        AdminStore.getStaffingRequestList()
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
    }

    //ASSIGN NEW CAREGIVER START
    const assignNewCaregiver = (slot, professionID) => {
        setIsOpen(false)
        setOpenNewCaregiver(true)
        const times = [slot?.slot_start_time, slot?.slot_end_time];
        let formattedTime = [];
        times.forEach((time) => {
            formattedTime.push(moment(time, 'HH:mm').format('hh:mm A'));
        });
        setAssignTime(formattedTime.join(" TO "))
        setSlotData(slot)
        setAssignToStaff(null)
        AdminStore.getStaffByProfession({ "profession_ids": [professionID] })
    }
    const onDismissNewCaregiverModal = () => {
        setOpenNewCaregiver(false)
        setAssignTime()
        setSlotData()
    }
    //ASSIGN NEW CAREGIVER END

    const markUnexport = (slot) => {
        AdminStore.unExportSlots({ "assignment_ids[]": [slot?.id] }, navigationCallBackAssign)
    }
    function ClearButton({ setFiled }) {
        return <div>
            <button
                className="btn btn-sm btn-primary"
                type="button"
                onClick={() => {
                    setDates([])
                    setAllDates([])
                    setFiled('tenure', [])
                }}
            >
                Clear
            </button>
        </div>;
    }
    const exportHistory = () => {
        navigate('/admin/export-history');
    }
    const showEmployerProfileDetails = (user) => {
        const client = {
            id: user?.id,
            first_name: user?.first_name,
            last_name: user?.last_name,
            organization_name: user?.organization_name,
        }
        const url = `/admin/employer-shift?data=${encodeURIComponent(JSON.stringify(client))}`;
        window.open(url, '_blank');
    };
    const showEmployeeProfileDetails = (user) => {
        const caregiver = {
            id: user?.staff?.id,
            first_name: user?.staff?.first_name,
            last_name: user?.staff?.last_name,
            bluesky_staff_id: user?.staff?.bluesky_staff_id,
        }
        const url = `/admin/caregiver-shift?data=${encodeURIComponent(JSON.stringify(caregiver))}`;
        window.open(url, '_blank');
    };

    //PROFESSION CHANGE
    const openProfChangeModal = (data) => {
        setFormData(data)
        setIsOpenProfChange(true)
        HomeStore.getSpecialtiesListData(data?.profession)
    }

    const onDismissProfChange = () => {
        setIsOpenProfChange(false);
        setFormData()
    }

    const editProfession = (values) => {
        AdminStore.editProfession(values, navigationCallBackProfChange)
    }
    const navigationCallBackProfChange = () => {
        setSingleData()
        setIsOpenProfChange(false);
        setFormData()
        AdminStore.getStaffingRequestList();
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Staffers LLC | Shift List</title>
            </Helmet>
            {/* <body> */}
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="row p-3 bg-white">
                            <h3 className="col-6 col-lg-4 mb-2 mb-lg-0 page-title">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="far fa-calendar-alt"></i>
                                </span> Orders
                            </h3>

                            <div className="col-6 col-lg-2">
                                <a onClick={() => AdminStore.loading = true} href="/admin/add-shift-request" >
                                    <small className="page-title signup-link fs-4" >
                                        <span className="page-title-icon linear text-white me-2">
                                            <i className="fas fa-plus"></i>
                                        </span><strong>New Order</strong>
                                    </small>
                                </a>
                            </div>
                            <div className="col-12 mb-3 mb-lg-0 col-lg-3" onClick={openCSVUpload}>
                                <small className="page-title signup-link fs-4" >
                                    <span className="page-title-icon linear text-white me-2">
                                        <i className="fas fa-upload"></i>
                                    </span><strong>Upload Order</strong>
                                </small>
                            </div>
                            <div className="col-12 col-lg-3">
                                <CInputGroup className="">
                                    <CFormInput placeholder="Search" value={inputSearch} onChange={handleChange} onKeyPress={handleKeyPress} />
                                    {inputSearch && (
                                        <CButton onClick={onClearSearch} type="button" color="primary" variant="outline">
                                            <i className="fa fa-times" aria-hidden="true"></i>
                                        </CButton>
                                    )}
                                    <CButton onClick={onSearch} type="button" color="primary" variant="outline">
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </CButton>
                                </CInputGroup>
                            </div>
                        </div>

                        <div style={{ height: 500, paddingTop: 0 }}>
                            <div className="col-12 d-flex justify-content-end mb-2 mb-lg-0">
                                <CButton onClick={exportHistory} type="button" color="info" variant="outline" style={{ height: 80, padding: 10, marginRight: 5 }}>
                                    Export <br /> History
                                </CButton>
                                <CButton type="button" disabled={unExportedCount === 0} onClick={getAllStaffingRequest} color="primary" variant="outline" style={{ height: 80, padding: 10, marginRight: 5 }}>
                                    {/* <i className="fas fa-file-csv"></i> */}
                                    Export Shift(s) <i className="fas fa-download"></i><br />
                                    {unExportedCount > 0 ? <small >({unExportedCount}) Shift(s) ready for export</small> : <small className="text-muted">No Pending Export</small>}
                                </CButton>
                            </div>
                            <div className="table-wrapper">
                                <table className="col-12 table table-hover dt-responsive nowrap" style={{ width: "100%" }} id={tableName} ref={tableRef}></table>
                            </div>
                        </div>
                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
            </div>
            <BottomSheet open={isOpen} onDismiss={onDismiss}
                header={
                    <>
                        <div className="text-end" style={{ cursor: "pointer" }} onClick={onDismiss}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </div>
                        <h4 className="flex items-center text-xl justify-center font-bold text-primary-800">
                            ORDER REQUEST #{signleData?.id}
                        </h4>
                    </>
                }
                footer={
                    <div>
                        <div className="d-flex justify-content-between">
                            <div>
                                {(signleData?.status === 2 || signleData?.status === 3 || signleData?.status === 4) && signleData?.rating && <>
                                    <div className="d-flex">
                                        <label>
                                            Share feedback on:&nbsp;&nbsp;
                                        </label>
                                        <ul className="social-icons">
                                            <li><a onClick={() => onClipboard(signleData, "https://www.facebook.com/sharer/sharer.php?u=https://staffers.us")} href="#" ><i className="fab fa-facebook-f"></i></a></li>
                                            <li><a onClick={() => onClipboard(signleData, "https://www.linkedin.com/sharing/share-offsite/?url=https://staffers.us")} href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                            <li><a target="_blank" href={`https://twitter.com/intent/tweet?url=staffers.us&text=${signleData?.user?.first_name} , ${degree?.find(x => x.Id == signleData?.profession)?.Name} , ${signleData?.user?.organization_name} \n${signleData?.feedback ? "Feedback :" + signleData?.feedback : ''} \n${signleData?.rating ? "Rating :" + signleData?.rating + "/5" : ''} \nVisit Us Here - https://staffers.us`}><i className="fab fa-twitter"></i></a></li>
                                            <li><a target="_blank" href={`https://wa.me/?text=${signleData?.user?.first_name} |  ${degree?.find(x => x.Id == signleData?.profession)?.Name} | ${signleData?.user?.organization_name} \n${signleData?.feedback ? "Feedback :" + signleData?.feedback : ''} \n${signleData?.rating ? "Rating :" + signleData?.rating + "/5" : ''} \nVisit Us Here - https://staffers.us`}><i className="fab fa-whatsapp"></i></a></li>
                                            <li><a target="_blank" href={`https://t.me/share/url?url=staffers.us&text=${signleData?.user?.first_name} |  ${degree?.find(x => x.Id == signleData?.profession)?.Name} | ${signleData?.user?.organization_name} \n${signleData?.feedback ? "Feedback :" + signleData?.feedback : ''} \n${signleData?.rating ? "Rating :" + signleData?.rating + "/5" : ''} \nVisit Us Here - https://staffers.us`}><i className="fab fa-telegram-plane"></i></a></li>
                                            <li><a target="_blank" href={`mailto:""?&subject=Feedback&body=${signleData?.user?.first_name} | ${degree?.find(x => x.Id == signleData?.profession)?.Name} | ${signleData?.user?.organization_name} \n${signleData?.feedback ? "Feedback :" + signleData?.feedback : ''} \n${signleData?.rating ? "Rating :" + signleData?.rating + "/5" : ''} \nVisit Us Here - https://staffers.us`}><i className="fas fa-at"></i></a></li>
                                            <li>
                                                <a href="#" onClick={() => onCopy(signleData)} className="cpy"><i className="fa fa-clone"></i></a></li>
                                            {copy && <li><div className="callout top-right">Copied</div></li>}
                                        </ul>
                                    </div>
                                </>}
                            </div>
                            {((editSlot && editSlot?.length !== 0) || (removeSlot && removeSlot?.length !== 0) || ((deletedSlotFacility && deletedSlotFacility?.length != 0) || (deletedSlotCaregiver && deletedSlotCaregiver?.length != 0))) && isTouched && (signleData?.status === 0 || signleData?.status === 1 || signleData?.status === 2 || signleData?.status === 4) &&
                                <div>
                                    <CButton onClick={() => {
                                        slotChanges(signleData?.id)
                                    }} className="w-15" color="primary" >
                                        {editMode ? "Update Slots" : "Cancel/Delete Slots"}
                                    </CButton>
                                </div>
                            }
                            {(signleData?.category === 'TC') &&
                                <div>
                                    <CButton onClick={() => {
                                        editTCShift(signleData)
                                    }} color="primary">
                                        <i className="fas fa-edit"></i> Edit
                                    </CButton>
                                </div>
                            }
                        </div>
                    </div>
                }
            >
                <div style={{ height: global.config.windowHeight / 1.5, overflowY: 'scroll' }}>
                    <div className="col-xl-12 col-md-12">
                        <div className="user-card-full">
                            <div className="row m-l-0 m-r-0">
                                <div className="col-sm-12">
                                    {(signleData?.category === 'TC' && signleData?.staff_id) &&
                                        <div className="card-block mb-0 pb-0">
                                            <h6 className="m-b-20 p-b-5 b-b-default f-w-600">Assigned Caregiver Details <i className="far fa-address-card"></i></h6>
                                            <div className="row">
                                                <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Caregiver Bluesky Id</p>
                                                    <h6 className="text-success f-w-400">{signleData?.staff?.bluesky_staff_id}</h6>
                                                </div>
                                                <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Caregiver Name</p>
                                                    <h6 className="text-success text-capitalize f-w-400"> {signleData?.staff?.first_name} {signleData?.staff?.last_name}</h6>
                                                </div>
                                                <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600">Contact</p>
                                                    <h6 className="text-muted f-w-400">{signleData?.staff?.country_code} {signleData?.staff?.contact}</h6>
                                                </div>
                                                <div className="col-sm-2">
                                                    <p className="m-b-10 f-w-600 ">Email</p>
                                                    <h6 className="text-muted text-break f-w-400">{signleData?.staff?.email}</h6>
                                                </div>
                                            </div>
                                        </div>}

                                    <div className="card-block">
                                        <h6 className="m-b-20 p-b-5 b-b-default f-w-600">Facility User Details <i className="far fa-building"></i></h6>
                                        <div className="row">
                                            {signleData && signleData?.user && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Facility User Name </p>
                                                <h6 className="text-info text-break text-decoration-underline pointer" onClick={() => showEmployerProfileDetails(signleData?.user)}><string>{signleData?.user?.first_name} {signleData?.user?.last_name}</string> <i className="fas fa-eye pointer"></i></h6>

                                            </div>}
                                            {signleData && signleData?.facility && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Facility </p>
                                                <h6 className="text-info" ><string>{signleData?.facility?.organization_name}</string></h6>

                                            </div>}
                                            {signleData && signleData?.facility && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Facility Type</p>
                                                <h6 className="text-muted  f-w-400">{signleData?.facility?.organization_type}</h6>
                                            </div>}
                                            {signleData && signleData?.facility && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Facility - Unit Code</p>
                                                <h6 className="text-muted f-w-400">{signleData?.facility?.facility_code} - {signleData?.facility?.unit_code}</h6>
                                            </div>}

                                            {signleData && signleData?.facility && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Email</p>
                                                <h6 className="text-muted text-break f-w-400">{signleData?.user?.email}</h6>
                                            </div>}
                                            {signleData && signleData?.user && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Contact</p>
                                                <h6 className="text-muted f-w-400">{signleData?.user?.country_code} {signleData?.user?.contact}</h6>
                                            </div>}
                                        </div>
                                        <h6 className="m-b-20 p-b-5 pt-2 b-b-default f-w-600">Staffing Request Details <i className="far fa-calendar-alt"></i></h6>
                                        <div className="row">
                                            {signleData && signleData?.profession && <div className="col-12 col-lg-2">
                                                <p className="m-b-10 f-w-600">Profession - Specialty</p>
                                                <h6 className="text-muted f-w-400">{signleData?.specialty_name ? signleData?.specialty_name : degree?.find(x => x.Id == signleData?.profession)?.Name}</h6>
                                            </div>}
                                            {signleData && signleData?.category && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Category</p>
                                                <h6 className="text-muted f-w-400">{global.config.shiftCaregory?.find(x => x.value === signleData?.category)?.label}</h6>
                                            </div>}
                                            {signleData && signleData?.shift_type && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Shift Type</p>
                                                <h6 className="text-muted f-w-400">{signleData?.shift_type}</h6>
                                            </div>}
                                            {<div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Break Time</p>
                                                <h6 className="text-muted f-w-400">{signleData?.break_time} Min</h6>
                                            </div>}
                                            {(signleData && signleData?.address) && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Address</p>
                                                <h6 className="text-muted f-w-400">{signleData?.address}</h6>
                                            </div>}
                                            {(signleData && signleData?.state) && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">City - State</p>
                                                <h6 className="text-muted f-w-400">{signleData?.city} - {signleData?.state}</h6>
                                            </div>}

                                            {(signleData && signleData?.zipcode) && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Zip Code</p>
                                                <h6 className="text-muted f-w-400">{signleData?.zipcode}</h6>
                                            </div>}
                                            {signleData?.pay_details && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Pay Detail</p>
                                                <h6 className="text-muted f-w-400">{signleData?.pay_details}</h6>
                                            </div>}
                                            {signleData?.start_date && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Start Date To End Date</p>
                                                <h6 className="f-w-600 text-success"><i className="fa fa-calendar-alt" aria-hidden="true"></i> {signleData?.start_date} To <i className="fa fa-calendar-alt" aria-hidden="true"></i> {signleData?.end_date}</h6>
                                            </div>}
                                            {signleData?.start_time && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Start Time To End Time</p>
                                                <h6 className="f-w-600 text-success"><i className="far fa-clock"></i> {moment(signleData?.start_time, "HH:mm").format("hh:mm A")} To <i className="far fa-clock"></i> {moment(signleData?.end_time, "HH:mm").format("hh:mm A")}</h6>
                                            </div>}
                                            {signleData?.hours_per_week && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Guaranteed Hours Per Week</p>
                                                <h6 className="text-muted f-w-400">{signleData?.hours_per_week} hr/week</h6>
                                            </div>}
                                            {signleData?.no_of_weeks && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Numbers Of Weeks</p>
                                                <h6 className="text-muted f-w-400">{signleData?.no_of_weeks}</h6>
                                            </div>}
                                            {signleData?.nop && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Numbers Of Openings</p>
                                                <h6 className="text-muted f-w-400">{signleData?.nop}</h6>
                                            </div>}
                                            {signleData?.qualifications && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Qualifications</p>
                                                <h6 className="text-muted f-w-400">{signleData?.qualifications}</h6>
                                            </div>}
                                            {signleData && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Extension</p>
                                                <h6 className="text-muted f-w-400">{signleData?.extentions}</h6>
                                            </div>}

                                            {
                                                (signleData?.rating && signleData?.status === 3 && (signleData?.assignments?.reduce((sum, obj) => sum + (obj.rating || 0), 0) / signleData?.assignments?.length > 0)) &&
                                                <>
                                                    <div className="col-6 col-lg-2">
                                                        <p className="f-w-600 mb-0">Rating By Client</p>
                                                        <h6 className="text-muted f-w-400">
                                                            <div className="rate-view">
                                                                <input type="radio" id="star5" checked={(signleData?.assignments?.reduce((sum, obj) => sum + (obj.rating || 0), 0) / signleData?.assignments?.length || 0) === 5} readOnly name="rate" value="5" />
                                                                <label htmlFor="star5" title="5 Start">5 stars</label>
                                                                <input type="radio" id="star4" checked={(signleData?.assignments?.reduce((sum, obj) => sum + (obj.rating || 0), 0) / signleData?.assignments?.length || 0) === 4} readOnly name="rate" value="4" />
                                                                <label htmlFor="star4" title="4 Start">4 stars</label>
                                                                <input type="radio" id="star3" checked={(signleData?.assignments?.reduce((sum, obj) => sum + (obj.rating || 0), 0) / signleData?.assignments?.length || 0) === 3} readOnly name="rate" value="3" />
                                                                <label htmlFor="star3" title="3 Start">3 stars</label>
                                                                <input type="radio" id="star2" checked={(signleData?.assignments?.reduce((sum, obj) => sum + (obj.rating || 0), 0) / signleData?.assignments?.length || 0) === 2} readOnly name="rate" value="2" />
                                                                <label htmlFor="star2" title="2 Start">2 stars</label>
                                                                <input type="radio" id="star1" checked={(signleData?.assignments?.reduce((sum, obj) => sum + (obj.rating || 0), 0) / signleData?.assignments?.length || 0) === 1} readOnly name="rate" value="1" />
                                                                <label htmlFor="star1" title="1 Start">1 star</label>
                                                            </div></h6>
                                                    </div>
                                                    <div className="col-6 col-lg-2">
                                                        <p className="m-b-10 f-w-600">Feedback By Client</p>
                                                        <h6 className="text-muted f-w-400">{signleData?.feedback}</h6>
                                                    </div>
                                                </>
                                            }

                                            {
                                                (signleData?.status === 3 && signleData?.assignments?.reduce((sum, obj) => sum + (obj.caregiver_experience || 0), 0) / signleData?.assignments?.length > 0) &&
                                                <>
                                                    <div className="col-6 col-lg-2">
                                                        <p className="f-w-600 mb-0">Rating By Caregiver</p>
                                                        <h6 className="text-muted f-w-400">
                                                            <div className="rate-view">
                                                                <input type="radio" id="star5" checked={(signleData?.assignments?.reduce((sum, obj) => sum + (obj.caregiver_experience || 0), 0) / signleData?.assignments?.length || 0) === 5} readOnly name="rate" value="5" />
                                                                <label htmlFor="star5" title="5 Start">5 stars</label>
                                                                <input type="radio" id="star4" checked={(signleData?.assignments?.reduce((sum, obj) => sum + (obj.caregiver_experience || 0), 0) / signleData?.assignments?.length || 0) === 4} readOnly name="rate" value="4" />
                                                                <label htmlFor="star4" title="4 Start">4 stars</label>
                                                                <input type="radio" id="star3" checked={(signleData?.assignments?.reduce((sum, obj) => sum + (obj.caregiver_experience || 0), 0) / signleData?.assignments?.length || 0) === 3} readOnly name="rate" value="3" />
                                                                <label htmlFor="star3" title="3 Start">3 stars</label>
                                                                <input type="radio" id="star2" checked={(signleData?.assignments?.reduce((sum, obj) => sum + (obj.caregiver_experience || 0), 0) / signleData?.assignments?.length || 0) === 2} readOnly name="rate" value="2" />
                                                                <label htmlFor="star2" title="2 Start">2 stars</label>
                                                                <input type="radio" id="star1" checked={(signleData?.assignments?.reduce((sum, obj) => sum + (obj.caregiver_experience || 0), 0) / signleData?.assignments?.length || 0) === 1} readOnly name="rate" value="1" />
                                                                <label htmlFor="star1" title="1 Start">1 star</label>
                                                            </div></h6>
                                                    </div>
                                                </>
                                            }
                                            {signleData && (<div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Status</p>
                                                <h6 className="text-muted f-w-400">{
                                                    signleData?.status === 0 ? <span className={'text-warning'}>Pending</span>
                                                        : (signleData?.category === 'TC' && signleData?.status === 1 && signleData?.staff_response === 0 && signleData?.staff_id) ? <span className={'text-warning'}><strong>Pending By Caregiver</strong></span>
                                                            : (signleData?.status === 1 && signleData?.staff_response === 1 && signleData?.staff_id) ? <span className={'text-warning '}><strong>Pending By Client</strong></span> : signleData?.extention_request
                                                                ? <span className={'text-info'}><b>Requested</b></span>
                                                                : signleData?.status === 2 ? <span className={'text-danger'}>Rejected</span>
                                                                    : signleData?.status == 3 ? <span className={'text-dark'}>Closed</span>
                                                                        : signleData?.assignments?.length === 0 ? (signleData?.category === 'TC' && signleData?.status === 1 && !signleData?.staff_id) ? <span className={'text-danger'}>Unfilled</span> :
                                                                            (signleData?.category === 'TC' && signleData?.status === 5 && signleData.staff_id) ? <span className={'text-success'}><b>Filled</b></span> : <span>No Update</span>
                                                                            : signleData?.assignments?.some(obj => obj.status === 4 && obj.staff_id) ?
                                                                                <span className={'text-warning'}><b>Extention Pending</b></span>
                                                                                : signleData?.assignments?.filter(stat => stat.status === 1 || stat.status === 2)?.every(obj => obj.status === 2 && obj.staff_id)
                                                                                    ? <span className={'text-success'}><b>Filled</b></span>
                                                                                    : signleData?.assignments?.filter(obj => obj.status === 2)?.length > 0 || (signleData?.assignments?.some(obj => obj.status === 1 && obj.staff_id))
                                                                                        ? <span className={'text-warning'}><b>Partially Filled</b></span>
                                                                                        : signleData?.status === 1 ? <span className={'text-danger'}>Unfilled</span> : signleData?.status == 4 ? <span className={'text-success'}>Extended</span> : "No Update"}</h6>
                                            </div>)}
                                            {(signleData && signleData?.created_at) && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Shift Creation Time</p>
                                                <h6 className="text-muted f-w-400">{moment(signleData?.updated_at).fromNow(signleData?.created_at)}</h6>
                                            </div>}
                                            {signleData && signleData?.last_updated_by && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Last Updated By</p>
                                                <h6 className="text-muted f-w-400">{signleData?.last_updated_by?.first_name} {signleData?.last_updated_by?.last_name}</h6>
                                            </div>}
                                            {signleData && signleData?.last_updated_by && <div className="col-6 col-lg-2">
                                                <p className="m-b-10 f-w-600">Last Update</p>
                                                <h6 className="text-muted f-w-400">{moment(signleData?.updated_at).fromNow()}</h6>
                                            </div>}
                                            {signleData && signleData?.description && <div className="col-sm-6">
                                                <p className="m-b-10 f-w-600">Comments From Client</p>
                                                <h6 className="text-muted f-w-400">{signleData?.description}</h6>
                                            </div>}
                                            {signleData && signleData?.shift_pickup_time && <div className="col-sm-6">
                                                <p className="m-b-10 f-w-600">Contract Pickup Time</p>
                                                <h6 className="text-muted f-w-400">{moment(signleData?.shift_pickup_time).format('MM/DD/YYYY h:mm A')}</h6>
                                            </div>}

                                        </div>
                                    </div>
                                    {(signleData?.assignments?.length !== 0) && <div className="card-block">
                                        {
                                            (signleData?.status !== 2 && signleData?.status !== 3) ?
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h6 className="m-b-20 p-b-5 b-b-default f-w-600">Order Details &nbsp;&nbsp;
                                                            <CButton active={editMode} onClick={() => { setEditMode(true); setCancelMode(false); setDeletedSlotCaregiver([]), setDeletedSlotFacility([]), setRemoveSlot([]) }} type="button" color="primary" variant="outline">
                                                                <i className="fa fa-edit" aria-hidden="true"></i> Edit Mode
                                                            </CButton>
                                                            &nbsp;&nbsp;
                                                            <CButton className="mt-2 mt-lg-0" active={cancelMode} onClick={() => { setCancelMode(true); setEditMode(false); setEditSlot([]) }} type="button" color="primary" variant="outline">
                                                                <i className="fa fa-times" aria-hidden="true"></i> Cancel/Delete Mode
                                                            </CButton>
                                                            &nbsp; &nbsp;
                                                            <span className="d-inline-block mt-2">
                                                                {cancelMode ? " Here, you can cancel/delete shift(s)." : editMode ? "  Here, you can edit slot timing." : ""}
                                                            </span>
                                                        </h6>
                                                    </div>
                                                </div>
                                                : <h6 className="m-b-20 p-b-5 b-b-default f-w-600">Order Details</h6>}
                                        <div className="row">
                                            {signleData?.assignments && <div className="col-sm-12">
                                                <ul className="list-unstyled">
                                                    <div className="row">
                                                        {signleData?.assignments?.map((slot, index) =>
                                                            <div key={index + "shifts"} className="col-sm-6 col-md-6 col-lg-4 p-1">
                                                                <li className={(deletedSlotFacility?.includes(slot?.id) || deletedSlotCaregiver?.includes(slot?.id) || removeSlot?.includes(slot?.id)) || (slot?.status === 5 || slot?.status === 6) ?
                                                                    "card p-3 border border-danger" :
                                                                    (slot?.status === 1 && slot?.staff_id) ? "card p-3 border border-warning" :
                                                                        "card p-3"}>
                                                                    {(slot?.status === 1 && slot?.staff_id) && <span style={{ position: "relative" }}>
                                                                        {<span className='suffix-container-list-status' style={{ backgroundColor: "#ffc302" }}><span className="suffix-lable ">Approval Pending</span></span>}
                                                                    </span>}
                                                                    {(slot?.status === 2 && slot?.staff_id) && <span style={{ position: "relative" }}>
                                                                        {<span className='suffix-container-list-status' style={{ backgroundColor: "#22bb33" }}><span className="suffix-lable ">Assigned</span></span>}
                                                                    </span>}
                                                                    {(slot?.is_exported === 1) && <span style={{ position: "relative" }}>
                                                                        {<span className='suffix-container-list-exported' style={{ backgroundColor: "#22bb33" }}><span className="suffix-lable ">Exported <i className="fas fa-check"></i></span></span>}
                                                                    </span>}
                                                                    {slot?.slot_pickup_time && <span style={{ position: "relative" }}>
                                                                        {<span className='suffix-container-list-update'><span className="suffix-lable ">{moment(slot?.slot_pickup_time).format('MM/DD/YYYY h:mm A')}</span></span>}
                                                                    </span>}
                                                                    <span>
                                                                        <div className="row align-items-center">
                                                                            <div className='col-6 col-lg-3 mb-1'>
                                                                                <SelectField
                                                                                    id={"slot-type"}
                                                                                    label={"Shift Type"}
                                                                                    name={"slot_type"}
                                                                                    disabled={!editMode || (slot?.status === 5 || slot?.status === 3)}
                                                                                    onChange={(event) => {
                                                                                        let start = global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.start;
                                                                                        let end = global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.end;
                                                                                        var inputStart = document.getElementById("start" + slot?.id);
                                                                                        var inputEnd = document.getElementById("end" + slot?.id);
                                                                                        // Change the value of the input element
                                                                                        inputStart.value = start;
                                                                                        inputEnd.value = end;
                                                                                        updateSlots(slot, start, end, event.target.value)
                                                                                    }}
                                                                                    value={editSlot?.find(x => x.id === slot.id) ? editSlot?.find(x => x.id === slot.id)?.slot_type : slot.slot_type}
                                                                                    data={global.config.shiftType}
                                                                                    optionLabel={"name"}
                                                                                    optionValue={"value"}
                                                                                />
                                                                            </div>
                                                                            <div className="col-6 pt-1 pb-2" >

                                                                                {((editSlot?.find(x => x.id === slot.id) ? editSlot?.find(x => x.id === slot.id)?.time[0] : slot?.slot_start_time)
                                                                                    && editSlot?.find(x => x.id === slot.id) ? editSlot?.find(x => x.id === slot.id)?.time[1] : slot?.slot_end_time)
                                                                                    && <span className="text-success me-3"><i className="fas fa-business-time"></i> {calculateLunch(editSlot?.find(x => x.id === slot.id) ? editSlot?.find(x => x.id === slot.id)?.time[0] : slot?.slot_start_time, editSlot?.find(x => x.id === slot.id) ? editSlot?.find(x => x.id === slot.id)?.time[1] : slot?.slot_end_time, signleData?.break_time)}</span>}

                                                                                {
                                                                                    <>
                                                                                        {signleData?.break_time ? <span className="text-success "><i className="fas fa-clock"></i> {signleData?.break_time} Min</span> : " "}
                                                                                        <span className="text-success "><i className="fas fa-dollar-sign"></i> {(signleData?.rate * calculateTotalHr(slot?.slot_start_time, slot?.slot_end_time, signleData?.break_time)?.toFixed(2))}</span>
                                                                                    </>
                                                                                }
                                                                            </div>

                                                                        </div>
                                                                        <span className={(deletedSlotFacility?.includes(slot?.id) || deletedSlotCaregiver?.includes(slot?.id) || removeSlot?.includes(slot?.id)) || (slot?.status === 5 || slot?.status === 6) ? "deleted slot-mobile-size" : "slot-mobile-size"}>
                                                                            <span style={{ position: "relative" }}>
                                                                                <input
                                                                                    readOnly={!editMode && (slot?.status === 1)}
                                                                                    className={slot?.status === 2 ? "slot green-border" : slot?.status === 4 ? "slot warning-border" : "slot"}
                                                                                    value={editSlot?.find(x => x.id === slot.id) ? editSlot?.find(x => x.id === slot.id)?.slot_date : moment(slot?.slot_date).format("YYYY-MM-DD")}
                                                                                    type="date"
                                                                                    onChange={(e) => updateSlots(slot, null, null, null, e.target.value)}
                                                                                />

                                                                                {slot?.is_extended === 1 && <span className='suffix-container'><span className="suffix-lable ">Extended</span></span>}
                                                                            </span>
                                                                            <span style={{ position: "relative" }}>
                                                                                <input required type="time" disabled={!editMode || (slot?.status === 5 || slot?.status === 3)}
                                                                                    id={"start" + slot?.id} className={slot?.status === 2 ? "timer-filled-admin text-success border-right-0" : slot?.status === 4 ? "timer-admin warning-border" : "timer-admin"} name="start"
                                                                                    value={editSlot?.find(x => x.id === slot.id) ? editSlot?.find(x => x.id === slot.id)?.time[0] : slot?.slot_start_time}
                                                                                    onChange={(e) => updateSlots(slot, e.target.value, null, null)} />
                                                                                <span className={slot?.status === 2 ? "border-right-0 border-left-0 timer-filled-admin text-success slight-padding " : slot?.status === 4 ? "timer-admin warning-border slight-padding" : "timer-admin slight-padding"}>To</span>

                                                                                <input required type="time" disabled={!editMode || (slot?.status === 5 || slot?.status === 3)}
                                                                                    className={slot?.status === 2 ? "timer-filled text-success" : slot?.status === 4 ? "timer-admin-end warning-border" : "timer"}
                                                                                    id={"end" + slot?.id} name="end"
                                                                                    value={editSlot?.find(x => x.id === slot.id) ? editSlot?.find(x => x.id === slot.id)?.time[1] : slot?.slot_end_time}
                                                                                    onChange={(e) => updateSlots(slot, null, e.target.value, null)} />
                                                                                {slot?.status === 5 ? <span className='suffix-container-cancelled'><span className="suffix-lable ">Cancelled by caregiver</span></span> : slot?.status === 6 ? <span className='suffix-container'><span className="suffix-lable ">Cancelled by facility</span></span> : ""}
                                                                            </span>
                                                                            {(slot?.status === 1 && !slot?.staff) && <small
                                                                                className="text-primary text-decoration-underline ms-2 pointer"
                                                                                onClick={() => {
                                                                                    onCopySlot(slot)
                                                                                }}
                                                                            >Copy Shift URL <i className="far fa-copy "></i></small>}

                                                                        </span>

                                                                        {signleData?.status !== 3 && cancelMode && slot?.staff && slot?.status !== 5 && slot?.status !== 6 && (deletedSlotFacility?.includes(slot?.id) || deletedSlotCaregiver?.includes(slot?.id) ?
                                                                            <i onClick={() => deleteSlots(slot?.id, "undo")} className="fa fa-undo pointer ms-2" aria-hidden="true"></i> :
                                                                            <div className="mb-1">
                                                                                <div onClick={() => deleteSlots(slot?.id, "facility")} className="btn btn-sm btn-outline-primary ">
                                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                                        <i className="fa fa-window-close text-danger pointer" data-toggle="tooltip" data-placement="left" title="Cancel this slot?" aria-hidden="true">
                                                                                        </i>
                                                                                        <span className="ms-1"> Cancel By Facility</span>
                                                                                    </div>
                                                                                </div>
                                                                                &nbsp;
                                                                                <div onClick={() => deleteSlots(slot?.id, "caregiver")} className="btn btn-sm btn-outline-primary ">
                                                                                    <div className=" d-flex justify-content-center align-items-center">
                                                                                        <i className="fa fa-window-close text-danger pointer" data-toggle="tooltip" data-placement="left" title="Cancel this slot?" aria-hidden="true">
                                                                                        </i>
                                                                                        <span className="ms-1"> Cancel By Caregiver</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {signleData?.status !== 3 && cancelMode && !slot?.staff && (removeSlot?.includes(slot?.id) ?
                                                                            <i onClick={() => removeSlots(slot?.id, "undo-remove")} className="fa fa-undo pointer ms-1" aria-hidden="true"></i> :
                                                                            <i onClick={() => removeSlots(slot?.id, "remove")} className="fas fa-trash text-danger pointer ms-2" data-toggle="tooltip" data-placement="left" title="Remove this slot?" aria-hidden="true">
                                                                            </i>
                                                                        )}
                                                                    </span>
                                                                    <span>
                                                                        {slot?.staff?.bluesky_staff_id ? <div className="">
                                                                            <li className="list-group-item"><small className="card-text">Bluesky Id : {slot?.staff?.bluesky_staff_id} </small></li>
                                                                            <li className="list-group-item"><small className="card-text">Caregiver : <strong className="text-decoration-underline text-info pointer" onClick={() => showEmployeeProfileDetails(slot)}> {slot?.staff?.first_name} {slot?.staff?.last_name} <i className="fas fa-eye"></i></strong></small></li>
                                                                            <li className="list-group-item"><small className="card-text">Contact : {slot?.staff?.country_code} {slot?.staff?.contact} </small></li>
                                                                            <li className="list-group-item"> <small className="card-text">Email : {slot?.staff?.email} </small></li>
                                                                        </div> : <div >
                                                                            <p className="mt-2"><em>No Assignment!</em></p>
                                                                        </div>}
                                                                        {slot?.remark && slot?.remark?.length > 100 ? (
                                                                            <div className="list-group-item">
                                                                                <i className="fas fa-info-circle text-primary"></i><marquee className="news-content"><small> {slot.remark}</small></marquee>
                                                                            </div>
                                                                        ) : slot?.remark && <div className={'list-group-item'}><i className="fas fa-info-circle text-primary"></i><small> {slot?.remark}</small></div>}
                                                                    </span>
                                                                    {((slot?.status === 5 || slot?.status === 6 || slot?.status === 7) && slot.is_exported === 0) ? "" : (slot?.staff?.bluesky_staff_id) ? <div className="btn btn-success btn-sm mt-1" onClick={() => assignNewCaregiver(slot, signleData?.profession)}>Change Caregiver <i className="fas fa-user-plus"></i></div> : <div className="btn btn-success btn-sm mt-1" onClick={() => assignNewCaregiver(slot, signleData?.profession)}>Assign Caregiver <i className="fas fa-user-plus"></i></div>}
                                                                    {(slot.is_exported === 1) && <div className="btn btn-info btn-sm mt-1" onClick={() => markUnexport(slot)}>Un-export Shift <i className="fas fa-undo"></i></div>}
                                                                </li>
                                                                &nbsp;
                                                                &nbsp;
                                                            </div>
                                                        )}
                                                    </div>
                                                </ul>
                                            </div>}
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BottomSheet>
            {/* ASIGGNED NEW AREGIVERS MODAL */}
            <CModal size="lg" visible={openNewCaregiver} backdrop="static" onClose={onDismissNewCaregiverModal}>
                <CModalHeader >
                    <CModalTitle>Assign Caregiver?
                    </CModalTitle>
                </CModalHeader>
                <CModalBody >
                    <CTable responsive >
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell scope="col">shift Type</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Date</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Time</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Comment</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Select Assign Caregiver</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            <CTableRow >
                                <CTableDataCell><span >{slotData?.slot_type}</span></CTableDataCell>
                                <CTableDataCell><i className="far fa-calendar-alt"></i> {slotData?.slot_date}</CTableDataCell>
                                <CTableDataCell> <i className="far fa-clock"></i> {assignTime}</CTableDataCell>
                                <CTableDataCell>{slotData?.remark}</CTableDataCell>
                                <CTableDataCell>
                                    <Select
                                        onChange={(e) => setAssignToStaff(e.staff_id)}
                                        placeholder={'Select Caregiver'}
                                        required={true}
                                        isDisabled={signleData?.status === 3 || signleData?.status === 2 || signleData?.status === 0}
                                        value={assignToStaff ? staffByProfession?.filter(({ staff_id }) => staff_id === assignToStaff) : staffByProfession?.filter(({ staff_id }) => staff_id === slotData?.staff?.id)}
                                        styles={colourStylesTitle}
                                        isSearchable={true}
                                        options={staffByProfession}
                                        getOptionLabel={(option) => `${option.first_name} ${option.last_name} (Bluesky Id: ${option.staffer_id})`}
                                        getOptionValue={(option) => option.staff_id}
                                        components={{ Control }}
                                    />
                                </CTableDataCell>
                            </CTableRow>
                        </CTableBody>

                    </CTable>
                </CModalBody>
                {/* </CModalBody> */}
                <div className="modal-footer justify-content-center">
                    <button type="button" className="btn btn-danger w-15" onClick={onDismissNewCaregiverModal} >&nbsp;Close &nbsp;</button>
                    <button type="button" className="btn btn-success w-15" disabled={assignToStaff === null} onClick={() => assignToOtherStaff(slotData)} >Update?</button>
                </div>
            </CModal>
            {/* EXTEND POP UP */}
            < CModal className="custom-modal-size" backdrop="static" visible={isOpenForm} onClose={() => onDismissForm()} >
                <CModalHeader onClose={() => onDismiss()}>
                    <CModalTitle>Extention Request For Ref.{formData?.id}</CModalTitle>
                </CModalHeader>
                <Formik
                    innerRef={formikRef}
                    initialValues={{ request_id: formData?.id, tenure: "", alldate: "", alltime: "", alltype: "", allqty: "" }}
                    onSubmit={values => onExtendStatus(values)}
                    validateOnChange={false}
                >
                    {
                        ({ handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched
                        }) =>
                        (
                            <form onSubmit={handleSubmit}>
                                <div className="modal-body p-4">
                                    <div className="row g-2">
                                        <div className="pb-2 col-sm-6">
                                            <DatePicker
                                                placeholder="Select Start & End Date"
                                                calendarPosition="bottom"
                                                fixMainPosition
                                                required
                                                format="MM/DD/YYYY"
                                                inputClass="did-floating-dropdown"
                                                multiple
                                                range
                                                rangeHover
                                                value={dates}
                                                minDate={new Date()}
                                                onChange={dateObjects => {
                                                    setDates(dateObjects)
                                                    setAllDates(dateObjects?.map((date) => getAllDatesInRange([date[0], date[1]]))?.flat())
                                                    setFieldValue('tenure', dateObjects?.map((date) => getAllDatesInRange([date[0], date[1]]))?.flat())
                                                    setDisabledSlots()
                                                }}
                                                plugins={[
                                                    weekends(),
                                                    <ClearButton setFiled={setFieldValue} position="bottom" />

                                                ]}
                                            />
                                        </div>
                                        <div>
                                            {allDates?.length > 0 &&
                                                <div>
                                                    <div className="d-flex justify-content-start mt-2 mb-2">Shifts:</div>
                                                    <ul className='list-unstyled'>
                                                        <FieldArray name="tenure">
                                                            {({ push, remove, form }) => (
                                                                values?.tenure?.length > 0 && values?.tenure?.sort((a, b) => new Date(a) - new Date(b))?.map((date, index) =>
                                                                    <div className={`card p-2 mb-3 ${disabledSlots?.includes(index + 1) ? "border border-2 border-danger" : "border"}`} key={index}>
                                                                        {disabledSlots?.includes(index + 1) && <span className='suffix-container mt-1'> <span className="suffix-lable">Assigned</span></span>}
                                                                        <div className="col-5 pt-1 pb-2">
                                                                            <SelectField
                                                                                id={"slot-type"}
                                                                                label={"Shift Type"}
                                                                                name={`alltype[${index}]`}
                                                                                onChange={(event) => {
                                                                                    setFieldValue(`alltype[${index}]`, event.target.value)
                                                                                    setFieldValue(`alltime[${index}]`, [global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.start, global.config.shiftTimeRange?.find(x => x.label === event.target.value)?.value?.end])
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                                value={values?.alltype && values?.alltype[index]}
                                                                                required
                                                                                data={global.config.shiftType}
                                                                                optionLabel={"name"}
                                                                                optionValue={"value"}
                                                                            />
                                                                        </div>

                                                                        <li className="d-flex align-items-center mb-2">
                                                                            <div className="border border-primary all-slot-border">
                                                                                <input className="border-0 start-date-width" id={`alldate[${index}]`} name={`alldate[${index}]`} value={date?.format()} />
                                                                                <Field id={`alltime[${index}]`} name={`alltime[${index}]`} render={({ field, form }) => <TimeRangePickerField  {...field} {...form} firstTime={values?.alltime?.length > 0 && values?.alltime[0]} index={index} />} />
                                                                                <input
                                                                                    className="slot-quantity-width"
                                                                                    placeholder="No. Of Positions"
                                                                                    type="number"
                                                                                    required
                                                                                    name={`allqty[${index}]`}
                                                                                    onChange={(event) => {
                                                                                        setFieldValue(`allqty[${index}]`, event.target.value)
                                                                                    }}
                                                                                    onBlur={handleBlur}
                                                                                    value={values?.allqty && values?.allqty[index]}
                                                                                    min="1"
                                                                                />
                                                                            </div>


                                                                            <div className="btn position-absolute top-0 end-0" onClick={() => {
                                                                                setAllDates([
                                                                                    ...allDates.slice(0, index),
                                                                                    ...allDates.slice(index + 1)
                                                                                ]);
                                                                                setDates([
                                                                                    ...allDates.slice(0, index),
                                                                                    ...allDates.slice(index + 1)
                                                                                ]);
                                                                                (values?.alltime?.length !== 0 && values?.alltime?.length >= index) && values?.alltime?.splice(index, 1);
                                                                                (values?.allqty?.length !== 0 && values?.allqty?.length >= index) && values?.allqty?.splice(index, 1);
                                                                                (values?.alltype?.length !== 0 && values?.alltype?.length >= index) && values?.alltype?.splice(index, 1);
                                                                                remove(index)
                                                                                setDisabledSlots(disabledSlots?.length > 0 ? [...disabledSlots.slice(0, index), ...disabledSlots.slice(index + 1)] : []);

                                                                            }}>
                                                                                <i className="fas fa-times-circle text-danger" aria-hidden="true"></i>
                                                                            </div>
                                                                            <div className="btn" onClick={() => {
                                                                                const elementToCopy = values.tenure[index];
                                                                                const elementToCopyTime = [undefined, undefined];
                                                                                setFieldValue("tenure", [...values?.tenure, elementToCopy]);
                                                                                (values?.alltime && values?.alltime?.length !== 0 && values?.alltime?.length >= index) && values?.alltime?.splice(index + 1, 0, elementToCopyTime);
                                                                                (values?.allqty && values?.allqty?.length !== 0 && values?.allqty?.length >= index) && values?.allqty?.splice(index + 1, 0, values?.allqty[0]);
                                                                                (values?.alltype && values?.alltype?.length !== 0 && values?.alltype?.length >= index) && values?.alltype?.splice(index + 1, 0, values?.alltype[0]);
                                                                                setAllDates([...allDates, elementToCopy])
                                                                                setDates([...allDates, elementToCopy])
                                                                            }}>
                                                                                <div className="btn btn-outline-success" style={{ padding: " 1px 5px 1px 5px" }}><i className="fa fa-plus" aria-hidden="true"></i></div>
                                                                            </div>
                                                                            {values.tenure?.length > 0 &&
                                                                                <div className="btn btn-outline-success" style={{ padding: " 1px 5px 1px 5px" }}>
                                                                                    <i className="far fa-copy pointer" onClick={() => {
                                                                                        if (values?.alltime[index]?.length === 2 && !values?.alltime[index]?.some(item => item === null || item === undefined)) {
                                                                                            values?.tenure?.map((item, ind) => {
                                                                                                setFieldValue(`alltime[${ind}]`, values?.alltime[index]);
                                                                                                if (values?.allqty?.length && (values?.allqty[index] !== null || values?.allqty[index] !== undefined)) {
                                                                                                    setFieldValue(`allqty[${ind}]`, values?.allqty[index])
                                                                                                }
                                                                                                if (values?.alltype?.length && (values?.alltype[index] !== null || values?.alltype[index] !== undefined)) {
                                                                                                    setFieldValue(`alltype[${ind}]`, values?.alltype[index])
                                                                                                }
                                                                                            })
                                                                                        } else {
                                                                                            toast.warn("Please select start end time")
                                                                                        }
                                                                                    }} data-toggle="tooltip" data-placement="right" title="Copy to all"></i></div>}
                                                                        </li>
                                                                        <div className="col-12">
                                                                            <InputField
                                                                                className={"did-floating-input"}
                                                                                type="text"
                                                                                name={`allcomment[${index}]`}
                                                                                onChange={(event) => {
                                                                                    setFieldValue(`allcomment[${index}]`, event.target.value)
                                                                                }}
                                                                                label={"Comments For Caregiver"}
                                                                                value={values?.allcomment && values?.allcomment[index]}
                                                                                onBlur={handleBlur}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                        </FieldArray>
                                                    </ul>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button type="submit" className="btn btn-primary w-25">Submit</button>
                                </div>
                            </form>
                        )
                    }
                </Formik>
            </CModal >
            {/* EXTEND RESPOND POP UP */}
            <CModal visible={extentionModal} backdrop="static" onClose={() => onDismissFormRespond()}>
                <CModalHeader onClose={() => onDismissFormRespond()}>
                    <CModalTitle>{extentionUser?.user?.organization_name} Request For #{extentionUser?.id} Order Extention  </CModalTitle>
                </CModalHeader>
                {/* <CModalBody> */}
                <div className="card-block">
                    <div className="row">
                        {extentionUser?.assignments?.length > 0 && <div className="col-sm-12">
                            <ul className="list-unstyled">
                                <div className="row mb-1">
                                    {extentionUser?.assignments?.filter(x => x.status === 4)?.map((slot, index) =>
                                        <div key={index + "shifts"} className="col-sm-12 col-md-12 col-lg-12">
                                            <li className="mb-1">
                                                <span className={""}>
                                                    <input className={"slot"} readOnly value={slot?.slot_date} />
                                                    <TimeRangePicker
                                                        value={[slot?.slot_start_time, slot?.slot_end_time]}
                                                        disabled={true}
                                                        className={"timer"}
                                                        rangeDivider="To"
                                                        clearIcon={null}
                                                        required
                                                        disableClock={true}
                                                    />
                                                </span>
                                            </li>
                                        </div>
                                    )}
                                </div>
                            </ul>
                        </div>}
                    </div>
                </div>
                <div className="modal-footer justify-content-center">
                    <button type="button" className="btn btn-danger w-25" disabled={extentionUser?.assignments?.filter(x => x.status === 4)?.length === 0} onClick={() => orderExtentionAppRej(extentionUser?.assignments?.filter(x => x.status === 4), 'reject')}>Reject?</button>
                    <button type="button" className="btn btn-success w-25" disabled={extentionUser?.assignments?.filter(x => x.status === 4)?.length === 0} onClick={() => orderExtentionAppRej(extentionUser?.assignments?.filter(x => x.status === 4), 'approve')}>Approve?</button>
                </div>
                {/* </CModalBody> */}
            </CModal>
            {/* EXTEND POP UP FOR CONTACT TC*/}
            < CModal className="custom-modal-size" backdrop="static" visible={isOpenFormTC} onClose={() => setIsOpenFormTC(false)} >
                <CModalHeader onClose={() => setIsOpenFormTC(false)}>
                    <CModalTitle>Select Contract</CModalTitle>
                </CModalHeader>
                <Formik
                    innerRef={formikRef}
                    initialValues={{ request_id: tcFormDataTC?.id, end_date: "" }}
                    onSubmit={values => onExtendTCStatus(values)}
                >
                    {
                        ({ handleChange, handleBlur, handleSubmit, values, errors, touched
                        }) =>
                        (
                            <form onSubmit={handleSubmit}>
                                <div className="modal-body p-4">
                                    <div className='row'>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="date"
                                                name="start_date"
                                                label={"Start Date"}
                                                value={tcFormDataTC.end_date}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="date"
                                                name="end_date"
                                                label={"End Date"}
                                                value={values.end_date}
                                                min={tcFormDataTC.end_date}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                            />
                                            {errors.end_date && touched.end_date ? (
                                                <div className="error-message">{errors.end_date}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-center">
                                    <button type="submit" className="btn btn-primary w-25">Submit</button>
                                </div>
                            </form>
                        )
                    }
                </Formik>
            </CModal>
            {/* EDIT POP UP FOR CONTACT TC*/}
            < CModal className="custom-modal-size" backdrop="static" visible={isOpenFormEditTC} onClose={() => { setIsOpenFormEditTC(false) }} >
                <CModalHeader onClose={() => { setIsOpenFormEditTC(false) }}>
                    <CModalTitle>Edit Contract Ref No. {tcFormDataTC?.id}</CModalTitle>
                </CModalHeader>
                <Formik
                    innerRef={formikRef}
                    initialValues={{
                        request_id: tcFormDataTC?.id,
                        profession: tcFormDataTC?.profession || "",
                        specialty_name: tcFormDataTC?.specialty_name || "",
                        hours_per_week: tcFormDataTC?.hours_per_week || "",
                        no_of_weeks: tcFormDataTC?.no_of_weeks || "",
                        pay_details: tcFormDataTC?.pay_details || "",
                        start_date: tcFormDataTC?.start_date || "",
                        end_date: tcFormDataTC?.end_date || "",
                        start_time: tcFormDataTC?.start_time || "",
                        end_time: tcFormDataTC?.end_time || "",
                        qualifications: tcFormDataTC?.qualifications || ""
                    }}
                    onSubmit={values => updateContractData(values)}
                    validationSchema={UpdateTCSchema}
                    enableReinitialize={true}
                    validateOnChange={false}
                >
                    {
                        ({ handleChange, handleBlur, handleSubmit, values, errors, touched
                        }) =>
                        (
                            <form onSubmit={handleSubmit}>
                                <div className="modal-body p-4">
                                    <div className="row g-2">
                                        <div className="col-sm-6">
                                            <div className="did-floating-label-content">
                                                <select className="did-floating-dropdown"
                                                    name="profession"
                                                    value={values.profession}
                                                    disabled
                                                >
                                                    <option
                                                        value=""
                                                    >
                                                    </option>
                                                    {degree && degree?.map((option, index) => (
                                                        <option
                                                            key={index}
                                                            value={option.Id}
                                                        >
                                                            {option.Name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label className={values.profession == "" ? "did-floating-label did-floating-label-down did-floating-labe-astrisk" : "did-floating-label did-floating-label-top did-floating-labe-astrisk"}>Profession</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                type="text"
                                                label={"Specialty"}
                                                value={values.specialty_name}
                                                disabled={true}
                                            />
                                        </div>

                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="date"
                                                name="start_date"
                                                label={"Start Date"}
                                                value={values.start_date}
                                                min={new Date().toISOString().split('T')[0]}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                            />
                                            {errors.start_date && touched.start_date ? (
                                                <div className="error-message">{errors.start_date}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="date"
                                                name="end_date"
                                                label={"End Date"}
                                                value={values.end_date}
                                                min={values.start_date ? values.start_date : new Date().toISOString().split('T')[0] || values.start_date}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                            />
                                            {errors.end_date && touched.end_date ? (
                                                <div className="error-message">{errors.end_date}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="time"
                                                name="start_time"
                                                label={"Start Time"}
                                                value={values.start_time}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                            />
                                            {errors.start_time && touched.start_time ? (
                                                <div className="error-message">{errors.start_time}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="time"
                                                name="end_time"
                                                label={"End Time"}
                                                value={values.end_time}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                            />
                                            {errors.end_time && touched.end_time ? (
                                                <div className="error-message">{errors.end_time}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="text"
                                                name="pay_details"
                                                label={"Pay Detail"}
                                                value={values.pay_details}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                            />
                                            {errors.pay_detail && touched.pay_detail ? (
                                                <div className="error-message">{errors.pay_detail}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="number"
                                                name="hours_per_week"
                                                label={"Guaranteed Hours Per Week"}
                                                value={values.hours_per_week}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                                min={1}
                                            />
                                            {errors.hours_per_week && touched.hours_per_week ? (
                                                <div className="error-message">{errors.hours_per_week}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="number"
                                                name="no_of_weeks"
                                                label={"# of Weeks"}
                                                value={values.no_of_weeks}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                                min={1}
                                            />
                                            {errors.no_of_weeks && touched.no_of_weeks ? (
                                                <div className="error-message">{errors.no_of_weeks}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="text"
                                                name="qualifications"
                                                label={"Qualifications"}
                                                value={values.qualifications}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                            />
                                            {errors.qualifications && touched.qualifications ? (
                                                <div className="error-message">{errors.qualifications}</div>
                                            ) : null}
                                        </div>
                                        <div className="d-flex justify-content-center pt-1">
                                            <button className="btn btn-primary w-auto pe-5 ps-5 p-2" type="submit">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )
                    }
                </Formik>
            </CModal >
            {/* CSV UPLOAD Modal*/}
            <CModal className="custom-modal-size" backdrop="static" visible={isOpenCSVUpload} onClose={() => { setIsOpenCSVUpload(false) }} >
                <CModalHeader onClose={() => { setIsOpenCSVUpload(false) }}>
                    <CModalTitle>Upload Shift</CModalTitle>
                </CModalHeader>
                <Formik
                    initialValues={{
                        client_id: "",
                        facility_id: "",
                        shift_file: "",
                        profession: "",
                        specialty: "",
                        specialty_name: "",
                        shift_type: "",
                        comment: ""
                    }}
                    onSubmit={values => uploadShiftCSV(values)}
                    innerRef={formikRef}
                    validationSchema={shiftImportAdminSchema}
                    validateOnChange={false}
                >
                    {
                        ({ handleSubmit, setFieldValue, resetForm, handleChange, handleBlur, values, errors, touched
                        }) =>
                        (
                            <form onSubmit={handleSubmit}>
                                <div className="modal-body p-4">
                                    <div className="row ">

                                        <div className="col-sm-6 mb-2">
                                            <Select
                                                onChange={(e) => {
                                                    setFieldValue("facility_id", e.id);
                                                    AdminStore.getClientsByFacility(e.id)
                                                }}
                                                placeholder={''}
                                                label={'Select Facility'}
                                                required={true}
                                                value={facilityList?.filter(({ id }) => facility_id === values.id) || ""}
                                                styles={colourStylesState}
                                                isSearchable={true}
                                                options={facilityList}
                                                getOptionLabel={(option) => `${option.organization_name} - ${option.city}, ${option.state}`}
                                                getOptionValue={(option) => option.id}
                                                components={{ Control }}
                                            />
                                            {errors.facility_id && touched.facility_id ? (
                                                <div className="error-message">{errors.facility_id}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <Select
                                                label="Select an F.User"
                                                isLoading={clientList?.length === 0}
                                                options={clientList} // Assuming clientList is an array of objects with 'value' and 'label' properties
                                                onChange={(selectedOption) => {
                                                    setFieldValue("client_id", selectedOption?.id);
                                                }}
                                                value={clientList.find((option) => option.id === values.client_id) || null} // Set to null if no match is found
                                                isRequired={true}
                                                components={{ Control }}
                                                styles={colourStylesState} // Assuming colourStylesState is defined
                                                getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                                                getOptionValue={(option) => option.id}
                                            />
                                            {errors.client_id && touched.client_id ? (
                                                <div className="error-message">{errors.client_id}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 mb-3">
                                            <SelectField
                                                name="shift_type"
                                                label={"Category"}
                                                value={values.shift_type}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                data={[{ "label": "Per Diem", "value": "PD" }, { "label": "Local Contract", "value": "TC" }]}
                                                optionLabel={"label"}
                                                optionValue={"value"}
                                                required
                                            />
                                            {errors.shift_type && touched.shift_type ? (
                                                <div className="error-message">{errors.shift_type}</div>
                                            ) : null}</div>
                                        <div className="col-sm-6">
                                            <div className="did-floating-label-content">
                                                <select className="did-floating-dropdown"
                                                    name="profession"
                                                    value={values.profession}
                                                    required
                                                    onChange={(e) => {
                                                        if (e.target.value) {
                                                            setFieldValue('profession', e.target.value)
                                                            HomeStore.getSpecialtiesListData(e.target.value)
                                                        } else {
                                                            setSpecialties([])
                                                        }
                                                    }}
                                                >
                                                    <option
                                                        value=""
                                                    >
                                                    </option>
                                                    {degree && degree?.map((option, index) => (
                                                        <option
                                                            key={index}
                                                            value={option.Id}
                                                        >
                                                            {option.Name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label className={values.profession == "" ? "did-floating-label did-floating-label-down did-floating-labe-astrisk" : "did-floating-label did-floating-label-top did-floating-labe-astrisk"}>Profession</label>
                                            </div>
                                            {errors.profession && touched.profession ? (
                                                <div className="error-message">{errors.profession}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 pb-lg-2 pb-md-2">
                                            <SelectField
                                                name="specialty"
                                                label={HomeStore.loading ? "Loading..." : specialties?.length === 0 ? "No Specialty Found" : "Specialty"}
                                                disabled={!values.profession || HomeStore.loading}
                                                value={values.specialty}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                data={specialties}
                                                optionLabel={"Name"}
                                                optionValue={"Id"}
                                                required={specialties?.length === 0 ? false : true}
                                            />
                                        </div>

                                        <div className="d-flex justify-content-center align-items-center mb-2">
                                            {!values.shift_file ? <div className="col-sm-5 card border border-primary justify-content-center align-items-center m-2 p-3 pointer" onClick={() => document.getElementById('file').click()}>
                                                <label className="file-upload-label">
                                                    <img className="upload-image pointer" width="100" src="/upload_exl.png" alt="Upload" />
                                                </label>
                                                <input
                                                    type="file"
                                                    id="file"
                                                    name="file"
                                                    className="file-input"
                                                    onChange={(event) => {
                                                        const exfile = event.currentTarget.files[0];
                                                        // Set the file value in Formik
                                                        setFieldValue('shift_file', exfile);
                                                    }}
                                                    style={{ display: 'none' }}
                                                    accept=".xlsx"
                                                />
                                                <p className="card-text ">Per Diem/Local Contract</p>
                                            </div> :
                                                <div className="card border border-primary justify-content-center align-items-center m-2 p-3 pointer" onClick={() => document.getElementById('file').click()}>
                                                    <div className='d-flex'>
                                                        <small className='text-success'>{values.shift_file?.name}</small>
                                                        <i className="fas fa-times-circle text-danger ms-2 pointer" onClick={() => setFieldValue('shift_file', '')}></i></div>
                                                    <ExcelPreview file={values.shift_file} />
                                                    <input
                                                        type="file"
                                                        id="file"
                                                        name="file"
                                                        className="file-input"
                                                        onChange={(event) => {
                                                            const exfile = event.currentTarget.files[0];
                                                            // Set the file value in Formik
                                                            setFieldValue('shift_file', exfile);
                                                        }}
                                                        style={{ display: 'none' }}
                                                        accept=".xlsx, .xls"
                                                    />
                                                </div>}
                                        </div>
                                        {errors.shift_file && touched.shift_file ? (
                                            <div className="error-message">{errors.shift_file}</div>
                                        ) : null}

                                        <div className="d-flex justify-content-center pt-1">
                                            <a href="/shift_sample.xlsx" download="shift_import_sample.xlsx">
                                                <div className="btn btn-info w-auto pe-5 ps-5 p-2 me-2">Download Sample <i className="fas fa-download"></i></div>
                                            </a>
                                            <button className="btn btn-light w-auto pe-5 ps-5 p-2 me-2" type="reset" onClick={() => resetForm()}>Clear</button>
                                            <button className="btn btn-primary w-auto pe-5 ps-5 p-2" type="submit">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )
                    }
                </Formik>

            </CModal >
            {/* Excel Export View Modal*/}
            <CModal size="lg" backdrop="static" visible={isOpenExcelDownload} onClose={onDismissExport} >
                <CModalHeader onClose={onDismissExport}>
                    <CModalTitle>Are You Sure To Export?</CModalTitle>
                </CModalHeader>
                <div>
                    <ExcelPreview file={excelData?.csv} />
                </div>
                <CModalFooter>
                    <CButton
                        color="success"
                        onClick={exportStatusChange}
                    >
                        Download Excel
                    </CButton>
                    <CButton
                        color="danger"
                        onClick={onDismissExport}
                    >
                        Close
                    </CButton>
                </CModalFooter>
            </CModal >
            {/* Pofession Change Modal */}
            <CModal backdrop="static" visible={isOpenProfChange} onClose={onDismissProfChange} >
                <CModalHeader onClose={onDismissExport}>
                    <CModalTitle>Change Profession For Shift Ref.{formData?.id}?</CModalTitle>
                </CModalHeader>
                <Formik
                    initialValues={{
                        request_id: formData?.id,
                        profession: formData?.profession,
                        specialty: formData?.specialty,
                        specialty_name: formData?.specialty_name
                    }}
                    onSubmit={values => editProfession(values)}
                    enableReinitialize
                    validationSchema={
                        Yup.object().shape({
                            profession: Yup.string().required('Profession required')
                        })}
                    validateOnChange={false}
                >
                    {
                        ({ handleChange, handleBlur, handleSubmit, setFieldValue, resetForm, values, errors, touched
                        }) =>
                        (
                            <form onSubmit={handleSubmit}>
                                <div className="row g-2 modal-body p-4">
                                    <div className="col-sm-12  pb-2">
                                        <div className="did-floating-label-content">
                                            <select className="did-floating-dropdown"
                                                name="profession"
                                                value={values.profession}
                                                required
                                                onChange={(e) => {
                                                    if (e.target.value) {
                                                        setFieldValue('profession', e.target.value)
                                                        setFieldValue('specialty', '')
                                                        setFieldValue('specialty_name', '')
                                                        HomeStore.getSpecialtiesListData(e.target.value)
                                                    } else {
                                                        setSpecialties([])
                                                    }
                                                }}
                                            >
                                                <option
                                                    value=""
                                                >
                                                </option>
                                                {degree && degree?.map((option, index) => (
                                                    <option
                                                        key={index}
                                                        value={option.Id}
                                                    >
                                                        {option.Name}
                                                    </option>
                                                ))}
                                            </select>
                                            <label className={values.profession == "" ? "did-floating-label did-floating-label-down did-floating-labe-astrisk" : "did-floating-label did-floating-label-top did-floating-labe-astrisk"}>Profession</label>
                                        </div>
                                        {errors.profession && touched.profession ? (
                                            <div className="error-message">{errors.profession}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-sm-12 pb-2">
                                        <SelectField
                                            name="specialty"
                                            label={HomeStore.loading ? "Loading..." : specialties?.length === 0 ? "No Specialty Found" : "Specialty"}
                                            disabled={!values.profession || HomeStore.loading}
                                            value={values.specialty}
                                            onChange={(event) => { setFieldValue("specialty", event.target.value), setFieldValue("specialty_name", specialties?.find(x => x.Id == event.target.value)?.Name) }}
                                            onBlur={handleBlur}
                                            data={specialties}
                                            optionLabel={"Name"}
                                            optionValue={"Id"}
                                            required={specialties?.length === 0 ? false : true}
                                        />
                                    </div>
                                    <div className="d-flex justify-content-center pt-1">
                                        <button className="btn btn-primary w-auto pe-5 ps-5 p-2" type="submit">Submit</button>
                                    </div>
                                </div>
                            </form>
                        )}
                </Formik>
            </CModal>

            <Spinner isLoading={AdminStore.loading} />
        </React.Fragment>
    );
    function TimeRangePickerField(props) {
        const hnadleStart = (e) => {
            const startTime = e.target.value;
            if (props?.values?.alltime?.length === 0) {
                props.setFieldValue(props.name, [startTime, undefined]);
            } else {
                props.setFieldValue(props.name, [startTime, props?.values?.alltime[props.index]?.length === 2 ? props?.values?.alltime[props.index][1] : undefined]);
            }
        }
        const hnadleEnd = (e) => {
            const endTime = e.target.value;
            if (props?.values?.alltime?.length === 0) {
                props.setFieldValue(props.name, [undefined, endTime]);
            } else {
                if (props?.values?.alltime[props.index][0] !== endTime) {
                    props.setFieldValue(props.name, [props?.values?.alltime[props.index][0], endTime]);
                } else {
                    toast.error("The start and finish timings must not be the same!")
                }
            }
        }
        return (
            <>
                <input className="border-0" required type="time" id="start" name="start" value={props?.values?.alltime[props?.index] ? props?.values?.alltime[props?.index][0] : ''} onChange={hnadleStart} />
                <span className="slot-time-to" >To</span>
                <input className="border-0" required type="time" id="end" name="end" disabled={props?.values?.alltime[props?.index] ? props?.values?.alltime[props?.index][0] ? false : true : true} value={props?.values?.alltime[props?.index]?.length === 2 ? props?.values?.alltime[props.index][1] : ""} onChange={hnadleEnd} />
            </>
        );
    }
})

export const Control = (props) => {
    return (
        <>
            <label className={props.hasValue || props.isFocused ? "did-floating-label did-floating-label-top" : "did-floating-label did-floating-label"} style={{ zIndex: 999 }}>{props?.selectProps?.label}</label>
            <components.Control {...props} />
        </>
    );
};

export default StaffingRequestList;