/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import Header from '@components/Header';
import { Helmet } from 'react-helmet';
import swal from '@sweetalert/with-react';
import 'react-spring-bottom-sheet/dist/style.css';
import StaffsStore from "@store/StaffsStore";
import { toJS } from 'mobx';
import { observer } from "mobx-react"
import SignatureCanvas from 'react-signature-canvas'
import { toast } from 'react-toastify';
import Spinner from "@components/Spinner";
import { BASE_API_URL_FILE } from '../../api/config';
import { useMediaQuery } from 'react-responsive';
import MultiStepProgressBarOnboarding from '../../elements/MultiStepProgressBarOnboarding/MultiStepProgressBarOnboarding';
import AdministratorJobDescription from './onboardingPages/AdministratorJobDescription';
import { ArbitrationAgreementGeneral } from './onboardingPages/ArbitrationAgreementGeneral';
import { CModal, CModalHeader, CModalTitle, CButton, CModalFooter } from "@coreui/react";
import { AdministratorAgreementNV } from './onboardingPages/AdministratorAgreementNV';
import ArbitrationAgreementCA from './onboardingPages/ArbitrationAgreementCA';
import { ArbitrationAgreementHI } from './onboardingPages/ArbitrationAgreementHI';
import { ArbitrationAgreementIA } from './onboardingPages/ArbitrationAgreementIA';
import { ArbitrationAgreementOH } from './onboardingPages/ArbitrationAgreementOH';
import { ArbitrationAgreementOR } from './onboardingPages/ArbitrationAgreementOR';
import { ArbitrationAgreementUtah } from './onboardingPages/ArbitrationAgreementUtah';
import { ArbitrationAgreementWA } from './onboardingPages/ArbitrationAgreementWA';
import BhtJobDescription from './onboardingPages/BhtJobDescription';
import { BonusPayment } from './onboardingPages/BonusPayment';
import COTAJobDescription from './onboardingPages/COTAJobDescription';
import { HepatitisBVaccinationWiver } from './onboardingPages/HepatitisBVaccinationWiver';
import html2pdf from 'html2pdf.js';
import { DonJobDescription } from './onboardingPages/DonJobDescription';
import { HealthQuestionaire } from './onboardingPages/HealthQuestionaire';
import { InfoSheet } from './onboardingPages/InfoSheet';
import { JobDescriptionCertifiedCNA } from './onboardingPages/JobDescriptionCertifiedCNA';
import { JobDescriptionCertifiedCNAMed } from './onboardingPages/JobDescriptionCertifiedCNAMed';
import { JobDescriptionRegisteredNurse } from './onboardingPages/JobDescriptionRegisteredNurse';
import { JobDescriptionLicensedLPN } from './onboardingPages/JobDescriptionLicensedLPN';
import { OTJobDescription } from './onboardingPages/OTJobDescription';
import { HandbookOrientation } from './onboardingPages/HandbookOrientation';
import WABackgroundCheck from './onboardingPages/WABackgroundCheck';
import PdfViewer from '../../elements/pdfViewer/pdfViewer';
import { BackgroundCheckChecklist } from './onboardingPages/BackgroundCheckChecklist';
import { AcknowledgmentAndAuthorizationUniversal } from './onboardingPages/AcknowledgmentAndAuthorizationUniversal';
import { Reference } from './onboardingPages/Reference';
import { AbusePreventionTraining } from './onboardingPages/AbusePreventionTraining';
import { checkProgress, documentFields } from '@utils/constant/constant';
import { AbuseNeglectandDementiaTrainingNewHires } from './onboardingPages/AbuseNeglectandDementiaTrainingNewHires';
import { PolicyandProcedureAcknow } from './onboardingPages/PolicyandProcedureAcknow';
import { CertifiedNursingAssistantSkillsValidationChecklist } from './onboardingPages/CertifiedNursingAssistantSkillsValidationChecklist';
import { SkilledNursingCompetencySelfAssessment } from './onboardingPages/SkilledNursingCompetencySelfAssessment';
import FluVaccinationDeclination from './onboardingPages/FluVaccinationDeclination';

const OnboardingDocuments = observer(() => {
    const dummyReference = {
        document_id: 77,  // Change the document_id to 0
        value: 'ref',
        document_path: null,
        document_type: { key_name: "References" }
        // ... (other attributes, keep them the same or change as needed)
    };
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const formRef = useRef();
    const fileInputRef = useRef();
    window.onscroll = function () { };
    const canvasRef = useRef(null);
    const canvasRefParent = useRef(null);
    const [onboardingDocumentsList, setOnboardingDocumentsList] = useState([]);
    const [singedDocuments, setSingedDocuments] = useState([]);
    const [selectedSignature, setSelectedSignature] = useState();
    const [selectedSignatureParent, setSelectedSignatureParent] = useState();
    const [signatureUrl, setSignatureUrl] = useState(null);
    const [signatureUrlParent, setSignatureUrlParent] = useState(null);
    const [canvaSignature, setCanvaSignature] = useState();
    const [canvaSignatureParent, setCanvaSignatureParent] = useState();
    const [currentStepInner, setCurrentStepInner] = useState(0);
    const [currentDocumentID, setCurrentDocumentID] = useState();
    const [isOpenModal, setOpenModal] = useState(false);
    const [isOpenModalParent, setOpenModalParent] = useState(false);
    const [tempSignedPDF, setTempSignedPDF] = useState([]);
    const [inputValues, setInputValues] = useState(documentFields);
    const [references, setReferences] = useState([]);
    const [deleted, setDeleted] = useState([]);
    const [fluPopUp, setFluPopUp] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        StaffsStore.getReferences();
        StaffsStore.getSignedDocuments();
        navigationCallBackSaveOnboardData();
    }, [])

    useEffect(() => {
        const keyword = "Arbitration";
        let count;
        let newArray;
        //Checking signed or not here
        if (StaffsStore?.data?.signedDocumentsList?.filter(x => x.document_id !== 77)?.length > 1) {
            var signedDoc = toJS(StaffsStore?.data?.signedDocumentsList);
            let updatedSignedDoc = signedDoc?.find(x => x.document_id === 77) ? signedDoc : [...signedDoc, dummyReference];
            setSingedDocuments(updatedSignedDoc?.sort((a, b) => (a.value === "ref") ? -1 : (b.value === "ref") ? 1 : 0))

        } else {
            count = toJS(StaffsStore.data.onboardingDocumentsList).reduce((acc, obj) => acc + (obj.key_name.includes(keyword) ? 1 : 0), 0);
        }

        if (count === 2) {
            newArray = toJS(StaffsStore.data.onboardingDocumentsList).filter(obj => obj.key_name !== "Arbitration Agreement General");
            newArray = newArray?.sort((a, b) => (a.value === "ref") ? -1 : (b.value === "ref") ? 1 : 0);
            setOnboardingDocumentsList(newArray)
        } else {
            const jsonArrayWithReference = toJS(StaffsStore.data.onboardingDocumentsList)?.sort((a, b) => (a.value === "ref") ? -1 : (b.value === "ref") ? 1 : 0);
            setOnboardingDocumentsList(jsonArrayWithReference)
        }
        if (toJS(StaffsStore.data.onboardingDocumentsList)?.find(x => x.document_id == 95)) {
            showFileInputModal();
        }
        setCurrentDocumentID(toJS(StaffsStore.data.onboardingDocumentsList).find(x => x.value == 'ref')?.document_id)

    }, [StaffsStore?.data?.signedDocumentsList, StaffsStore?.data?.onboardingDocumentsList]);

    useEffect(() => {
        if (toJS(StaffsStore?.data?.signedDocumentsList)?.filter(x => x.document_id !== 77)?.length === 0) {
            StaffsStore.getOnboardingDocuments();
        } else {
            if (toJS(StaffsStore.data.signedDocumentsList)?.find(x => x.document_id == 95)) {
                setFluPopUp(true);
            }
        }
    }, [StaffsStore?.data?.signedDocumentsList]);

    useEffect(() => {
        setCurrentDocumentID(toJS(StaffsStore?.data?.signedDocumentsList)?.find(x => x.value == 'ref')?.document_id || 77)
    }, [singedDocuments]);

    useEffect(() => {
        setReferences(toJS(StaffsStore.data.referenceData))
    }, [StaffsStore.data.referenceData]);

    useEffect(() => {
        try {
            // Attempt to parse the JSON string
            let jsonObject = JSON.parse(toJS(StaffsStore.data.onBoardingData)?.data);
            setInputValues(jsonObject)
            // If parsing is successful, you can work with the jsonObject here
        } catch (error) {
            // If an error occurs during parsing, catch it here
            console.log('Error parsing JSON:', error.message);
        }
    }, [StaffsStore.data.onBoardingData]);

    const handleClearConva = () => {
        canvasRef?.current?.clear();
        setCanvaSignature('')
        setSelectedSignature(null)
        setSignatureUrl(null)
    };
    const handleClearConvaParent = () => {
        canvasRefParent?.current?.clear();
        setCanvaSignatureParent('')
        setSelectedSignatureParent(null)
        setSignatureUrlParent(null)
    };
    const handleSave = async () => {
        if (!signatureUrl) {
            if (canvasRef?.current) {
                if (canvasRef?.current?.isEmpty()) {
                    toast.error("Please upload or use the signature pad to sign documents!")
                    return
                } else {
                    const trimmedCanvas = canvasRef?.current?.getTrimmedCanvas();
                    const dataUrl = trimmedCanvas?.toDataURL();
                    const signatureData = dataUrl?.split(',')[1];
                    if (signatureData) {
                        setCanvaSignature("data:image/png;base64," + signatureData)
                        setOpenModal(false)
                        return
                    }
                }
            } else {
                toast.error("Please upload signature to sign documents!")
                return
            }

        } else {
            setOpenModal(false)
        }
    };
    const handleSaveParent = async () => {
        if (!signatureUrlParent) {
            if (canvasRefParent?.current) {
                if (canvasRefParent?.current?.isEmpty()) {
                    toast.error("Please upload or use the signature pad to sign this document!")
                    return
                } else {
                    let trimmedCanvas = canvasRefParent?.current?.getTrimmedCanvas();
                    const dataUrl = trimmedCanvas?.toDataURL();
                    const signatureData = dataUrl.split(',')[1];
                    if (signatureData) {
                        setCanvaSignatureParent("data:image/png;base64," + signatureData)
                        setOpenModalParent(false)
                        return
                    }
                }
            } else {
                toast.error("Please upload signature to sign documents!")
                return
            }

        } else {
            setOpenModalParent(false)
        }
    };

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        handleClearConva(null)
        const minSizeInBytes = 100 * 1024; // Minimum file size in bytes (100 KB)
        if (file.size > minSizeInBytes) {
            if (fileInputRef?.current) {
                fileInputRef.current.value = null;
            }
            toast.error(`Maximum file size should be less then ${minSizeInBytes} KB`)
            return
        }

        if (!event.target.value) {
            toast.error("Signature not select!")
            return
        }
        if (file.type === 'image/png') {
            setSelectedSignature(event.target.files[0]);
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setSignatureUrl(reader.result);
            };
        } else {
            toast.error('Please select a PNG file.');
        }
    };

    const handleFileInputChangeParent = (event) => {
        const file = event.target.files[0];
        handleClearConvaParent(null)
        if (!event.target.value) {
            toast.error("Signature not select!")
            return
        }
        if (file.type === 'image/png') {
            setSelectedSignatureParent(event.target.files[0]);
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setSignatureUrlParent(reader.result);
            };
        } else {
            toast.error('Please select a PNG file.');
        }
    };
    //API Call
    const saveSignedDocuments = () => {
        if (tempSignedPDF?.length !== onboardingDocumentsList?.length - 1) {
            toast.warn("Please fill out all of the data before sending the docs.")
            return
        }
        const fArray = tempSignedPDF?.map((doc, index) => {
            return { 'doc_id': doc.doc_id, "file": doc.file }
        });
        if (fArray?.length === onboardingDocumentsList?.length - 1) {
            setIsLoading(true);
            let param = {
                doc_list: fArray
            }
            StaffsStore.generateOnboardPDFs(param, navigationCallBackFinal)
        } else {
            toast.warn("Before submitting the request, kindly read the entire document.")
        }
        return true
    }

    const navigationCallBackFinal = () => {
        setStepInner(0)
        setIsLoading(false);
        swal("Submitted", "I appreciate you signing the necessary forms. This data is used to choose caregivers for upcoming positions and shift requests!", "success");
        StaffsStore.getSignedDocuments();
     
        // let param = {
        //     request_id: id
        // }
        // StaffsStore.uploadSignedDocuments(param, navigationCallBackBluesky)
    }

    // const navigationCallBackBluesky = () => {
 
    // }

    const setStepInner = (step, item) => {
        if (references?.length === 0 && singedDocuments?.length === 0) {
            toast.warn("Fill out the reference form first, then go to other documents.")
            return
        }
        if (singedDocuments?.length > 0) {
            window.scrollTo(0, 250)
            if (item) {
                setCurrentStepInner(step);
                setCurrentDocumentID(item?.document_id);
                return
            } else {
                setCurrentStepInner(currentStepInner + 1);
                setCurrentDocumentID(singedDocuments[currentStepInner + 1]?.document_id)
                return
            }
        }

        onOpenModal(null, "inner", step, item)
    }

    const setStepInnerBack = () => {
        if (singedDocuments?.length > 0) {
            window.scrollTo(0, 250)
            setCurrentStepInner(currentStepInner - 1);
            setCurrentDocumentID(singedDocuments[currentStepInner - 1]?.document_id)
        } else {
            window.scrollTo(0, 250)
            setCurrentStepInner(currentStepInner - 1);
            setCurrentDocumentID(onboardingDocumentsList[currentStepInner - 1]?.document_id)
        }

    }

    //Create PDF
    const onOpenModal = (e, type, step, selectedItem) => {
        e?.preventDefault();
        if (currentDocumentID === 77) {
            setCurrentStepInner(step);
            setCurrentDocumentID(selectedItem?.document_id)
            return
        }
        StaffsStore.toggleLoading(true);
        // Handle form submission here
        if (formRef.current.checkValidity()) {
            if ((!signatureUrl && !canvaSignature)) {
                StaffsStore.toggleLoading(false);
                setOpenModal(true)
            } else {
                const formPdf = document.getElementById('form-content')
                const existingIndex = tempSignedPDF?.findIndex(obj => obj.doc_id === currentDocumentID);
                console.log(existingIndex);
                if (existingIndex !== -1) {
                    console.log("replace");
                    tempSignedPDF[existingIndex] = { doc_id: currentDocumentID, file: formPdf?.innerHTML };
                } else {
                    tempSignedPDF.push({ doc_id: currentDocumentID, file: formPdf?.innerHTML });
                }

                saveOnboardingData();
                if (currentStepInner !== onboardingDocumentsList?.length - 1) {
                    formRef?.current?.scrollIntoView({ behavior: 'smooth' });
                }
                if (type !== "inner") {
                    if (onboardingDocumentsList?.length - 1 !== currentStepInner) {
                        setCurrentStepInner(currentStepInner + 1);
                        setCurrentDocumentID(onboardingDocumentsList[currentStepInner + 1]?.document_id)
                    } else {
                        if ((tempSignedPDF?.length === onboardingDocumentsList?.length - 1) && onboardingDocumentsList?.length !== 0) {
                            swal({
                                title: "Are you certain you want to submit onboarding documentation?",
                                icon: "warning",
                                buttons: true,
                                dangerMode: true,
                            })
                                .then((willDelete) => {
                                    if (willDelete) {
                                        saveSignedDocuments();
                                    }
                                });
                        }

                    }
                    StaffsStore.toggleLoading(false);
                } else {
                    setCurrentStepInner(step);
                    setCurrentDocumentID(selectedItem?.document_id)
                    StaffsStore.toggleLoading(false);
                }
            }
        } else {
            const validationErrors = [];
            const formElements = formRef?.current?.elements;
            for (let i = 0; i < formElements?.length; i++) {
                const element = formElements[i];
                if (!element?.checkValidity()) {
                    validationErrors?.push({ name: element.name, error: element.validationMessage });
                }
            }
            if (validationErrors?.length !== 0) {
                console.log("error", validationErrors);
                if (validationErrors[0]?.name) {
                    toast.error(`Please fill out ${validationErrors[0]?.name}`);
                } else {
                    toast.error(`${validationErrors[0]?.error}`);
                }
            }
            StaffsStore.toggleLoading(false);
            // You can also display error messages to the user here
            // Optionally, you can show some error message here
            // toast.warn("Please fill all required fields.")
            return
        }
    }

    const handleInputChange = (name, value) => {
        setInputValues(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const onDismissModal = () => {
        setOpenModal(false)
    }

    const onDismissModalParent = () => {
        setOpenModalParent(false)
    }
    const onOpenModalParent = () => {
        setOpenModalParent(true)
    }

    const saveReference = (data) => {
        const param = {
            references: JSON.stringify(data.references),
            deleted_ids: deleted
        }
        if (references?.length === 0) {
            StaffsStore.addReferences(param, navigationCallBackRefer)
        } else {
            StaffsStore.editReferences(param, navigationCallBackRefer)
        }
    }

    const navigationCallBackRefer = () => {
        StaffsStore.getReferences();
        setCurrentStepInner(currentStepInner + 1);
        setCurrentDocumentID(onboardingDocumentsList[currentStepInner + 1]?.document_id)
    }

    const onDelete = (id) => {
        if (id) {
            setDeleted((prevDeleted) => [...prevDeleted, id]);
        }
    }

    const saveOnboardingData = () => {
        StaffsStore.saveOnboardingData({ "data": JSON.stringify(inputValues) }, navigationCallBackSaveOnboardData)
        return
    }
    const navigationCallBackSaveOnboardData = () => {
        StaffsStore.getOnboardingData();
        return
    }
    // Function to handle showing the SweetAlert modal
    const showFileInputModal = () => {
        if (fluPopUp) {
            return
        }
        swal({
            title: 'Have you received a flu vaccination this season?',
            content: (
                <div>
                    <input type="file" id="fileInput" className='form-control' />
                </div>
            ),
            buttons: {
                confirm: {
                    text: 'Yes',
                    value: true,
                    visible: true,
                    className: ""
                },
                cancel: {
                    text: 'No',
                    value: null,
                    visible: true,
                    className: ""
                }
            },
            closeOnClickOutside: false, // Prevent closing the popup when clicking outside
            closeOnEsc: false // Prevent closing the popup when pressing ESC key
        }).then((value) => {
            if (value) {
                const fileInput = document.getElementById('fileInput');
                if (!fileInput.files[0]) {
                    swal('Please select a file', {
                        icon: 'error',
                    }).then(() => {
                        // Show the popup again if no file is selected
                        swal.close();
                        showFileInputModal();
                    });
                    return
                }
                const file = fileInput.files[0];
                const fileSize = file.size / 1024; // size in KB
                const fileType = file.type;
                if (!((fileType === 'application/pdf' || fileType === 'image/png') && fileSize <= 1024)) {
                    swal('Please select a PDF or PNG file less than 1 MB', {
                        icon: 'error',
                    }).then(() => {
                        swal.close();
                        showFileInputModal();
                    });
                } else {
                    const param = {
                        'document_id': 88,
                        'file': file
                    }
                    StaffsStore.addNewDocument(param, externalDocSave)

                }
            } else {
                setFluPopUp(true);
            }
        });
    };
    const externalDocSave = (values) => {
        console.log("called Blueskty update....");
        let param = {
            profile_id: values.profile_id,
            document_ids: [values.document_id]
        }
        StaffsStore.uploadExtDocuments(param, navigationCallBackAddDoc)
    }
    const navigationCallBackAddDoc = () => {
        window.location.reload();
    }
    return (
        <React.Fragment>
            <Header />
            <Helmet>
                <title>Staffers LLC | Onboarding Documents</title>
            </Helmet>
            <>
                {/* <Joyride steps={steps} /> */}
                <div className="container-fluid callback mt-5 py-lg-5 shadow">
                    <div className="mt-5  mt-lg-0  pt-3 pb-3">
                        <h5 className="display-5 animated slideInDown login-head pb-2 pt-3">
                            Onboarding Documents
                        </h5>
                    </div>
                    {(
                        <div className='container'>
                            {(
                                <div className='row'>
                                    <div className='col'>
                                        <h4 className='ms-lg-3 fs-5 mb-lg-5 mb-0 ms-0 text-center pb-1'>
                                            Onboarding Progress:&nbsp;
                                            {checkProgress(singedDocuments?.length === 0 ? (Math.round((currentStepInner / ((onboardingDocumentsList?.length > 0 ? onboardingDocumentsList : singedDocuments)?.length - 1)) * 100) + 1) : 200)}
                                        </h4>
                                    </div>
                                </div>
                            )}
                            {!isMobile ? <div className='row mt-5 mb-5 mt-lg-0 mb-lg-0'>
                                <div className='col'>
                                    <MultiStepProgressBarOnboarding
                                        onboardingDocuments={singedDocuments?.length === 0 ? onboardingDocumentsList : singedDocuments}
                                        currentStep={currentStepInner}
                                        setStep={setStepInner}
                                    />
                                </div>
                            </div> : null}
                        </div>
                    )}
                </div>


                {singedDocuments?.length === 0 && onboardingDocumentsList?.length > 0 && <div className={`container ${isMobile ? "w-100 overflow-auto" : "w-50 overflow-auto"}`} style={isMobile ? {} : { height: 500 }}> <form onSubmit={(e) => e.preventDefault()} ref={formRef}>

                    {/* REFERENCE FORM */}
                    {

                        currentDocumentID === 77 && <Reference saveReference={saveReference} referencesData={references} onDelete={onDelete} />//REFERENCE
                    }
                    {
                        currentDocumentID === 95 && <FluVaccinationDeclination inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} signature={signatureUrl ? signatureUrl : canvaSignature} />//126
                    }
                    {
                        currentDocumentID === 126 && <AdministratorAgreementNV inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} signature={signatureUrl ? signatureUrl : canvaSignature} />//126
                    }
                    {
                        currentDocumentID === 135 && <AdministratorJobDescription inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} signature={signatureUrl ? signatureUrl : canvaSignature} />//135
                    }
                    {/* {
                        currentDocumentID === 131 && <ADONJobDescription />
                             }
                        */}
                    {
                        currentDocumentID === 122 && <ArbitrationAgreementCA inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} signature={signatureUrl ? signatureUrl : canvaSignature} />//122
                    }
                    {
                        currentDocumentID === 125 && <ArbitrationAgreementGeneral inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} signature={signatureUrl ? signatureUrl : canvaSignature} />//125
                    }
                    {
                        currentDocumentID === 128 && <ArbitrationAgreementHI inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} signature={signatureUrl ? signatureUrl : canvaSignature} />//128
                    }
                    {
                        currentDocumentID === 123 && <ArbitrationAgreementIA inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} signature={signatureUrl ? signatureUrl : canvaSignature} />//123
                    }

                    {
                        currentDocumentID === 132 && <ArbitrationAgreementOH inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} signature={signatureUrl ? signatureUrl : canvaSignature} /> //132
                    }
                    {
                        currentDocumentID === 124 && <ArbitrationAgreementOR inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} signature={signatureUrl ? signatureUrl : canvaSignature} /> //124
                    }
                    {
                        currentDocumentID === 127 && <ArbitrationAgreementUtah inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} signature={signatureUrl ? signatureUrl : canvaSignature} /> //127
                    }
                    {
                        currentDocumentID === 130 && <ArbitrationAgreementWA inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} signature={signatureUrl ? signatureUrl : canvaSignature} /> //130
                    }

                    {
                        currentDocumentID === 72 && <BhtJobDescription inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} signature={signatureUrl ? signatureUrl : canvaSignature} /> //72
                    }
                    {
                        currentDocumentID === 131 && <BonusPayment inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} signature={signatureUrl ? signatureUrl : canvaSignature} /> //131
                    }
                    {
                        currentDocumentID === 120 && <COTAJobDescription inputValues={inputValues} handleInputChange={handleInputChange} signature={signatureUrl ? signatureUrl : canvaSignature} />  //120
                    }
                    {
                        currentDocumentID === 111 && <DonJobDescription inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} signature={signatureUrl ? signatureUrl : canvaSignature} />  //111
                    }
                    {
                        currentDocumentID === 83 && <HealthQuestionaire inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} signature={signatureUrl ? signatureUrl : canvaSignature} />  //83
                    }
                    {
                        currentDocumentID === 96 && <HandbookOrientation inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} signature={signatureUrl ? signatureUrl : canvaSignature} />  //96
                    }
                    {
                        currentDocumentID === 29 && <HepatitisBVaccinationWiver inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} signature={signatureUrl ? signatureUrl : canvaSignature} /> //29
                    }
                    {
                        currentDocumentID === 73 && <InfoSheet inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} signature={signatureUrl ? signatureUrl : canvaSignature} />  //73
                    }
                    {
                        currentDocumentID === 71 && <JobDescriptionCertifiedCNA inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} signature={signatureUrl ? signatureUrl : canvaSignature} />  //71
                    }
                    {/* {
                        currentDocumentID === ? && <JobDescriptionCertifiedCNAMed signature={signatureUrl ? signatureUrl : canvaSignature} />  //
                    } */}
                    {
                        currentDocumentID === 70 && <JobDescriptionLicensedLPN inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} signature={signatureUrl ? signatureUrl : canvaSignature} />  //70
                    }
                    {
                        currentDocumentID === 69 && <JobDescriptionRegisteredNurse inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} signature={signatureUrl ? signatureUrl : canvaSignature} />  //69
                    }
                    {
                        currentDocumentID === 119 && <OTJobDescription inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} signature={signatureUrl ? signatureUrl : canvaSignature} />  //119
                    }
                    {
                        currentDocumentID === 133 && <WABackgroundCheck inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} onOpenModalParent={onOpenModalParent} signatureParent={signatureUrlParent ? signatureUrlParent : canvaSignatureParent} signature={signatureUrl ? signatureUrl : canvaSignature} />  //133
                    }
                    {
                        currentDocumentID === 94 && <BackgroundCheckChecklist inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} onOpenModalParent={onOpenModalParent} signature={signatureUrl ? signatureUrl : canvaSignature} />  //94
                    }
                    {
                        currentDocumentID === 140 && <AcknowledgmentAndAuthorizationUniversal inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} onOpenModalParent={onOpenModalParent} signature={signatureUrl ? signatureUrl : canvaSignature} />  //140
                    }
                    {/* { 
                        currentDocumentID === 141 && <AbusePreventionTraining inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} onOpenModalParent={onOpenModalParent} signature={signatureUrl ? signatureUrl : canvaSignature} />  //140
                    } */}
                    {
                        currentDocumentID === 141 && <AbuseNeglectandDementiaTrainingNewHires inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} signature={signatureUrl ? signatureUrl : canvaSignature} />

                    }
                    {

                        currentDocumentID === 151 && <PolicyandProcedureAcknow inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} signature={signatureUrl ? signatureUrl : canvaSignature} />//REFERENCE
                    }
                    {

                        currentDocumentID === 75 && <SkilledNursingCompetencySelfAssessment inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} signature={signatureUrl ? signatureUrl : canvaSignature} />//REFERENCE
                    }
                    {
                        currentDocumentID === 76 && <CertifiedNursingAssistantSkillsValidationChecklist inputValues={inputValues} handleInputChange={handleInputChange} onOpenModal={onOpenModal} signature={signatureUrl ? signatureUrl : canvaSignature} />
                    }
                    <div className="d-flex justify-content-center ">
                        {(currentStepInner !== 0) && <button className="btn  btn-outline-primary w-25 mt-2 mb-3 me-2" onClick={setStepInnerBack} style={{ fontWeight: 'bolder', fontSize: 20 }}>
                            {"Back"}
                        </button>}
                        {(currentStepInner !== onboardingDocumentsList?.length - 1) && (currentStepInner !== 0) && <button className="btn btn-outline-primary w-25 mt-2 mb-3" onClick={onOpenModal} style={{ fontWeight: 'bolder', fontSize: 20 }}>
                            {currentStepInner === 0 ? "Sign & Next" : "Next"}
                        </button>}
                        {(currentStepInner === onboardingDocumentsList?.length - 1) &&
                            <button className="btn btn-outline-success mt-2 mb-3" onClick={onOpenModal}>
                                <b>Submit</b>
                            </button>
                        }
                    </div>
                </form>
                    {/* FLOAT BUTTON FOR SIGN */}
                    <div className="float-btn">
                        <button type="submit" onClick={() => setOpenModal(true)} className="btn btn-success w-100 py-3"><i className="fas fa-signature"></i>&nbsp; &nbsp;{isMobile ? "" : "Open signature Pad"}</button>
                    </div>
                </div>}
                {/* VIEWER PDF */}
                {singedDocuments?.length > 0 && <div className={`container ${isMobile ? "w-100 overflow-auto" : "w-50 overflow-auto"}`} style={{ height: 500 }}><form onSubmit={(e) => e.preventDefault()}>
                    <div style={{ height: 550 }}>
                        {

                            (currentDocumentID === 77 && !singedDocuments?.find(x => x.document_id === currentDocumentID)?.document_path) ? <Reference saveReference={saveReference} referencesData={references} onDelete={onDelete} /> : <PdfViewer pdfUrl={BASE_API_URL_FILE + singedDocuments?.find(x => x.document_id === currentDocumentID)?.document_path} />
                        }
                        <div className="d-flex justify-content-center ">
                            {currentStepInner !== 0 && <button className="btn  btn-outline-primary w-25 mt-2 mb-3 me-2" onClick={setStepInnerBack} style={{ fontWeight: 'bolder', fontSize: 20 }}>
                                {"Back"}
                            </button>}
                            {singedDocuments?.length - 1 !== currentStepInner && <button className="btn  btn-outline-primary w-25 mt-2 mb-3 me-2" onClick={setStepInner} style={{ fontWeight: 'bolder', fontSize: 20 }}>
                                {"Next"}
                            </button>}
                        </div>
                    </div>

                </form></div>}

                <CModal scrollable visible={isOpenModal} backdrop="static" onClose={() => onDismissModal()}>
                    <CModalHeader onClose={() => onDismissModal()}>
                        <CModalTitle>Sign Documents</CModalTitle>
                    </CModalHeader>
                    <form>
                        <div className='container mt-2'>
                            {(!signatureUrl && !canvaSignature) && <small className='mb-3'>The optimum size for an signature is 860–390 px wide and 390 px high. Ensure images are well-optimized and have a file size of less than 100kb.</small>
                            }
                            {!canvaSignature && <div className="col-sm-12 mt-3 did-floating-label-content ">
                                <input
                                    ref={fileInputRef}
                                    title="click here to upload signature"
                                    accept="image/png"
                                    className={"did-floating-input"}
                                    type="file"
                                    onChange={(e) => handleFileInputChange(e)}
                                />
                                <label className="did-floating-label did-floating-label-top">Upload Signature</label>
                            </div>}
                            {(signatureUrl || canvaSignature) &&
                                <div className='mb-2'>
                                    <label>Selected Signature:</label><img src={signatureUrl ? signatureUrl : canvaSignature} alt="Selected Signature" height={50} width={100} /></div>}
                            {(!selectedSignature && !canvaSignature) && <div>
                                <CModalTitle className="text-center mt-3 mb-3">Or Use Signature PAD</CModalTitle>
                                <div>
                                    <div className='d-flex justify-content-center'>
                                        <SignatureCanvas penColor='black' ref={canvasRef}
                                            canvasProps={{ className: 'signature-canvas', width: "auto", height: 200 }}
                                        />
                                    </div>
                                </div>
                            </div>}
                        </div>

                        <CModalFooter>
                            {/* <CButton color="danger" onClick={() => setOpenModal(false)}>Cancel</CButton> */}
                            <CButton color="btn btn-light text-primary" type='reset' onClick={() => handleClearConva()}>Clear All</CButton>
                            <CButton color="primary" onClick={handleSave}>Save</CButton>
                        </CModalFooter>
                    </form>
                </CModal>

                <CModal scrollable visible={isOpenModalParent} backdrop="static" onClose={() => onDismissModalParent()}>
                    <CModalHeader onClose={() => onDismissModalParent()}>
                        <CModalTitle>YOUR PARENT OR GUARDIAN'S SIGNATURE</CModalTitle>
                    </CModalHeader>
                    <form>
                        <div className='container mt-5'>
                            {!canvaSignatureParent && <div className="col-sm-12 did-floating-label-content ">
                                <input
                                    title="click here to upload signature"
                                    accept="image/png"
                                    className={"did-floating-input"}
                                    type="file"
                                    onChange={(e) => handleFileInputChangeParent(e)}
                                />
                                <label className="did-floating-label did-floating-label-top">Upload Signature</label>
                            </div>}
                            {!selectedSignatureParent && <div>
                                <CModalTitle className="text-center mt-3 mb-3">Use Signature PAD</CModalTitle>
                                <div>
                                    <div className='d-flex justify-content-center'>
                                        <SignatureCanvas penColor='black' ref={canvasRefParent}
                                            canvasProps={{ className: 'signature-canvas', width: "auto", height: 200 }}
                                        />
                                    </div>
                                </div>
                            </div>}
                        </div>

                        <CModalFooter>
                            <CButton color="btn btn-light text-primary" type='reset' onClick={() => handleClearConvaParent()}>Clear All</CButton>
                            <CButton color="primary" onClick={handleSaveParent}>Save</CButton>
                        </CModalFooter>
                    </form>
                </CModal>

                <Spinner isLoading={StaffsStore.loading || isLoading} dontClose={true} />
            </>
        </React.Fragment >
    )
})

export default OnboardingDocuments;