import * as Yup from "yup";
//Regular expression
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
var regex = /\b(\w{1})(\w+)(\w)\b/g;
const ClientSignUpSchema = Yup.object().shape({
  // organization_name: Yup.string().required("Organization name required"),
  // organization_type: Yup.string().required("Organization type required"),
  designation: Yup.string().required("Job Title required"),
  // state: Yup.string().required("State required"),
  // city: Yup.string().required("City required"),
  // address: Yup.string().required("Address required"),
  // zipcode: Yup.string()
  //   .required("Zip code is required")
  //   .matches(/^\d{5}$/, "Zip code must be exactly 5 digits")
  //   .min(3, "Zip code must be at least 3 digits")
  //   .max(5, "Zip code can be at most 5 digits"),
  first_name: Yup.string()
    .required("First name required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
  last_name: Yup.string()
    .required("Last name required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
  email: Yup.string().email("Invalid email").required("Email required"),
  contact: Yup.string()
    .matches(phoneRegExp, "Contact number is not valid")
    .required("Contact number required")
    .min(10)
    .max(10, "Wrong contact number"),
  password: Yup.string()
    .required("Password required")
    .min(6, "Your password must be longer than 6 characters.")
    .test(
      "no-spaces",
      "Password must not contain spaces",
      (value) => !/\s/.test(value)
    ),
  passwordConfirmation: Yup.string()
    .required("Confirm password required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  additional_contact: Yup.array().of(
    Yup.object().shape({
      contact: Yup.string().required("Contact is required"),
    })
  ),
  additional_email: Yup.array().of(
    Yup.object().shape({
      email: Yup.string().required("Email is required"),
    })
  ),
});

const FacilityAddSchema = Yup.object().shape({
  organization_name: Yup.string().required("Facility name is required!"),
  organization_type: Yup.string().required("Facility type is required!"),
  state: Yup.string().required("State required!"),
  city: Yup.string().required("City is required!"),
  region_id: Yup.string().required("Region is required!"),
  address: Yup.string().required("Address is required!"),
  zipcode: Yup.string()
    .required("Zip code is required!")
    .matches(/^\d{5}$/, "Zip code must be exactly 5 digits")
    .min(5, "Zip code must be at least 3 digits")
    .max(7, "Zip code can be at most 5 digits"),
});
const StaffSignUpSchema = Yup.object().shape({
  profession: Yup.string().required("Profession required"),
  first_name: Yup.string()
    .required("First name required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
  last_name: Yup.string()
    .required("First name required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
  email: Yup.string().email("Invalid email").required("Email required"),
  contact: Yup.string()
    .matches(phoneRegExp, "Contact number is not valid")
    .required("Contact number required")
    .min(10)
    .max(10, "Wrong contact number"),
  password: Yup.string()
    .required("Password required")
    .min(6, "Your password must be longer than 6 characters.")
    .test(
      "no-spaces",
      "Password must not contain spaces",
      (value) => !/\s/.test(value)
    ),
  passwordConfirmation: Yup.string()
    .required("Confirm password required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});
const SignInSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required!"),
  password: Yup.string()
    .required("Password is required!")
});
const SignClientSchema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  password: Yup.string()
    .required("Required")
    .min(6, "Your password must be longer than 6 characters."),
});
const StepOneSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required!"),
});

const StepOneClientSchema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
});
const StepThreeSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password required")
    .min(6, "Your password must be longer than 6 characters."),
  passwordConfirmation: Yup.string()
    .required("Confirm password required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});
const SignUpSubSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
  last_name: Yup.string()
    .required("Last name required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
  designation: Yup.string().required("Designation Required"),
  password: Yup.string()
    .trim()
    .min(6, "Your password must be longer than 6 characters.")
    .test(
      "no-spaces",
      "Password must not contain spaces",
      (value) => !/\s/.test(value)
    ),
  additional_contact: Yup.array().of(
    Yup.object().shape({
      contact: Yup.string().required("Contact is required"),
    })
  ),
  additional_email: Yup.array().of(
    Yup.object().shape({
      email: Yup.string().required("Email is required"),
    })
  ),
});
const ClientEditSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
  last_name: Yup.string()
    .required("Last name required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
  designation: Yup.string().required("Designation Required"),
  contact: Yup.string()
    .matches(phoneRegExp, "Contact number is not valid")
    .required("Contact number required")
    .min(7)
    .max(15, "Wrong contact number"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .trim()
    .min(6, "Your password must be longer than 6 characters.")
    .test(
      "no-spaces",
      "Password must not contain spaces",
      (value) => !/\s/.test(value)
    ),

  additional_contact: Yup.array().of(
    Yup.object().shape({
      contact: Yup.string().required("Contact is required"),
    })
  ),
  additional_email: Yup.array().of(
    Yup.object().shape({
      email: Yup.string().required("Email is required"),
    })
  ),
});
const SubClientEditSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
  last_name: Yup.string()
    .required("Last name required")
    .matches(/^[a-zA-Z\s.]+$/, {
      message: "Only alphabets and spaces are allowed",
      excludeEmptyString: true,
    }),
  designation: Yup.string().required("Designation Required"),
  additional_contact: Yup.array().of(
    Yup.object().shape({
      contact: Yup.string().required("Contact is required"),
    })
  ),
  additional_email: Yup.array().of(
    Yup.object().shape({
      email: Yup.string().required("Email is required"),
    })
  ),
});
const StaffUpdateSchema = Yup.object().shape({
  profession: Yup.string().required("Profession required").nullable(),
  first_name: Yup.string()
    .required("First name required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
  last_name: Yup.string()
    .required("Last name required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
  contact: Yup.string()
    .matches(phoneRegExp, "Contact number is not valid")
    .required("Contact number required")
    .min(7)
    .max(15, "Wrong contact number"),
});
const ShiftReqSchema = Yup.object().shape({
  profession: Yup.string().required("Profession is required!"),
  category: Yup.string().required("Category is required!"),
  facility_id: Yup.string().required("Facility is required!"),
});
const UpdateTCSchema = Yup.object().shape({
  pay_details: Yup.string().required("Pay details required"),
  start_date: Yup.string().required("Start Date required"),
  end_date: Yup.string().required("End date required"),
  start_time: Yup.string().required("Start time required"),
  end_time: Yup.string().required("End time required"),
  hours_per_week: Yup.string().required("Hours/week required"),
  no_of_weeks: Yup.string().required("No. of week required"),
  qualifications: Yup.string().required("Qualifications required"),
});
const dnrSchema = Yup.object().shape({
  user_id: Yup.string().required("Facility is required!"),
  staff_id: Yup.string().required("Caregiver is required!"),
});
const referenceSchema = Yup.object().shape({
  references: Yup.array()
    .of(
      Yup.object().shape({
        organization_name: Yup.string().required(
          "Organization name is required"
        ),
        name: Yup.string().required("Name is required"),
        email: Yup.string()
          .email("Invalid email")
          .required("Email is required"),
        contact: Yup.string()
          .matches(phoneRegExp, "Contact number is not valid")
          .required("Contact number required")
          .min(10, "Contact must be at least 10 digit")
          .max(10, "Wrong contact number"),
      })
    )
    .test("unique-email", "Email must be unique.", function (value) {
      if (this.parent.references) {
        const references = this.parent.references;
        const seenEmails = new Set();
        let isUnique = true;
        references.forEach((org, index) => {
          if (seenEmails.has(org.email)) {
            isUnique = false;
          } else {
            seenEmails.add(org.email);
          }
        });
        return isUnique;
      }
      return true;
    })
    .test("unique-contact", "Contact must be unique.", function (value) {
      if (this.parent.references) {
        const references = this.parent.references;
        const seenContacts = new Set();
        let isUnique = true;
        references.forEach((org, index) => {
          if (seenContacts.has(org.contact)) {
            isUnique = false;
          } else {
            seenContacts.add(org.contact);
          }
        });
        return isUnique;
      }
      return true;
    }),
});

const shiftImportAdminSchema = Yup.object().shape({
  client_id: Yup.string().required("F.User is required!"),
  facility_id: Yup.string().required("Facility is required!"),
  profession: Yup.string().required("Profession is required!"),
  shift_file: Yup.mixed().nullable().required("Order file is required!"),
});
const shiftImportClientSchema = Yup.object().shape({
  profession: Yup.string().required("Profession required"),
  shift_file: Yup.mixed().nullable().required("Order file required"),
});
const timeValidationSchema = Yup.object({
  slot_date: Yup.date().required("Date is required"),
  slot_start_time: Yup.string().required("Start time is required"),
  slot_end_time: Yup.string()
    .required("End time is required")
    .when(
      "slot_start_time", // Corrected property name
      (slot_start_time, schema) =>
        slot_start_time &&
        schema.notOneOf(
          [Yup.ref("slot_start_time")],
          "End time must not be equal to start time"
        )
    ),
});
const JobValidationSchema = Yup.object({
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
  industry: Yup.string().required("Industry is required"),
  degree: Yup.string().required("Job Title/Degree is required"),
  contract_type: Yup.string().required("Contract type is required"),
  shift_type:  Yup.array().required("Shift type is required"),
});

const EnquiryValidationSchema = Yup.object({
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
  name: Yup.string()
    .required("Name is required")
    .matches(/^[A-Za-z\s]+$/, "Name must contain only letters and spaces")
    .required("Name is required"),
  email: Yup.string().required("Email is required"),
  contact: Yup.string().required("Contact is required"),
  enquiry_type: Yup.string().required("Enquiry type is required"),
  status: Yup.string().required("Status is required"),
});

const FeaturedLocValidationSchema = Yup.object({
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
});

const ReviewValidationSchema = Yup.object({
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
  name: Yup.string()
    .required("Name is required")
    .matches(/^[A-Za-z\s]+$/, "Name must contain only letters and spaces")
    .required("Name is required"),
  stars: Yup.number()
    .required()
    .integer()
    .min(1, "Please provide review, Number must be greater than or equal to 1")
    .max(5, "Number must be less than or equal to 5"),
  feedback: Yup.string().required("Feedback is required"),
  company_name: Yup.string().required("Company Name is required"),
});
const AddShiftSchema = Yup.object().shape({
  profession: Yup.string().required('Profession is required!'),
  category: Yup.string().required('Category is required!'),
  client_id: Yup.string().required('F.User is required!'),
  facility_id: Yup.string().required('Facility is required!'),
});
const AddShiftByEmployerSchema = Yup.object().shape({
  profession: Yup.string().required('Profession is required!'),
  category: Yup.string().required('Category is required!'),
  facility_id: Yup.string().required('Facility is required!'),
});

const UpdateJobProfileSchema = Yup.object({
  profile_id: Yup.string().required("Profile id is required").nullable(),
  external_id: Yup.string().required("External id is required").nullable(),
  region_id: Yup.string().required("Region is required").nullable(),
  work_distance: Yup.string().required("Work distance is required").nullable(),

});

export {
  ClientSignUpSchema,
  StaffSignUpSchema,
  SignInSchema,
  SignClientSchema,
  StepOneSchema,
  StepOneClientSchema,
  StepThreeSchema,
  SignUpSubSchema,
  ClientEditSchema,
  SubClientEditSchema,
  StaffUpdateSchema,
  ShiftReqSchema,
  UpdateTCSchema,
  dnrSchema,
  referenceSchema,
  shiftImportAdminSchema,
  shiftImportClientSchema,
  timeValidationSchema,
  JobValidationSchema,
  EnquiryValidationSchema,
  FeaturedLocValidationSchema,
  ReviewValidationSchema,
  AddShiftSchema,
  AddShiftByEmployerSchema,
  FacilityAddSchema,
  UpdateJobProfileSchema,
  regex,
};
